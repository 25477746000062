import { useEffect } from 'react';

const useHandleOAuthError = () => {
  // error=access_denied will append to the URL when user denied the OAuth consent screen, remove it for better UX
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('error') === 'access_denied') {
      const url = new URL(window.location.href);
      url.searchParams.delete('error');
      window.history.replaceState({}, '', url);
    }
  }, []);
};

export default useHandleOAuthError;
