import { ToastType } from '@shopline/dashboard-ui/types/themes/toast';

export enum ActionTypes {
  CREATE_UI_TOAST = 'CREATE_UI_TOAST',
  DISMISS_UI_TOAST = 'DISMISS_UI_TOAST',
  DELETE_UI_TOAST = 'DELETE_UI_TOAST',
  CLEAR_UI_TOAST = 'CLEAR_UI_TOAST',
}

export type Toast = {
  id: string;
  type: ToastType;
  title?: string;
  titleWithParams?: {
    key: string;
    params: Record<string, string>;
  };
  description?: string;
  descriptionWithParams?: {
    key: string;
    params: Record<string, string>;
  };
  descriptionNodeWithProps?: {
    node: React.FunctionComponent<any>;
    props: any;
  };
  duration?: number;
  isShow: boolean;
};
