const fieldValidation = {
  required: '{{fieldName}} 是必须的',
  pattern: '{{fieldName}} 无效',
  min: '{{fieldName}} 不得小于 {{value}}',
  max: '{{fieldName}} 不得大于 {{value}}',
  minDate: '{{fieldName}} 不得早于 {{value}}',
  maxDate: '{{fieldName}} 不得晚于 {{value}}',
  channelUnlinked: '选取的频道已被解绑，请重新选择或串接频道',
};

export default fieldValidation;
