import { forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

const InvisibleInput = forwardRef(
  (props: React.InputHTMLAttributes<HTMLInputElement>, ref) => (
    <Wrapper>
      {/* @ts-ignore ref */}
      <input {...props} ref={ref} />
    </Wrapper>
  ),
);

export default InvisibleInput;
