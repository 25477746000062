import { Button, Popup } from '@shopline/dashboard-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spacer from '@/components/Spacer';
import Text from '@/components/Text';

import { YT_LIVE_E2E_PREFIX } from '../../constants';

const E2E_PREFIX = `${YT_LIVE_E2E_PREFIX}-hidden-product-modal`;

const StyledImg = styled.img`
  width: auto;
  max-width: 100%;
`;
const PreLineText = styled(Text)`
  white-space: pre-line;
`;

const MIN_PAGE = 1;
const MAX_PAGE = 4;
const HiddenProductModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation(['ytLive', 'common']);
  const [page, setPage] = useState(MIN_PAGE);

  const pageContents = useMemo(() => {
    return [
      {
        title: t('HiddenProductModal - page 1 title'),
        description: t('HiddenProductModal - page 1 description'),
        imageSrc: require('./page-1.jpg'),
      },
      {
        title: t('HiddenProductModal - page 2 title'),
        description: t('HiddenProductModal - page 2 description'),
        imageSrc: require('./page-2.jpg'),
      },
      {
        title: t('HiddenProductModal - page 3 title'),
        description: t('HiddenProductModal - page 3 description'),
        imageSrc: require('./page-3.jpg'),
      },
      {
        title: t('HiddenProductModal - page 4 title'),
        description: t('HiddenProductModal - page 4 description'),
        imageSrc: require('./page-4.jpg'),
      },
    ];
  }, [t]);

  const currentPageContent = pageContents[page - 1];

  const renderFooter = () => {
    return (
      <>
        {page !== MIN_PAGE && (
          <Button.Default
            onClick={() => setPage(page - 1)}
            type="button"
            width="WIDE"
            e2eId={`${E2E_PREFIX}-previous`}
          >
            {t('Previous Step', { ns: 'common' })}
          </Button.Default>
        )}
        {page < MAX_PAGE && (
          <Button.Primary
            onClick={() => setPage(page + 1)}
            type="button"
            width="WIDE"
            e2eId={`${E2E_PREFIX}-next`}
          >
            {t('Next Step', { ns: 'common' })}
          </Button.Primary>
        )}
        {page === MAX_PAGE && (
          <Button.Primary
            onClick={onClose}
            type="button"
            width="WIDE"
            e2eId={`${E2E_PREFIX}-finish`}
          >
            {t('Finish', { ns: 'common' })}
          </Button.Primary>
        )}
      </>
    );
  };

  return (
    // @ts-ignore react 18 removed `children` as a default prop
    <Popup
      title={t('HiddenProductModal - title')}
      onClose={onClose}
      renderFooter={renderFooter}
      e2eId={E2E_PREFIX}
    >
      <Text fontType="Subheading" color="INK_800">
        {currentPageContent.title}
      </Text>
      <Spacer y="4px" />
      <PreLineText fontType="Body" color="INK_600">
        {currentPageContent.description}
      </PreLineText>
      <Spacer y="16px" />
      <StyledImg src={currentPageContent.imageSrc} alt="tutorial" />
    </Popup>
  );
};

export default HiddenProductModal;
