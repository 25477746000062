import { Icons, Tooltip } from '@shopline/dashboard-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TableItem } from '../../../hooks/useProducts/transformToTableItem';

const StatusIcon = ({
  text,
  IconComponent,
  tooltipWithPortal,
}: {
  text: string;
  IconComponent: React.FC<any>;
  tooltipWithPortal?: string;
}) => {
  return (
    // @ts-ignore react 18 removed `children` as a default prop
    <Tooltip.Primary
      text={text}
      direction="TOP_CENTER"
      withPortal={tooltipWithPortal}
    >
      <IconComponent size="MEDIUM" color="INK_600" />
    </Tooltip.Primary>
  );
};

const StatusWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

type Props = Pick<
  TableItem,
  | 'isReminderActive'
  | 'isHidden'
  | 'schedulePublishAt'
  | 'availableStartTime'
  | 'availableEndTime'
> & { tooltipContainerId?: string };
const ProductStatus = ({
  isReminderActive,
  isHidden,
  schedulePublishAt,
  availableStartTime,
  availableEndTime,
  tooltipContainerId,
}: Props) => {
  const timeFormat = 'YYYY/MM/DD HH:mm';
  const { t } = useTranslation('ytLive');

  const availableTimeString = useMemo(() => {
    if (!availableStartTime) {
      return '';
    }

    const currentDay = dayjs();
    const startDay = dayjs(availableStartTime);
    const endDay = dayjs(availableEndTime);
    if (availableEndTime && currentDay.isAfter(endDay)) {
      return t('Live Products status - availableStartTime end', {
        date: endDay.format(timeFormat),
      });
    }
    if (availableStartTime && availableEndTime) {
      return t('Live Products status - availableStartTime period', {
        date: `${startDay.format(timeFormat)} - ${endDay.format(timeFormat)}`,
      });
    }
    return t('Live Products status - availableStartTime start', {
      date: startDay.format(timeFormat),
    });
  }, [availableStartTime, availableEndTime, t]);

  const tooltipWithPortal = tooltipContainerId
    ? `#${tooltipContainerId}`
    : undefined;

  return (
    <StatusWrapper>
      {isReminderActive ? (
        <StatusIcon
          IconComponent={Icons.Outline.Bell}
          text={t('Live Products status - reminder on')}
          tooltipWithPortal={tooltipWithPortal}
        />
      ) : (
        <StatusIcon
          IconComponent={Icons.Outline.BellOff}
          text={t('Live Products status - reminder off')}
          tooltipWithPortal={tooltipWithPortal}
        />
      )}
      {isHidden && (
        <StatusIcon
          IconComponent={Icons.Outline.EyeHide}
          text={t('Live Products status - hidden')}
          tooltipWithPortal={tooltipWithPortal}
        />
      )}
      {schedulePublishAt && (
        <StatusIcon
          IconComponent={Icons.Outline.Calendar}
          text={t('Live Products status - schedulePublishAt', {
            date: dayjs(schedulePublishAt).format(timeFormat),
          })}
          tooltipWithPortal={tooltipWithPortal}
        />
      )}
      {availableTimeString && (
        <StatusIcon
          IconComponent={Icons.Outline.Clock}
          text={availableTimeString}
          tooltipWithPortal={tooltipWithPortal}
        />
      )}
    </StatusWrapper>
  );
};

export default ProductStatus;
