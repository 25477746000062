import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { Tracker, TrackerType } from '@/types/tracker/tracker.types';

type QueryKey = ['tracker', string];

export const getTracker = async (merchantId: string, page = 1) => {
  const { data } = await axios.get<{ items: Tracker[] }>(
    `/api/merchants/${merchantId}/event-trackers`,
    {
      params: {
        page,
        perPage: 50,
      },
    },
  );

  return data.items;
};

const passMerchantId =
  (queryFn: typeof getTracker) =>
  ({ queryKey: [, merchantId] }: QueryFunctionContext<QueryKey>) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId: string): QueryKey => [
  'tracker',
  merchantId,
];

export const useQueryTracker = ({
  trackerType,
  refetchOnWindowFocus = false,
}: {
  trackerType: TrackerType;
  refetchOnWindowFocus?: boolean;
}) => {
  const { merchantId } = useParams() as { merchantId: string };
  return useQuery({
    queryKey: composeQueryKey(merchantId),
    queryFn: passMerchantId(getTracker),
    enabled: !!merchantId,
    staleTime: refetchOnWindowFocus ? 0 : Number.POSITIVE_INFINITY,
    refetchOnWindowFocus,
    select: trackerType
      ? (data) => data.find((item) => item.trackerType === trackerType)
      : undefined,
  });
};
