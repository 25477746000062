const fieldValidation = {
  required: '{{fieldName}} is required',
  pattern: '{{fieldName}} is invalid',
  min: '{{fieldName}} must not be less than {{value}}',
  max: '{{fieldName}} must not be greater than {{value}}',
  minDate: '{{fieldName}} must not be earlier than {{value}}',
  maxDate: '{{fieldName}} must not be later than {{value}}',
  channelUnlinked:
    'The channel previously selected is disconnected, please select another/connect the channel again.',
};

export default fieldValidation;
