const ytAccountLinking = {
  'YouTube Shopping Account Linking Title': 'YouTube Shopping 商店串接',
  'Store Setup Title': '商店前置设定',
  'Store Setup Description':
    '请确保您已可以在 Google Merchant Center 帐号管理此商店的商品广告资讯，再到步骤 2 复制串接连结给您的合作 YouTube 频道管理员。',
  'Store Setup GMC Title': '串接 Google Merchant Center',
  'Store Setup GMC Description':
    '完成后，此商店的商品资料将自动同步至串接的 Google Merchant Center 帐户。请注意新增 YouTube Shopping 频道后，请勿异动商店串接的 GMC 帐号，若异动，需要重新授权频道，该频道才能取得新的商品资讯。 <KnowMoreLink></KnowMoreLink>',
  'Learn More': '了解更多',
  'Setup Now': '前往启用',
  'Store Setup GMC Account Connected': '已串接以下 Google Merchant Center 帐号',
  'Store Setup GMC Finished': '已完成串接',
  'Store Setup GMC ID Changed':
    'Google Merchant Center 帐号异动，部分频道需要重新授权',
  'Store Setup Content API Title': '启用 Content API',
  'Store Setup Content API Description':
    '商店的商品异动将透过 Content API 即时更新至 Google Merchant Center，隐藏商品资讯也会自动同步。 <KnowMoreLink></KnowMoreLink>',
  'Store Setup Content API Status': 'Google Content API 串接状态',
  'Store Setup Content API Finished': '已完成串接',
  'Connected Channels Title': 'YouTube Shopping 串接频道',
  'Connected Channels Description':
    '请复制串接连结给合作的 YouTube 频道管理员，频道管理员完成授权后，即可于相关 YouTube 频道后台选取商品应用于直播或影片中。',
  'Connected Channels Copy Link': '复制串接连结',
  'Connected Channels Copy Success': '复制完成',
  'Connected Channels No Channels Connected':
    '无任何频道与此商店完成 YouTube Shopping 串接',
  'Connected Channels Channels Connected':
    '以下频道已与商店完成 YouTube Shopping 串接',
  'Connected Channels Not Authorized': '非系统授权',
  'Connected Channels Disconnect Tooltip':
    '若需要解除此频道串接，请联系您的客户成功顾问',
  'Connected Channels Disconnect Channel': '解除串接',
  'Connected Channels Disconnect Popup Title':
    '确认解此频道与 {{shopName}} 的 YouTube Shopping 串接？',
  'Connected Channels Disconnect Popup Description':
    '解除串接后，在 YouTube 频道将无法显示任何商店商品',
  'Connected Channels Confirm Disconnect': '确定解除',
  'Connected Channels Cancel Disconnect': '取消',
  'Connected Channels Disconnect Channel Success': '成功解除',
  'Connected Channels Reconnect as GMC ID Changed':
    'Google Merchant Center 帐号异动，请重新串接频道',
};

export default ytAccountLinking;
