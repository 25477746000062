import { LoadingSpinner } from '@shopline/dashboard-ui';
import styled from 'styled-components';

interface LoadSpinnerContainerProps {
  mt: string;
}

const LoadSpinnerContainer = styled.div<Partial<LoadSpinnerContainerProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ mt }) => mt || '0'};
`;

const LoadSpinner = ({ mt }: LoadSpinnerContainerProps) => {
  return (
    <LoadSpinnerContainer mt={mt}>
      <LoadingSpinner />
    </LoadSpinnerContainer>
  );
};

export default LoadSpinner;
