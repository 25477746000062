import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { useToastActionContext } from '@/contexts/Toast';
import queryClient from '@/features/queryClient';

import { queryKey } from './queryKey';

const disconnectChannelFromMerchant = async (
  merchantId?: string,
  channelId?: string,
) => {
  await axios.delete(`/api/merchants/${merchantId}/connects/${channelId}`);
};

export const useDisconnectChannelFromMerchant = (channelId?: string) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { createUIToast } = useToastActionContext();

  return useMutation({
    mutationFn: () => disconnectChannelFromMerchant(merchantId, channelId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKey.merchantConnectedChannels(merchantId),
      );
      createUIToast?.({
        type: 'success',
        titleWithParams: {
          key: 'Connected Channels Disconnect Channel Success',
          params: { ns: 'ytAccountLinking' },
        },
        duration: 3000,
      });
    },
    onError: () => {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'General Error',
          params: { ns: 'common' },
        },
        duration: 3000,
      });
    },
  });
};
