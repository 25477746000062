import eq from 'lodash/fp/eq';
import lt from 'lodash/fp/lt';
import startsWith from 'lodash/fp/startsWith';
import {
  cond,
  constant,
  flow,
  isString,
  overEvery,
  size,
  stubTrue,
} from 'lodash-es';

const LANG_MAP = {
  'zh-hant': 'zhHant',
  'zh-cn': 'zhCn',
} as const;

export const getFallbackLangKeys = cond([
  [eq('zhHant'), constant(['zhCn', 'en', 'vi', 'th'])],
  [eq('zhCn'), constant(['zhHant', 'en', 'vi', 'th'])],
  [eq('en'), constant(['zhHant', 'zhCn', 'vi', 'th'])],
  [eq('vi'), constant(['en', 'zhHant', 'zhCn', 'th'])],
  [eq('th'), constant(['en', 'zhHant', 'zhCn', 'vi'])],
  [startsWith('zh'), constant(['zhHant', 'zhCn', 'en', 'vi', 'th'])],
  [stubTrue, constant(['en', 'zhHant', 'zhCn', 'vi', 'th'])],
]);

export const isValidTranslationString = overEvery([
  isString,
  flow(size, lt(0)),
]);

const getTranslation = (
  trans: { [key: string]: string },
  langKey: string,
  fallback = '',
  isValid = isValidTranslationString,
  customFallbackLangKeys = getFallbackLangKeys,
) => {
  // @ts-ignore
  const convertedLangKey = LANG_MAP[langKey] ?? langKey;
  const translation = trans[convertedLangKey];
  if (isValid(translation)) {
    return translation;
  }
  const fallbackLangKeys = customFallbackLangKeys(langKey);
  const validFallbackKey = fallbackLangKeys.find((key) => isValid(trans[key]));
  return validFallbackKey ? trans[validFallbackKey] : fallback;
};

export default getTranslation;
