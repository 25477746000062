import { Environment } from '@/types/environment/environment.type';

export const getEnvironment = (): Environment => {
  const hostname = window.location.hostname;
  if (hostname.includes('shoplinestg')) {
    return 'staging';
  }
  if (hostname.includes('preview.shoplineapp')) {
    return 'preview';
  }
  if (hostname.includes('shoplineapp') && !hostname.includes('preview')) {
    return 'production';
  }
  return 'default';
};
