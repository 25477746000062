const common = {
  Save: '儲存',
  Add: '新增',
  'Previous Step': '上一步',
  'Next Step': '下一步',
  Finish: '完成',
  Cancel: '取消',
  Confirm: '確認',
  'Unauthorized - title': '抱歉，此頁面目前暫時無法開放給您！',
  'Unauthorized - description': '若您仍需進入此頁面，請聯繫您的店長索取權限。',
  'Saved successfully': '儲存成功',
  'Failed to save': '儲存失敗',
  en: 'English',
  'zh-hant': '繁體中文',
  'zh-cn': '简体中文',
  vi: '越南語',
  th: '泰語',
  'General Error': '發生問題，請再試一次',
};

export default common;
