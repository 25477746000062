export enum PermissionScope {
  merchant = 'merchant',
  regionLocaleSet = 'regionLocaleSet',
  liveRoomSetting = 'liveRoomSetting',
  eventTrackers = 'eventTrackers',
  youtubeChannelConnects = 'youtubeChannelConnects',
}
export type PermissionScopeString = keyof typeof PermissionScope;

export enum PermissionAction {
  index = 'index',
  create = 'create',
  show = 'show',
  edit = 'edit',
  patch = 'patch',
  remove = 'remove',
}
export type PermissionActionString = keyof typeof PermissionAction;

export type Permission = {
  [action in PermissionAction]?: boolean;
};

export interface InitialState {
  merchant: {
    id: string;
    name: string;
    currentPlanKey: string;
    staffId: string;
    rolloutKeys: string[];
    handle: string;
  };
  staff: {
    staffId: string;
    staffName: string;
    staffEmail: string;
    staffLocaleCode: string;
    isMerchantOwner: boolean;
    isMerchantStaff: boolean;
    isSuperAdmin: boolean;
    isInspector: boolean;
    isMarketer: boolean;
  };
  permissions: {
    [scope in PermissionScope]: Permission;
  };
}
