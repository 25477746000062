import { IconButton, Icons, LoadingSpinner } from '@shopline/dashboard-ui';
import { LanguageName } from '@shopline/dashboard-ui/types/themes/fonts';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { useQueryChannelsInfo } from '@/hooks/externalYTChannelConnect/useConnectYouTubeChannel';

import ChannelConnectStatus from './ChannelConnectStatus';

interface ConnectYouTubeChannelBlockProps {
  shopName?: string;
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FaqIconContainer = styled.span`
  vertical-align: middle;
`;

const LoadSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`;

const ConnectYouTubeChannelBlock = ({
  shopName,
}: ConnectYouTubeChannelBlockProps) => {
  const { t, i18n } = useTranslation('externalYtConnect');
  const { data, isLoading } = useQueryChannelsInfo();

  const hasEligibleChannel = !isEmpty(data);

  if (isLoading) {
    return (
      <LoadSpinnerContainer>
        <LoadingSpinner />
      </LoadSpinnerContainer>
    );
  }
  return (
    <>
      <BlockTitle>
        <Text fontType="Heading" color="INK_800">
          {t('External YouTube Connect Connected Channels Title')}
        </Text>
      </BlockTitle>
      <MarginContainer mt="0.25rem">
        <Text color="INK_600" fontType="Caption">
          {t('External YouTube Connect Connected Channels Description')}
          <FaqIconContainer>
            <IconButton
              Icon={Icons.Outline.QuestionMark}
              colorType={'basic'}
              size={1}
              iconProps={{ size: 'SMALL' }}
              tooltipProps={{
                direction: 'TOP_CENTER',
                lang: i18n.language as LanguageName,
                text: t(
                  'External YouTube Connect Connected Channels Description Faq Tooltip',
                ),
              }}
            />
          </FaqIconContainer>
        </Text>
      </MarginContainer>
      {hasEligibleChannel ? (
        <>
          {data?.map((channel) => (
            <MarginContainer key={channel.channelId} mt="1rem">
              <ChannelConnectStatus channel={channel} shopName={shopName} />
            </MarginContainer>
          ))}
        </>
      ) : (
        <MarginContainer mt="1rem">
          <Text color="INK_800" fontType="Body">
            {t('External YouTube Connect No Eligible Channels')}
          </Text>
        </MarginContainer>
      )}
    </>
  );
};

export default ConnectYouTubeChannelBlock;
