import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { chunk, flatten, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { Schema$SlProductListResponse } from '@/types/sl-open-api/sl-product/sl-product.types';

import { SlProduct } from './sl-product.types';
import { transformToTableItem } from './transformToTableItem';

type QueryParam = { merchantId: string };
type QueryKey = ['selectedProducts', QueryParam];
const PER_PAGE = 100;

const getProductsByIds = async ({
  merchantId,
  ids,
  page = 1,
}: QueryParam & { ids: string[]; page?: number }) => {
  // chunk ids to prevent too long ids, since it is GET request
  const queries = chunk(ids, PER_PAGE).map((chunkedIds) =>
    axios.get<Schema$SlProductListResponse>(
      `/api/merchants/${merchantId}/products`,
      {
        params: {
          perPage: PER_PAGE,
          ids: chunkedIds,
          page,
          sortBy: 'desc',
        },
      },
    ),
  );
  const responses = await Promise.all(queries);
  const data = flatten(
    responses.map((res) => res.data.items),
  ) as unknown as SlProduct[];
  const tableItems = data.map(transformToTableItem);
  return tableItems;
};

const passParams = (
  queryFn: typeof getProductsByIds,
  { ids }: { ids: string[] },
) => {
  return ({
    queryKey: [, { merchantId }],
    pageParam,
  }: QueryFunctionContext<QueryKey>) => queryFn({ merchantId, ids });
};

const composeQueryKey = ({ merchantId }: QueryParam): QueryKey => [
  'selectedProducts',
  { merchantId },
];

export const useSelectedProductsQueryKey = () => {
  const { merchantId } = useParams() as { merchantId: string };

  return composeQueryKey({ merchantId });
};

export const useQuerySelectedProducts = ({ ids = [] }: { ids?: string[] }) => {
  const queryKey = useSelectedProductsQueryKey();
  const { merchantId } = useParams() as { merchantId: string };

  return useQuery({
    queryKey,
    queryFn: passParams(getProductsByIds, { ids }),
    enabled: !!merchantId && !isEmpty(ids),
  });
};
