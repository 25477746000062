import { Button, TextButton } from '@shopline/dashboard-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import InvisibleInput from '@/components/InvisibleInput';
import Text from '@/components/Text';
import useAdminHost from '@/hooks/useAdminHost';
import { useQueryTracker } from '@/hooks/useTracker';
import { Tracker, TrackerType } from '@/types/tracker/tracker.types';

import { FormField } from '../formSchema';
import { E2E_PREFIX } from '.';
import LinkageCompleted from './LinkageCompleted';
import Step from './Step';

const ActionButton = ({
  isInstalled,
  trackerId,
  e2eId,
}: {
  isInstalled: boolean;
  trackerId: string;
  e2eId: string;
}) => {
  const { t } = useTranslation('ytLive');
  const adminHost = useAdminHost();

  return isInstalled ? (
    <Button.Default
      // @ts-ignore
      as="a"
      href={`${adminHost}/event_trackers?path=%2F${trackerId}%2Fedit`}
      target="_blank"
      rel="noreferrer noopener"
      e2eId={e2eId}
    >
      {t('Manage Settings')}
    </Button.Default>
  ) : (
    <Button.Default
      // @ts-ignore
      as="a"
      href={`${adminHost}/event_trackers?kind=ga4&path=%2Fcreate`}
      target="_blank"
      rel="noreferrer noopener"
      e2eId={e2eId}
    >
      {t('Go to Set')}
    </Button.Default>
  );
};

const StepTrackerSetting = ({ stepNum }: { stepNum: number }) => {
  const { t } = useTranslation('ytLive');
  const { getFieldState, watch, control } = useFormContext();
  const { data: ga4Tracker } = useQueryTracker({
    trackerType: TrackerType.google_analytics_4,
    refetchOnWindowFocus: true,
  }) as { data: Tracker };
  const isInstalled = !!ga4Tracker;
  const isLinkageCompleted = watch(FormField.isGa4Ready);
  const hasError = getFieldState(FormField.isGa4Ready).invalid;

  return (
    <Step
      stepNum={stepNum}
      title={t('Setup Guide StepTrackerSetting')}
      actionButton={
        <ActionButton
          isInstalled={isInstalled}
          trackerId={ga4Tracker?.id}
          e2eId={`${E2E_PREFIX}-step-tracker-setting_action-button`}
        />
      }
      hasError={hasError}
    >
      <Text fontType="Body" color="INK_600">
        <Trans
          t={t}
          i18nKey="Setup Guide StepTrackerSetting - description"
          components={{
            LinkButton: (
              <TextButton.Primary
                // @ts-ignore
                as="a"
                href={t('Setup Guide StepTrackerSetting - support link')}
                target="_blank"
                rel="noreferrer noopener"
              />
            ),
          }}
        />
      </Text>
      {isLinkageCompleted && <LinkageCompleted />}
      <Controller
        name={FormField.isGa4Ready}
        defaultValue={null}
        control={control}
        render={({ field }) => (
          <InvisibleInput
            {...field}
            type="checkbox"
            data-e2e-id={`${E2E_PREFIX}-step-two-isGa4Ready_checkbox`}
          ></InvisibleInput>
        )}
      ></Controller>
    </Step>
  );
};

export default StepTrackerSetting;
