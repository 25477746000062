import { PermissionAction, PermissionScope } from '@/hooks/initialState.types';
import usePermissions from '@/hooks/usePermissions';

const useCanEdit = () => {
  const hasPermission = usePermissions([
    [PermissionScope.liveRoomSetting, PermissionAction.edit],
  ]);

  return hasPermission;
};

export default useCanEdit;
