import {
  Button,
  Icons,
  InfiniteScrollPicker,
  Input,
  TextButton,
  Tooltip,
} from '@shopline/dashboard-ui';
import { Item } from '@shopline/dashboard-ui/types/components/infiniteScrollPicker/interface';
import { LanguageName } from '@shopline/dashboard-ui/types/themes/fonts';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spacer from '@/components/Spacer';

import { TableItem } from '../../../hooks/useProducts/transformToTableItem';
import { PRODUCTS_MAX_COUNT } from '../constants';
import { getProductDisabledType } from './getProductDisabledType';
import useColumns from './useColumns';
import useLiveProducts from './useLiveProducts';
import useSelectLiveProducts from './useSelectLiveProducts';

enum Mode {
  ALL = 'ALL',
  SELECTED = 'SELECTED',
}

const TOOLTIP_CONTAINER_ID = 'product-picker-tooltip-container';
const TooltipContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.POPUP + 1};
`;

const SearchBar = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (val: string) => void;
}) => {
  const { t } = useTranslation('ytLive');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Input
      value={value}
      onChange={handleChange}
      status="valid"
      placeholder={t('LiveProductsPicker - SearchBar placeholder')}
      renderMessages={[]}
      Icon={Icons.Outline.Search}
      block
      autoFocus
    />
  );
};

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Footer = ({
  selectedCount,
  mode,
  onCancel,
  onConfirm,
  onToggleMode,
}: {
  selectedCount: number;
  mode: Mode;
  onCancel: () => void;
  onConfirm: () => void;
  onToggleMode: () => void;
}) => {
  const { t } = useTranslation('ytLive');

  return (
    <FooterWrapper>
      <TextButton.Primary onClick={onToggleMode} type="button">
        {mode === Mode.ALL
          ? t('LiveProductsPicker - View selected products', {
              count: selectedCount,
              max: PRODUCTS_MAX_COUNT,
            })
          : t('LiveProductsPicker - View all products')}
      </TextButton.Primary>
      <div>
        <Button.Default onClick={onCancel} type="button">
          {t('Cancel', { ns: 'common' })}
        </Button.Default>
        <Spacer x="16px" />
        <Button.Primary onClick={onConfirm} type="button" width="WIDE">
          {t('Confirm', { ns: 'common' })}
        </Button.Primary>
      </div>
    </FooterWrapper>
  );
};

const RowTooltip = ({
  item,
  children,
}: {
  item: TableItem;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation('ytLive');
  const disabledType = getProductDisabledType(item);
  const text =
    disabledType === null
      ? ''
      : t('Live Prdoucts disabled', { context: disabledType });

  return (
    // @ts-ignore react 18 removed `children` as a default prop
    <Tooltip.Primary
      text={text}
      direction="TOP_CENTER"
      withPortal={`#${TOOLTIP_CONTAINER_ID}`}
    >
      {children}
    </Tooltip.Primary>
  );
};

interface Props {
  onClose: () => void;
}
const LiveProductsPicker = ({ onClose }: Props) => {
  useLockBodyScroll();
  const {
    t,
    i18n: { language },
  } = useTranslation('ytLive');

  const [mode, setMode] = useState<Mode>(Mode.ALL);
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === Mode.ALL ? Mode.SELECTED : Mode.ALL));
  };

  const [keyword, setKeyword] = useState('');
  const columns = useColumns({ tooltipContainerId: TOOLTIP_CONTAINER_ID });
  const { items, fetchNextPage, noResult, showItemCount } = useLiveProducts({
    keyword: keyword.trim(),
  });

  const {
    selectedItems,
    filteredSelectedItems,
    selectedItemsSize,
    toggleSelection,
    updateSlProductIdsFormValue,
  } = useSelectLiveProducts({
    keyword: keyword.trim(),
  });

  const handleRowClick: (items: Item[]) => void = ([item]) => {
    toggleSelection(item);
  };

  const handleConfirm = () => {
    updateSlProductIdsFormValue();
    onClose();
  };

  return (
    <>
      <InfiniteScrollPicker
        lang={language as LanguageName}
        enableSelection
        title={t('Add Products')}
        noResultTitle={t('LiveProductsPicker - No result')}
        noResultText={t('LiveProductsPicker - No result description')}
        noSelectedText={t('LiveProductsPicker - No result description')}
        mode={mode}
        items={items}
        selectedItems={
          // product search api may return products without keyword
          // only use filteredSelectedItems when mode is selected
          mode === Mode.SELECTED ? filteredSelectedItems : selectedItems
        }
        onRowClick={handleRowClick}
        noResult={noResult}
        columns={columns}
        showItemCount={showItemCount}
        onClose={onClose}
        loadMoreItems={() => fetchNextPage()}
        // @ts-ignore type
        RowWrapperComponent={RowTooltip}
        isRowDisabled={(item) => !!getProductDisabledType(item as TableItem)}
        Searchbar={
          <SearchBar value={keyword} onChange={(val) => setKeyword(val)} />
        }
        Footer={
          <Footer
            selectedCount={selectedItemsSize}
            mode={mode}
            onCancel={onClose}
            onConfirm={handleConfirm}
            onToggleMode={toggleMode}
          />
        }
        rowHeight={64}
      />
      <TooltipContainer id={TOOLTIP_CONTAINER_ID} />
    </>
  );
};

export default LiveProductsPicker;
