import { useLayoutEffect, useState } from 'react';

// NOTE: this hook only make sure get correct rect before repaint by useLayoutEffect
// not ensure correct value when scroll or resize
const useBoundingClientRect = (
  ref: React.MutableRefObject<HTMLElement | undefined | null>,
): DOMRect => {
  const [rect, setRect] = useState(new DOMRect());

  useLayoutEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, []);

  return rect;
};

export default useBoundingClientRect;
