import { Button, Icons } from '@shopline/dashboard-ui';
import { debounce } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MarginContainer } from '@/components/Layout';
import LoadSpinner from '@/components/LoadSpinner';
import Text from '@/components/Text';
import { YT_ACCOUNT_LINKING_E2E_PREFIX } from '@/constants/e2eId';
import { useConfig } from '@/contexts/Config';
import { useToastActionContext } from '@/contexts/Toast';
import { useQueryMerchant } from '@/hooks/useMerchant';
import { useQueryMerchantConnectedChannels } from '@/hooks/youtubeAccountLinking/useQueryMerchantConnectedChannels';

import BlockContainer from '../BlockContainer';
import ChannelConnectStatus from './ChannelConnectStatus';

interface ConnectedChannelsBlockProps {
  hasRemovePermission?: boolean;
}

export const connectedChannelsCopyLinkButtonE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-connected-channels-copy-link_button`;

// prevent user from copying the link too frequently, the time is first set to 500ms
const COPY_LINK_DEBOUNCE_DELAY = 500;

const ConnectedChannelsBlock = ({
  hasRemovePermission = false,
}: ConnectedChannelsBlockProps) => {
  const { data: merchant } = useQueryMerchant({ refetchOnWindowFocus: true });
  const { t, i18n } = useTranslation('ytAccountLinking');
  const { merchantId } = useParams<{ merchantId: string }>();
  const { data, isLoading } = useQueryMerchantConnectedChannels();
  const { createUIToast } = useToastActionContext();
  const config = useConfig();
  const googleMerchantId = merchant?.googleMerchantId;

  const handleCopyLink = async () => {
    const externalConnectPageLink = `${config.origins.ytChannelConnect}/merchants/${merchantId}?lang=${i18n.language}`;
    await navigator.clipboard.writeText(externalConnectPageLink);
    createUIToast?.({
      type: 'success',
      titleWithParams: {
        key: 'Connected Channels Copy Success',
        params: { ns: 'ytAccountLinking' },
      },
      duration: 3000,
    });
  };

  const debouncedHandleCopyLink = debounce(
    handleCopyLink,
    COPY_LINK_DEBOUNCE_DELAY,
  );

  if (!data || isLoading) {
    return <LoadSpinner mt="1rem" />;
  }

  return (
    <BlockContainer
      stepNum={2}
      title={t('Connected Channels Title')}
      description={t('Connected Channels Description')}
      actionButton={
        <Button.Default
          onClick={debouncedHandleCopyLink}
          width="SQUARE"
          Icon={Icons.Outline.Duplicate}
          e2eId={connectedChannelsCopyLinkButtonE2eId}
          disabled={!googleMerchantId}
        >
          {t('Connected Channels Copy Link')}
        </Button.Default>
      }
    >
      <MarginContainer mt="1rem">
        {data?.connects.length > 0 ? (
          <>
            <Text color="INK_800" fontType="Body">
              {t('Connected Channels Channels Connected')}
            </Text>
            <MarginContainer mt="0.25rem">
              {data?.connects.map((channel) => (
                <MarginContainer mt="0.25rem" key={channel.id}>
                  <ChannelConnectStatus
                    channel={channel}
                    hasRemovePermission={hasRemovePermission}
                  />
                </MarginContainer>
              ))}
            </MarginContainer>
          </>
        ) : (
          <Text color="INK_800" fontType="Body">
            {t('Connected Channels No Channels Connected')}
          </Text>
        )}
      </MarginContainer>
    </BlockContainer>
  );
};

export default ConnectedChannelsBlock;
