import { MerchantConnectedChannelsQueryKey } from '@/types/youtube-account-linking/youtube-account-linking.type';

export const queryKey = {
  merchantConnectedChannels: (
    merchantId?: string,
  ): MerchantConnectedChannelsQueryKey => [
    'youtube-account-linking-merchant-connected-channels',
    merchantId,
  ],
};
