import { Icons } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Text from '@/components/Text';

const Wrapper = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const Activated = () => {
  const { t } = useTranslation('ytAccountLinking');

  return (
    <Wrapper>
      <Icons.Solid.CircleTick size="MEDIUM" color="GREEN_600" />
      <Text fontType="Body" color="GREEN_600">
        {t('Store Setup GMC Finished')}
      </Text>
    </Wrapper>
  );
};

export default Activated;
