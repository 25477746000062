import getOr from 'lodash/fp/getOr';
import lt from 'lodash/fp/lt';
import prop from 'lodash/fp/prop';
import {
  cond,
  conforms,
  constant,
  flow,
  isNumber,
  matches,
  stubTrue,
  toString,
} from 'lodash-es';
import { createStructuredSelector } from 'reselect';

import { SlProduct } from '@/hooks/useProducts/sl-product.types';

export interface TableItem {
  uniqueId: string;
  id: string;
  sku?: string;
  image: string;
  titleTranslations: { [key: string]: string };
  priceLabel: string;
  priceSaleLabel: string;
  hidePrice: boolean;
  hasPrice: boolean;
  inventory: string;

  isReminderActive: boolean;
  isHidden: boolean;
  isDraft: boolean;
  schedulePublishAt: string | null;
  availableStartTime: string | null;
  availableEndTime: string | null;
}

const getPriceLabel = (price: SlProduct['price']) =>
  `${price.currencySymbol}${price.dollars}`;
const getPriceSaleLabel = cond<SlProduct['price'], string>([
  [matches({ dollars: 0 }), constant('')],
  [stubTrue, prop('label')],
]);

const inventorySelector = cond<SlProduct, string>([
  [matches({ unlimitedQuantity: true }), constant('∞')],
  [matches({ totalOrderableQuantity: -1 }), constant('∞')],
  [
    conforms<{ totalOrderableQuantity?: number }>({
      totalOrderableQuantity: isNumber,
    }),
    flow(prop('totalOrderableQuantity'), toString),
  ],
  [conforms({ quantity: isNumber }), flow(prop('quantity'), toString)],
  [stubTrue, constant('')],
]);

export const transformToTableItem = createStructuredSelector<
  SlProduct,
  TableItem
>({
  uniqueId: prop('id'),
  id: prop('id'),
  sku: prop('sku'),
  image: getOr<SlProduct, any, string>('', [
    'medias',
    0,
    'images',
    'original',
    'url',
  ]),
  titleTranslations: getOr({}, 'titleTranslations'),
  hasPrice: flow(getOr(0, ['lowestPrice', 'dollars']), lt(0)),
  hidePrice: prop('hidePrice'),
  priceLabel: flow(prop('lowestPrice'), getPriceLabel),
  priceSaleLabel: flow(prop('lowestPriceSale'), getPriceSaleLabel),
  inventory: inventorySelector,
  isReminderActive: prop('isReminderActive'),
  isHidden: matches({ status: 'hidden' }),
  isDraft: matches({ status: 'draft' }),
  schedulePublishAt: prop('schedulePublishAt'),
  availableStartTime: prop('availableStartTime'),
  availableEndTime: prop('availableEndTime'),
});
