import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import transformObjectKeys from 'transform-object-keys';

import { useToastActionContext } from '@/contexts/Toast';
import {
  LiveRoomSetting,
  UpdateLiveRoomSettingRequestBody,
} from '@/types/live-room/live-room.types';

type QueryParam = {
  liveRoomId: string;
  merchantId: string;
};
type QueryKey = ['live-room-setting', QueryParam];

const getLiveRoomSetting = async ({ merchantId, liveRoomId }: QueryParam) => {
  const {
    data: { data },
  } = await axios.get<{ data: LiveRoomSetting }>(
    `/api/merchants/${merchantId}/live-room/${liveRoomId}/setting`,
  );
  return transformObjectKeys(data, {
    deep: true,
  }) as unknown as LiveRoomSetting;
};

const createLiveRoomSetting = async ({
  merchantId,
  liveRoomId,
}: QueryParam) => {
  const {
    data: { data },
  } = await axios.post<{ data: LiveRoomSetting }>(
    `/api/merchants/${merchantId}/live-room`,
    { liveRoomId },
  );
  return transformObjectKeys(data, {
    deep: true,
  }) as unknown as LiveRoomSetting;
};

const updateLiveRoomSetting = async ({
  merchantId,
  liveRoomId,
  payload,
}: QueryParam & { payload: UpdateLiveRoomSettingRequestBody }) => {
  const {
    data: { data },
  } = await axios.put<{ data: LiveRoomSetting }>(
    `/api/merchants/${merchantId}/live-room/${liveRoomId}/setting`,
    payload,
  );
  return transformObjectKeys(data, {
    deep: true,
  }) as unknown as LiveRoomSetting;
};

const checkIsNotCreatedError = (error: AxiosError) =>
  error.response?.status === 404;

const passParams =
  (queryFn: typeof getLiveRoomSetting) =>
  ({
    queryKey: [, { merchantId, liveRoomId }],
  }: QueryFunctionContext<QueryKey>) =>
    queryFn({ merchantId, liveRoomId });

const composeQueryKey = ({ merchantId, liveRoomId }: QueryParam): QueryKey => [
  'live-room-setting',
  { merchantId, liveRoomId },
];

export const useQueryLiveRoomSetting = () => {
  const { merchantId, liveRoomId } = useParams() as {
    merchantId: string;
    liveRoomId: string;
  };

  const createMutation = useCreateLiveRoomSetting();
  const query = useQuery({
    queryKey: composeQueryKey({
      merchantId,
      liveRoomId,
    }),
    queryFn: passParams(getLiveRoomSetting),
    enabled: !!merchantId,
    retry: (failureCount, error) => {
      return !checkIsNotCreatedError(error as AxiosError);
    },
  });

  useEffect(() => {
    if (query.isError && checkIsNotCreatedError(query.error as AxiosError)) {
      createMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isError]);

  return query;
};

export const useCreateLiveRoomSetting = () => {
  const { merchantId, liveRoomId } = useParams() as {
    merchantId: string;
    liveRoomId: string;
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      createLiveRoomSetting({
        merchantId,
        liveRoomId,
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        composeQueryKey({
          merchantId,
          liveRoomId,
        }),
        data,
      );
    },
  });
};

export const useUpdateLiveRoomSetting = () => {
  const { merchantId, liveRoomId } = useParams() as {
    merchantId: string;
    liveRoomId: string;
  };
  const queryClient = useQueryClient();
  const { createUIToast } = useToastActionContext();

  return useMutation({
    mutationFn: (payload: UpdateLiveRoomSettingRequestBody) =>
      updateLiveRoomSetting({
        merchantId,
        liveRoomId,
        payload,
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        composeQueryKey({
          merchantId,
          liveRoomId,
        }),
        data,
      );
      createUIToast?.({
        type: 'success',
        titleWithParams: {
          key: 'Saved successfully',
          params: { ns: 'common' },
        },
        duration: 5000,
      });
    },
    onError: (error: AxiosError) => {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'Failed to save',
          params: { ns: 'common' },
        },
        description: error.message,
      });
    },
  });
};
