import { BlockMessagePage } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

import { UNAUTHORIZED_IMAGE_URL } from '@/constants/image';
import usePermissions, { PermissionItem } from '@/hooks/usePermissions';
import useQueryInitialState from '@/hooks/useQueryInitialState';

import LoadingPage from '../LoadingPage';
import Text from '../Text';

interface Props {
  children: JSX.Element;
  permissions?: PermissionItem[];
}
const AuthedRoute = ({ children, permissions }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const { isSuccess } = useQueryInitialState();
  const canShow = usePermissions(permissions);

  if (!isSuccess) {
    return <LoadingPage />;
  }
  if (!canShow) {
    return (
      <BlockMessagePage
        image={UNAUTHORIZED_IMAGE_URL}
        title={
          <Text color="INK_600" fontType="Heading" weight="Medium">
            {t('Unauthorized - title')}
          </Text>
        }
        description={
          <Text color="INK_500" fontType="Body" weight="Regular">
            {t('Unauthorized - description')}
          </Text>
        }
      />
    );
  }

  return children;
};

export default AuthedRoute;
