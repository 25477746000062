import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { errorCodeToTranslationKeyMap, ErrorResponse } from '@/constants/error';
import { useToastActionContext } from '@/contexts/Toast';
import queryClient from '@/features/queryClient';
import type {
  ChannelStatus,
  GetConnectedChannelsQueryKey,
  GetMerchantConnectedChannelsResponse,
  GetUserYouTubeChannelsResponse,
} from '@/types/external-yt-channel-connect/external-yt-channel-connect.types';

import { queryKeys } from './queryKey';

const getMerchantConnectedChannels = async (merchantId?: string) => {
  const { data } = await axios.get<GetMerchantConnectedChannelsResponse>(
    `/external-api/merchants/${merchantId}/connects`,
  );
  return data;
};

const getUserChannels = async (merchantId?: string) => {
  const { data } = await axios.get<GetUserYouTubeChannelsResponse>(
    `/external-api/merchants/${merchantId}/channels`,
  );
  return data;
};

const getChannelConnectedInfo = async (
  merchantId?: string,
): Promise<ChannelStatus[]> => {
  const [merchantConnectedChannels, userYouTubeChannels] = await Promise.all([
    getMerchantConnectedChannels(merchantId),
    getUserChannels(merchantId),
  ]);
  const userChannelsInfo = userYouTubeChannels.channels.map(
    (userYouTubeChannel) => {
      const connectUserChannel = merchantConnectedChannels.connects.find(
        (merchantConnectedChannel) => {
          return (
            merchantConnectedChannel.youtubeChannelId === userYouTubeChannel.id
          );
        },
      );
      return {
        channelId: userYouTubeChannel.id,
        channelName: userYouTubeChannel.name,
        connectId: connectUserChannel?.id,
        isGMCIdChanged: connectUserChannel?.isGMCIdChanged,
      };
    },
  );
  return userChannelsInfo;
};

const passMerchantId =
  (queryFn: typeof getChannelConnectedInfo) =>
  ({
    queryKey: [_, merchantId],
  }: QueryFunctionContext<GetConnectedChannelsQueryKey>) =>
    queryFn(merchantId);

export const useQueryChannelsInfo = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { createUIToast } = useToastActionContext();
  return useQuery<
    ChannelStatus[],
    AxiosError,
    ChannelStatus[],
    GetConnectedChannelsQueryKey
  >({
    queryKey: queryKeys.getConnectedChannels(merchantId),
    queryFn: passMerchantId(getChannelConnectedInfo),
    enabled: !!merchantId,
    onError: () => {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'General Error',
          params: { ns: 'common' },
        },
        duration: 3000,
      });
    },
  });
};

const connectChannelToMerchant = async (
  requestBody: { youtubeChannelId?: string },
  merchantId?: string,
) => {
  await axios.post(
    `/external-api/merchants/${merchantId}/connects`,
    requestBody,
  );
};

export const useConnectChannelToMerchant = (youtubeChannelId?: string) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { createUIToast } = useToastActionContext();
  return useMutation({
    mutationFn: () => {
      const connectChannelToMerchantBody = {
        youtubeChannelId,
      };
      return connectChannelToMerchant(connectChannelToMerchantBody, merchantId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKeys.getConnectedChannels(merchantId),
      );
      createUIToast?.({
        type: 'success',
        titleWithParams: {
          key: 'External YouTube Connect Disconnect Channel Connect Success Toast',
          params: { ns: 'externalYtConnect' },
        },
        duration: 3000,
      });
    },
    onError: (error: AxiosError) => {
      const errorResponse = error.response?.data as ErrorResponse;
      const errorKey = errorCodeToTranslationKeyMap[errorResponse?.code ?? ''];
      const toastTitleWithParams = {
        key: errorKey || 'General Error',
        params: { ns: errorKey ? 'externalYtConnect' : 'common' },
      };
      createUIToast?.({
        type: 'alert',
        titleWithParams: toastTitleWithParams,
        duration: 3000,
      });
    },
  });
};

const disconnectChannelFromMerchant = async (
  merchantId?: string,
  youtubeConnectId?: string,
) => {
  await axios.delete(
    `/external-api/merchants/${merchantId}/connects/${youtubeConnectId}`,
  );
};

export const useDisconnectChannelFromMerchant = (youtubeConnectId?: string) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { createUIToast } = useToastActionContext();
  return useMutation({
    mutationFn: () =>
      disconnectChannelFromMerchant(merchantId, youtubeConnectId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKeys.getConnectedChannels(merchantId),
      );
      createUIToast?.({
        type: 'success',
        titleWithParams: {
          key: 'External YouTube Connect Disconnect Channel Disconnect Success Toast',
          params: { ns: 'externalYtConnect' },
        },
        duration: 3000,
      });
    },
    onError: () => {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'General Error',
          params: { ns: 'common' },
        },
        duration: 3000,
      });
    },
  });
};
