import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useNav = ({ e2ePrefix }: { e2ePrefix: string }) => {
  const { t } = useTranslation('ytLive');
  const setupGuideRef = useRef<HTMLDivElement>(null);
  const liveProductsRef = useRef<HTMLDivElement>(null);
  const youtubeAdminPreparationRef = useRef<HTMLDivElement>(null);
  const finishSettingRef = useRef<HTMLDivElement>(null);
  const items = useMemo(
    () => [
      {
        title: t('Setup Guide'),
        content: t('Setup Guide - nav content'),
        target: setupGuideRef,
        e2eId: `${e2ePrefix}-setup-guide-nav`,
      },
      {
        title: t('Live Products'),
        content: t('Live Products - nav content'),
        target: liveProductsRef,
        e2eId: `${e2ePrefix}-setup-guide-nav`,
      },
      {
        title: t('Youtube Admin Preparation'),
        content: t('Youtube Admin Preparation - nav content'),
        target: youtubeAdminPreparationRef,
        e2eId: `${e2ePrefix}-finish-setting-nav`,
      },
      {
        title: t('Finish Setting'),
        content: '',
        target: finishSettingRef,
        e2eId: `${e2ePrefix}-finish-setting-nav`,
      },
    ],
    [t, e2ePrefix],
  );

  return {
    refs: {
      setupGuideRef,
      liveProductsRef,
      youtubeAdminPreparationRef,
      finishSettingRef,
    },
    items,
  };
};

export default useNav;
