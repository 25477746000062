import { mapValues } from 'lodash-es';
import { createContext, useContext, useMemo, useReducer } from 'react';

import ToastList from '@/components/ToastList';

import * as actions from './actions';
import reducer, { initialState, type ToastState } from './reducer';

type Actions = keyof typeof actions;
type ActionsState = {
  [Action in Actions]: (...param: Parameters<(typeof actions)[Action]>) => void;
};

export const ToastActionContext = createContext<Partial<ActionsState>>({});
export const ToastStateContext = createContext<ToastState>(initialState);

export const ToastProvider: React.FC<{
  state?: ToastState;
  children: React.ReactNode;
}> = ({ state: defaultState = initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const memoizedActions = useMemo(
    () =>
      mapValues(actions, (action) => (...param: any[]) => {
        const _action: any = action;
        dispatch(_action(...param));
      }),
    [dispatch],
  );
  return (
    <ToastActionContext.Provider value={memoizedActions}>
      <ToastStateContext.Provider value={state}>
        <ToastList />
        {children}
      </ToastStateContext.Provider>
    </ToastActionContext.Provider>
  );
};

export const useToastActionContext = () => useContext(ToastActionContext);
export const useToastStateContext = () => useContext(ToastStateContext);
