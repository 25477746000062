import { Button, IconButton, Icons } from '@shopline/dashboard-ui';
import { LanguageName } from '@shopline/dashboard-ui/types/themes/fonts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX } from '@/constants/e2eId';
import { useConnectChannelToMerchant } from '@/hooks/externalYTChannelConnect/useConnectYouTubeChannel';
import type { ChannelStatus } from '@/types/external-yt-channel-connect/external-yt-channel-connect.types';

import DisconnectChannelPopup from './DisconnectChannelPopup';

export const channelDisconnectIconE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-channel-disconnect_icon`;
export const channelConnectButtonE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-channel-connect_button`;

const ChannelConnectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.SNOW_500};
  padding: 1rem;
  border-radius: 6px;
`;

const ConnectStatus = styled.div`
  display: flex;
  align-items: center;
`;

const BreakLineText = styled(Text)`
  word-break: break-word;
`;

const AlertIconContainer = styled.div`
  vertical-align: text-top;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
`;

export interface ChannelConnectStatusProps {
  channel: ChannelStatus;
  shopName?: string;
}

const ChannelConnectStatus = ({
  channel,
  shopName,
}: ChannelConnectStatusProps) => {
  const [disconnectPopupOpen, setDisconnectPopupOpen] = useState(false);
  const { t, i18n } = useTranslation('externalYtConnect');
  const { mutate: connectChannelToMerchantMutation, isLoading } =
    useConnectChannelToMerchant(channel.channelId);

  return (
    <>
      {disconnectPopupOpen && (
        <DisconnectChannelPopup
          shopName={shopName}
          youtubeConnectId={channel.connectId}
          onClose={() => setDisconnectPopupOpen(false)}
        />
      )}
      <ChannelConnectContainer>
        <BreakLineText color="INK_600" fontType="Body" key={channel?.channelId}>
          {channel?.channelName}
        </BreakLineText>
        <ConnectStatus>
          {channel?.isGMCIdChanged && (
            <>
              <AlertIconContainer>
                <Icons.Solid.Alert color="RED_700" size="SMALL" />
              </AlertIconContainer>
              <Text color="RED_700" fontType="Body">
                {t(
                  'External YouTube Connect Connected Channels GMC Account Changed',
                )}
              </Text>
            </>
          )}
          <MarginContainer ml="1rem">
            {channel?.connectId ? (
              <IconButton
                Icon={Icons.Outline.Unlink}
                colorType={'basic'}
                size={1}
                iconProps={{
                  size: 'SMALL',
                  position: 'LEFT',
                  e2eId: channelDisconnectIconE2eId,
                }}
                tooltipProps={{
                  direction: 'TOP_CENTER',
                  lang: i18n.language as LanguageName,
                  text: t('External YouTube Connect Disconnect Channel'),
                }}
                handleClick={() => setDisconnectPopupOpen(true)}
              />
            ) : (
              <Button.Default
                width="SQUARE"
                Icon={Icons.Solid.Connect}
                height="SMALL"
                onClick={() => {
                  connectChannelToMerchantMutation();
                }}
                e2eId={channelConnectButtonE2eId}
                isLoading={isLoading}
              >
                {t(
                  'External YouTube Connect No Connected Channels Connect Button',
                )}
              </Button.Default>
            )}
          </MarginContainer>
        </ConnectStatus>
      </ChannelConnectContainer>
    </>
  );
};

export default ChannelConnectStatus;
