import { uniqueId } from 'lodash-es';

import { ActionTypes, Toast } from './types';

export const createUIToast = (
  toast: Omit<Toast, 'id' | 'isShow'>,
): {
  type: ActionTypes.CREATE_UI_TOAST;
  payload: Toast;
} => {
  return {
    type: ActionTypes.CREATE_UI_TOAST,
    payload: {
      id: uniqueId(),
      ...toast,
      isShow: true,
    },
  };
};

export const dismissUIToast = (
  id: string,
): {
  type: ActionTypes.DISMISS_UI_TOAST;
  payload: {
    id: string;
  };
} => ({
  type: ActionTypes.DISMISS_UI_TOAST,
  payload: { id },
});

export const deleteUIToast = (
  id: string,
): {
  type: ActionTypes.DELETE_UI_TOAST;
  payload: {
    id: string;
  };
} => ({
  type: ActionTypes.DELETE_UI_TOAST,
  payload: { id },
});

export const clearUIToast = (): {
  type: ActionTypes.CLEAR_UI_TOAST;
} => ({
  type: ActionTypes.CLEAR_UI_TOAST,
});
