import { Button, Icons } from '@shopline/dashboard-ui';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX } from '@/constants/e2eId';
import useHandleOAuthError from '@/hooks/externalYTChannelConnect/useHandleOAuthError';

import LogoutPopup from './LogoutPopup';

interface GoogleLoginBlockProps {
  currentUser?: { name: string; email: string } | null;
}

const BlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BlockGoogleLoginStatus = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const GoogleIcon = styled(Icons.Brand.Google)`
  margin: 10px;
`;
const GoogleLoginBlock = ({ currentUser }: GoogleLoginBlockProps) => {
  const [logoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const { t } = useTranslation('externalYtConnect');
  const isMobileScreen = useMediaQuery('(max-width: 768px)');

  useHandleOAuthError();

  const handleClickLogin = () => {
    const encodedPath = encodeURIComponent(
      window.location.pathname + window.location.search,
    );
    window.location.href = `/external-api/auth/youtuber?redirect_url=${encodedPath}`;
  };

  const handleClickLogout = () => {
    setLogoutPopupOpen(true);
  };

  return (
    <>
      {logoutPopupOpen && (
        <LogoutPopup onClose={() => setLogoutPopupOpen(false)} />
      )}
      <BlockTitle>
        <Text fontType="Heading" color="INK_800">
          {t('External YouTube Connect Google Login Title')}
        </Text>
        {!isMobileScreen &&
          (currentUser ? (
            <Button.Default
              e2eId={`${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}_google-logout_button`}
              onClick={handleClickLogout}
            >
              {t('External YouTube Connect Logout')}
            </Button.Default>
          ) : (
            <Button.Primary
              e2eId={`${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}_google-login_button`}
              onClick={handleClickLogin}
            >
              {t('External YouTube Connect Login')}
            </Button.Primary>
          ))}
      </BlockTitle>
      <MarginContainer mt="0.5rem">
        <Text color="INK_600" fontType="Caption">
          {t('External YouTube Connect Google Login Description')}
        </Text>
      </MarginContainer>
      {currentUser && (
        <BlockGoogleLoginStatus>
          <GoogleIcon size="XXLARGE" />
          <MarginContainer ml="0.25rem">
            <Text color="INK_800" fontType="Body" weight="Semibold">
              {currentUser?.email}
            </Text>
          </MarginContainer>
        </BlockGoogleLoginStatus>
      )}
      {isMobileScreen && (
        <MarginContainer mt="1rem">
          {currentUser ? (
            <Button.Default
              e2eId={`${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}_google-logout_button`}
              onClick={handleClickLogout}
            >
              {t('External YouTube Connect Logout')}
            </Button.Default>
          ) : (
            <Button.Primary
              e2eId={`${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}_google-login_button`}
              onClick={handleClickLogin}
            >
              {t('External YouTube Connect Login')}
            </Button.Primary>
          )}
        </MarginContainer>
      )}
    </>
  );
};

export default GoogleLoginBlock;
