const common = {
  Test: 'Test',
  Save: 'บันทึก',
  en: 'English',
  'zh-hant': 'Traditional Chinese',
  'zh-cn': 'Simplified Chinese',
  vi: 'Vietnamese',
  th: 'Thai',
};

export default common;
