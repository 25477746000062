import { AnchorLink as SLAnchorLink } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

type SLAnchorLinkProps = React.ComponentProps<typeof SLAnchorLink>;

const AnchorLink = (props: Omit<SLAnchorLinkProps, 'lang'>) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  return (
    <SLAnchorLink lang={language as SLAnchorLinkProps['lang']} {...props} />
  );
};

export default AnchorLink;
