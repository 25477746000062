import {
  GetConnectedChannelsQueryKey,
  InitialStateQueryKey,
} from '@/types/external-yt-channel-connect/external-yt-channel-connect.types';

export const queryKeys = {
  initialState: (merchantId?: string): InitialStateQueryKey => [
    'external-yt-channnel-connect-page-initial-state',
    merchantId,
  ],
  getConnectedChannels: (merchantId?: string): GetConnectedChannelsQueryKey => [
    'external-yt-channnel-connect-page-get-connected-channel',
    merchantId,
  ],
};
