import { IconButton, Icons } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { PermissionAction, PermissionScope } from '@/hooks/initialState.types';
import usePermissions from '@/hooks/usePermissions';

import ConnectedChannelsBlock from './components/ConnectedChannelsBlock';
import GmcAndContentApiBlock from './components/GmcAndContentApiBlock';

const PageWrapper = styled.div`
  padding: 0 6.5rem;
`;

const YTShoppingAccountLinking = () => {
  const { t } = useTranslation('ytAccountLinking');
  const hasRemovePermission = usePermissions([
    [PermissionScope.youtubeChannelConnects, PermissionAction.remove],
  ]);

  const handleClickFaq = () => {
    window.open(
      'https://support.shoplineapp.com/hc/zh-tw/articles/24191989236377',
    );
  };

  return (
    <PageWrapper>
      <MarginContainer mt="1.5rem">
        <Text color="INK_800" fontType="DisplayLarge" weight="Semibold">
          {t('YouTube Shopping Account Linking Title')}
        </Text>
        <IconButton
          size={2.25}
          Icon={Icons.Outline.QuestionMark}
          iconProps={{ size: 'MEDIUM' }}
          tooltipProps={{ text: '', direction: 'TOP_CENTER' }}
          handleClick={handleClickFaq}
        />
      </MarginContainer>
      <MarginContainer mt="1rem">
        <GmcAndContentApiBlock hasRemovePermission={hasRemovePermission} />
        <MarginContainer mt="1rem">
          <ConnectedChannelsBlock hasRemovePermission={hasRemovePermission} />
        </MarginContainer>
      </MarginContainer>
    </PageWrapper>
  );
};

export default YTShoppingAccountLinking;
