import { Input } from '@shopline/dashboard-ui';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DateTimePicker from '@/components/DateTimePicker';
import InvisibleInput from '@/components/InvisibleInput';
import Spacer from '@/components/Spacer';
import Text from '@/components/Text';
import { FieldError } from '@/constants/fieldError';

import { FormField } from '../formSchema';
import useCanEdit from '../hooks/useCanEdit';
import useExceedDate from '../hooks/useExceedDate';
import { E2E_PREFIX } from '.';
import Step from './Step';

const Grid2Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;
// for increasing date picker popup z-index
const DateWrapper = styled.div`
  z-index: 10;
`;
const PreLineText = styled(Text)`
  white-space: pre-line;
`;

const StepInfoForm = ({ stepNum }: { stepNum: number }) => {
  const { t } = useTranslation('ytLive');
  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();
  const canEdit = useCanEdit();
  const [exceeded] = useExceedDate();

  return (
    <Step title={t('Setup Guide StepInfoForm')} stepNum={stepNum}>
      <Spacer y="16px" />
      <Grid2Wrapper>
        <div>
          <Text fontType="Body" color="INK_800">
            {t('Field youtubeId')}
          </Text>
          <Spacer y="4px" />
          <Controller
            name={FormField.youtubeId}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="e.g. 123456789"
                disabled={!canEdit || exceeded}
                status={fieldState.invalid ? 'invalid' : 'valid'}
                renderMessages={[
                  () =>
                    fieldState.error &&
                    t(fieldState.error.message as FieldError, {
                      ns: 'fieldValidation',
                      fieldName: t('Field youtubeId'),
                    }),
                ]}
                block
                e2eInputId={`${E2E_PREFIX}-step-info-form-youtubeId_input`}
              />
            )}
          />
        </div>
        <div>
          <Text fontType="Body" color="INK_800">
            {t('Field youtubeUrl')}
          </Text>
          <Spacer y="4px" />
          <Controller
            name={FormField.youtubeUrl}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="e.g. https://www.youtube.com/@YourChannel"
                disabled={!canEdit || exceeded}
                status={fieldState.invalid ? 'invalid' : 'valid'}
                renderMessages={[
                  () =>
                    fieldState.error &&
                    t(fieldState.error.message as FieldError, {
                      ns: 'fieldValidation',
                      fieldName: t('Field youtubeUrl'),
                    }),
                ]}
                block
                e2eInputId={`${E2E_PREFIX}-step-info-form-youtubeUrl_input`}
              />
            )}
          />
        </div>
      </Grid2Wrapper>
      <Spacer y="16px" />
      <div>
        <Text fontType="Body" color="INK_800">
          {t('Field brandHomeUrl')}
        </Text>
        <Spacer y="4px" />
        <Controller
          name={FormField.brandHomeUrl}
          control={control}
          disabled
          render={({ field, fieldState }) => (
            <Input
              {...field}
              placeholder="e.g. https://example.shopline.com"
              status={fieldState.invalid ? 'invalid' : 'valid'}
              renderMessages={[
                () =>
                  fieldState.error &&
                  t(fieldState.error.message as any, {
                    ns: 'ytLive',
                    fieldName: t('Field brandHomeUrl'),
                  }),
              ]}
              block
              e2eInputId={`${E2E_PREFIX}-step-info-form-brandHomeUrl_input`}
            />
          )}
        />
      </div>
      <Spacer y="20px" />
      <div>
        <Text fontType="Body" weight="Medium">
          {t('Field date')}
        </Text>
        <Spacer y="4px" />
        <PreLineText fontType="Body" color="INK_600">
          {t('Field date - description')}
        </PreLineText>
        <Spacer y="8px" />
        <Grid2Wrapper>
          <DateWrapper
            data-e2e-id={`${E2E_PREFIX}-step-info-form-date_wrapper`}
          >
            <Text fontType="Body" color="INK_800">
              {t('Field date')}
            </Text>
            <Spacer y="4px" />
            <Controller
              name={FormField.date}
              defaultValue={null}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <DateTimePicker
                    value={field.value}
                    onDateChange={({ date }) => {
                      setValue(FormField.date, date ?? null);
                      if (isSubmitted) {
                        // setTimeout to solve INNO-2016, form value won't update immediately
                        setTimeout(() => {
                          trigger(FormField.date);
                        }, 0);
                      }
                    }}
                    disabled={!canEdit || exceeded}
                    disabledDate={(date) => dayjs(date).isBefore(dayjs())}
                    status={fieldState.invalid ? 'invalid' : 'valid'}
                    renderMessages={[
                      () =>
                        fieldState.error &&
                        t(fieldState.error.message as FieldError, {
                          ns: 'fieldValidation',
                          fieldName: t('Field date'),
                          value: dayjs()
                            .add(1, 'day')
                            .format('YYYY-MM-DD h:mma'),
                        }),
                    ]}
                    minuteInterval={1}
                    customHourFormat="h:mma"
                    block
                  />
                  <InvisibleInput
                    {...field}
                    data-e2e-id={`${E2E_PREFIX}-step-info-form-date_input`}
                  />
                </>
              )}
            />
          </DateWrapper>
        </Grid2Wrapper>
      </div>
    </Step>
  );
};

export default StepInfoForm;
