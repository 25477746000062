import { TextButton } from '@shopline/dashboard-ui';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import LoadingPage from '@/components/LoadingPage';
import Text from '@/components/Text';
import { useToastActionContext } from '@/contexts/Toast';
import useLanguageFromQuery from '@/hooks/externalYTChannelConnect/useLanguageFromQueryString';
import { useQueryInitialState } from '@/hooks/externalYTChannelConnect/useQueryInitialState';

import ConnectYouTubeChannelBlock from './components/ConnectYouTubeChannelBlock';
import GoogleLoginBlock from './components/GoogleLoginBlock';
import LanguageDropdown from './components/LanguageDropdown';

const serviceRequestUrl =
  'https://shopline.atlassian.net/servicedesk/customer/portal/13';

const NavBar = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.SNOW_100};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 2rem;
`;

const LogoImage = styled.img`
  height: 48px;
  width: auto;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 5rem 260px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    margin: 2.5rem 1.5rem;
  }
`;

const TitleDescription = styled(Text)`
  margin-top: 0.75rem;
`;

const ContentContainer = styled.div`
  margin-top: 1.5rem;
  background-color: ${({ theme }) => theme.colors.SNOW_100};
  padding: 1.5rem;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    margin: 1rem;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    margin: 1.5rem;
    margin-top: 5.5rem;
  }
`;

const ShopLink = ({
  shopName,
  shopUrl,
}: {
  shopName?: string;
  shopUrl?: string;
}) => {
  return (
    <TextButton.Primary
      // @ts-ignore
      as="a"
      href={shopUrl}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Text fontType="Body">{shopName}</Text>
    </TextButton.Primary>
  );
};

const ExternalYTChannelConnect = () => {
  const { data, error, isLoading } = useQueryInitialState();
  const isMobileScreen = useMediaQuery('only screen and (max-width : 768px)');
  const { createUIToast } = useToastActionContext();
  const { t } = useTranslation('externalYtConnect');
  useLanguageFromQuery();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    if (error.response?.status === 404) {
      window.location.replace(serviceRequestUrl);
    } else {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'General Error',
          params: { ns: 'common' },
        },
      });
    }
  }

  return (
    <>
      <NavBar>
        {!isMobileScreen && <div />}
        {data?.merchant?.logoUrl ? (
          <LogoImage src={data.merchant.logoUrl} />
        ) : (
          <div />
        )}
        <LanguageDropdown />
      </NavBar>
      <PageWrapper>
        <TitleContainer>
          <Text color="INK_800" fontType="DisplayLarge">
            {t('External YouTube Connect Title')}
          </Text>
          <TitleDescription color="INK_700" fontType="Body">
            <Trans
              t={t}
              i18nKey="External YouTube Connect Description"
              components={{
                ShopLink: (
                  <ShopLink
                    shopUrl={data?.merchant.brandHomeUrl}
                    shopName={data?.merchant.name}
                  />
                ),
              }}
            />
          </TitleDescription>
        </TitleContainer>
        <ContentContainer>
          <GoogleLoginBlock currentUser={data?.user} />
          {data?.user && (
            <MarginContainer mt="1.5rem">
              <ConnectYouTubeChannelBlock shopName={data?.merchant.name} />
            </MarginContainer>
          )}
        </ContentContainer>
      </PageWrapper>
      <Footer>
        <Text color="INK_400" fontType="Micro">
          {t('External YouTube Connect Footer')}
        </Text>
      </Footer>
    </>
  );
};

export default ExternalYTChannelConnect;
