import { Item } from '@shopline/dashboard-ui/types/components/infiniteScrollPicker/interface';
import { useQueryClient } from '@tanstack/react-query';
import { useDebounce, useMap } from '@uidotdev/usehooks';
import contains from 'lodash/fp/contains';
import prop from 'lodash/fp/prop';
import {
  conforms,
  curryRight,
  filter,
  flow,
  overSome,
  toPairs,
} from 'lodash-es';
import memoizee from 'memoizee';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { type TableItem } from '@/hooks/useProducts/transformToTableItem';
import {
  useQuerySelectedProducts,
  useSelectedProductsQueryKey,
} from '@/hooks/useProducts/useSelectedProducts';
import getTranslation from '@/utils/getTranslation';

import { FormField } from '../formSchema';

const curriedGetTranslation = curryRight(getTranslation, 2);

const useSelectLiveProducts = ({ keyword }: { keyword: string }) => {
  const { i18n } = useTranslation();
  const debouncedKeyword = useDebounce(keyword, keyword === '' ? 0 : 300);
  const {
    getValues,
    setValue,
    formState: { isSubmitted },
  } = useFormContext();
  const ids = useMemo(() => getValues('slProductIds'), [getValues]);
  const { data } = useQuerySelectedProducts({
    ids,
  });
  const queryClient = useQueryClient();
  const selectedProductsQueryKey = useSelectedProductsQueryKey();

  const selectedItems: Map<string, TableItem> = useMap<string>();

  useEffect(() => {
    if (data) {
      selectedItems.clear();
      data.forEach((item) => {
        selectedItems.set(item.id, item);
      });
    }
  }, [data]);

  const toggleSelection = (item: Item) => {
    if (selectedItems.has(item.uniqueId)) {
      selectedItems.delete(item.uniqueId);
    } else {
      selectedItems.set(item.uniqueId, item as TableItem);
    }
  };

  const updateSlProductIdsFormValue = () => {
    setValue(FormField.slProductIds, Array.from(selectedItems.keys()), {
      shouldValidate: isSubmitted,
    });
    queryClient.setQueryData(
      selectedProductsQueryKey,
      Array.from(selectedItems.values()),
    );
  };

  const getTranslation = useMemo(
    () => curriedGetTranslation(i18n.language),
    [i18n.language],
  );

  // debouncedKeyword, getTranslation 變動就要重新產生 memoized filter
  const keywordFilter = useMemo(
    () =>
      memoizee<(entry: [string, any]) => boolean>(
        flow(
          prop(1),
          overSome<any>([
            conforms({
              sku: contains(debouncedKeyword),
            }),
            conforms({
              titleTranslations: flow(
                getTranslation,
                contains(debouncedKeyword),
              ),
            }),
          ]),
        ),
        {
          normalizer: prop([0, 0]),
        },
      ),
    [debouncedKeyword, getTranslation],
  );

  const filteredSelectedItems = useMemo(
    () => {
      if (debouncedKeyword !== '') {
        return new Map(filter(toPairs(selectedItems), keywordFilter));
      }
      return selectedItems;
    },
    // selectedItems 變動不會觸發 dep 改變，要監控 size
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItems.size, debouncedKeyword, keywordFilter],
  );

  return {
    selectedItems,
    filteredSelectedItems,
    selectedItemsSize: selectedItems.size,
    toggleSelection,
    updateSlProductIdsFormValue,
  };
};

export default useSelectLiveProducts;
