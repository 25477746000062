import LoadingPage from '../LoadingPage';

export default function BranchLoading({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: JSX.Element;
}) {
  if (isLoading) {
    return <LoadingPage />;
  }

  return children;
}
