import { flatten } from 'lodash';
import { useMemo } from 'react';

import { TableItem } from '../../../hooks/useProducts/transformToTableItem';
import { useQueryProducts } from '../../../hooks/useProducts/useProducts';

const useLiveProducts = ({ keyword }: { keyword?: string }) => {
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useQueryProducts({ keyword });

  const items = useMemo<TableItem[]>(
    () => flatten(data ? data.pages.map((page) => page.items) : []),
    [data],
  );

  const noResult = !isLoading && items.length === 0 && !hasNextPage;
  const showItemCount = hasNextPage ? items.length + 1 : items.length;

  return {
    items,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,

    noResult,
    showItemCount,
  };
};

export default useLiveProducts;
