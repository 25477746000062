import { Button, Popup } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX } from '@/constants/e2eId';
import { useGoogleOAuthLogout } from '@/hooks/externalYTChannelConnect/useGoogleOAuth';

interface LogoutPopupProps {
  onClose: () => void;
}

interface LogoutPopupFooterProps {
  onClose: () => void;
}

export const logoutPopupE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-logout-popup_modal`;
export const logoutPopupConfirmButtonE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-logout-popup-confirm_button`;
export const logoutPopupCancelButtonE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-logout-popup-cancel_button`;
export const logoutPopupBodyE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-logout-popup-body_content`;

const PopupFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const PopupFooter = ({ onClose }: LogoutPopupFooterProps) => {
  const { t } = useTranslation('common');
  const { merchantId } = useParams<{ merchantId: string }>();
  const { mutateAsync: logout, isLoading } = useGoogleOAuthLogout(merchantId);

  const handleClickConfirm = async () => {
    await logout();
    onClose();
  };

  return (
    <PopupFooterContainer>
      <Button.Default
        onClick={onClose}
        e2eId={logoutPopupCancelButtonE2eId}
        height="LARGE"
      >
        {t('Cancel')}
      </Button.Default>
      <MarginContainer ml="1rem">
        <Button.Destructive
          onClick={handleClickConfirm}
          isLoading={isLoading}
          e2eId={logoutPopupConfirmButtonE2eId}
          width="WIDE"
          height="LARGE"
        >
          {t('Confirm')}
        </Button.Destructive>
      </MarginContainer>
    </PopupFooterContainer>
  );
};

const LogoutPopup = ({ onClose }: LogoutPopupProps) => {
  const { t } = useTranslation('externalYtConnect');
  const title = t('External YouTube Connect Logout Popup Title');
  const content = t('External YouTube Connect Logout Popup Content');
  return (
    // @ts-ignore react 18 removed `children` as a default prop
    <Popup
      e2eId={logoutPopupE2eId}
      onClose={onClose}
      title={title}
      renderFooter={() => <PopupFooter onClose={onClose} />}
    >
      <Text e2eId={logoutPopupBodyE2eId} color="INK_800" fontType="Subheading">
        {content}
      </Text>
    </Popup>
  );
};

export default LogoutPopup;
