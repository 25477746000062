import { Icons } from '@shopline/dashboard-ui';
import { useMemo } from 'react';
import styled from 'styled-components';

import Spacer from '@/components/Spacer';
import Text from '@/components/Text';

import { YT_LIVE_E2E_PREFIX } from '../constants';

type IconProps = React.ComponentProps<typeof Icons.Solid.StepOne>;
const E2E_PREFIX = `${YT_LIVE_E2E_PREFIX}-setup-guide-step`;

const StepWrapper = styled.div`
  display: flex;
`;
const StepNumber = styled.div`
  flex: 0 0 36px;
`;
const StepContent = styled.div`
  flex: 1;
`;
const StepAction = styled.div`
  flex: 0 0 0;
  margin-left: 1rem;
  text-align: right;
`;
const Step = ({
  stepNum,
  children,
  title,
  actionButton,
  hasError = false,
}: {
  stepNum: number;
  title: string;
  children: React.ReactNode;
  actionButton?: React.ReactNode;
  hasError?: boolean;
}) => {
  const numberIcon = useMemo(() => {
    const props: Pick<IconProps, 'size' | 'color'> = {
      size: 'MEDIUM',
      color: hasError ? 'RED_600' : 'INK_600',
    };

    const iconMap = [
      <Icons.Solid.StepOne {...props} />,
      <Icons.Solid.StepTwo {...props} />,
      <Icons.Solid.StepThree {...props} />,
      <Icons.Solid.StepFour {...props} />,
      <Icons.Solid.StepFive {...props} />,
    ];
    return iconMap[stepNum - 1];
  }, [stepNum, hasError]);

  return (
    <StepWrapper data-e2e-id={`${E2E_PREFIX}-${stepNum}`}>
      <StepNumber>{numberIcon}</StepNumber>
      <StepContent>
        <Text
          fontType="Body"
          weight="Medium"
          color={hasError ? 'RED_600' : 'INK_800'}
        >
          {title}
        </Text>
        <Spacer y="4px" />
        {children}
      </StepContent>
      <StepAction>{actionButton}</StepAction>
    </StepWrapper>
  );
};

export default Step;

export const StepTitle = styled(Text)`
  margin-bottom: 0.25rem;
  display: block;
`;
