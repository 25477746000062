const ytAccountLinking = {
  'YouTube Shopping Account Linking Title': 'YouTube Shopping Integration',
  'Store Setup Title': '1. Store setup',
  'Store Setup Description':
    'Please make sure you can manage your product ads in Google Merchant Center. Then, proceed to Step 2 to copy the connection link and share it with the YouTube Channel manager you‘re collaborating with.',
  'Store Setup GMC Title': 'Connect to Google Merchant Center',
  'Store Setup GMC Description':
    "Once connected, your store's product information will be automatically uploaded to your Google Merchant Center account. Please avoid changing your GMC account after connecting it to the YouTube Channel. Otherwise, you will need to reauthorize the channel to update the latest product information.",
  'Learn More': 'Learn more',
  'Setup Now': 'Set up',
  'Store Setup GMC Account Connected':
    'The store has been connected to the following Google Merchant Center account',
  'Store Setup GMC Finished': 'Connected',
  'Store Setup GMC ID Changed':
    'The GMC account has been changed. Some connected channels need to be reauthorized.',
  'Store Setup Content API Title': 'Activate Content API',
  'Store Setup Content API Description':
    'Through the Content API, product updates and hidden product information will be updated to GMC in real time.',
  'Store Setup Content API Status': 'Google Content API connection status',
  'Store Setup Content API Finished': 'Connected',
  'Connected Channels Title': '2. Connected YouTube Shopping Channel',
  'Connected Channels Description':
    "Please copy and share the link with the YouTube Channel manager you're collaborating with. Once they complete the authorization, they can include your store's products in their livestreams or videos.",
  'Connected Channels Copy Link': 'Copy link',
  'Connected Channels Copy Success': 'Copied',
  'Connected Channels No Channels Connected':
    'You have not established any YouTube Shopping integration',
  'Connected Channels Channels Connected':
    'The following channels have been connected with your store',
  'Connected Channels Not Authorized': 'Not authorized',
  'Connected Channels Disconnect Tooltip':
    'If you need to disconnect the channel, please contact your Merchant Success consultant.',
  'Connected Channels Disconnect Channel': 'Disconnect',
  'Connected Channels Disconnect Popup Title':
    'Are you sure you want to disconnect the YouTube Shopping integration between this channel and {Store name}?',
  'Connected Channels Disconnect Popup Description':
    'Once disconnected, the channel will no longer have access to the store products.',
  'Connected Channels Confirm Disconnect': 'Disconnect',
  'Connected Channels Cancel Disconnect': 'Disconnected',
  'Connected Channels Reconnect as GMC ID Changed':
    'The Google Merchant Center account has been changed. Please reauthorize the channel.',
};

export default ytAccountLinking;
