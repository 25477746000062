import { ColumnConfig } from '@shopline/dashboard-ui/types/components/infiniteScrollPicker/interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ProductPrice from './ProductPrice';
import ProductStatus from './ProductStatus';
import ProductTitle from './ProductTitle';

const useColumns = ({
  tooltipContainerId,
}: {
  tooltipContainerId?: string;
} = {}) => {
  const { t } = useTranslation('ytLive');

  const columns = useMemo<ColumnConfig[]>(
    () => [
      {
        key: 'title',
        title: t('Live Products columns - Product'),
        renderCell: ({ image, titleTranslations }) => (
          <ProductTitle image={image} titleTranslations={titleTranslations} />
        ),
      },
      {
        key: 'priceLabel',
        title: t('Live Products columns - Price'),
        renderCell: ({ priceLabel, priceSaleLabel }) => (
          <ProductPrice
            priceLabel={priceLabel}
            priceSaleLabel={priceSaleLabel}
          />
        ),
      },
      {
        key: 'inventory',
        title: t('Live Products columns - Inventory'),
      },
      {
        key: 'status',
        title: t('Live Products columns - Status'),
        renderCell: (item) => (
          <ProductStatus
            isReminderActive={item.isReminderActive}
            isHidden={item.isHidden}
            schedulePublishAt={item.schedulePublishAt}
            availableStartTime={item.availableStartTime}
            availableEndTime={item.availableEndTime}
            tooltipContainerId={tooltipContainerId}
          />
        ),
      },
    ],
    [t, tooltipContainerId],
  );

  return columns;
};

export default useColumns;
