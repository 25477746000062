const externalYtConnect = {
  'External YouTube Connect Title': 'YouTube Shopping Integration',
  'External YouTube Connect Description':
    "By logging in to your Google account, you can connect <ShopLink></ShopLink> to your YouTube Shopping channel and access the store's products.",
  'External YouTube Connect Google Login Title': '1. Log in to Google account',
  'External YouTube Connect Google Login Description':
    'Please ensure you log in with the Gmail account that has been granted manager access to the YouTube Channel.',
  'External YouTube Connect Login': 'Log in',
  'External YouTube Connect Logout': 'Log out',
  'External YouTube Connect Connected Channels': '2. Connected YouTube Channel',
  'External YouTube Connect No Connected Channels':
    'You have not established any YouTube Shopping integration',
  'External YouTube Connect With Connected Channels':
    'You have connected the following channels to the store',
  'External YouTube Connect Add Channel': 'Add channel',
  'External YouTube Connect Channel Name': 'Channel name',
  'External YouTube Connect Channel ID': 'Channel ID',
  'External YouTube Connect Add Channel Success': 'Added successfully',
  'External YouTube Connect Information Missing': 'Some information is missing',
  'External YouTube Connect Add No Permission':
    "You don't have enough permission",
  'External YouTube Connect Channel Not Eligible': 'Channel not eligible',
  'External YouTube Connect Add Channel Error':
    'Something is wrong with the channel',
  'External YouTube Connect Footer':
    "© 2013 - 2024 SHOPLINE. Asia's E-Commerce Solution.",
};

export default externalYtConnect;
