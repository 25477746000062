import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { SlProductListResponse } from './sl-product.types';
import { transformToTableItem } from './transformToTableItem';

type QueryParam = { merchantId: string; keyword?: string };
type QueryKey = ['products', QueryParam];
const PER_PAGE = 10;

const getProducts = async ({
  merchantId,
  page = 1,
}: {
  merchantId: string;
  page: number;
}) => {
  const { data } = await axios.get<SlProductListResponse>(
    `/api/merchants/${merchantId}/products`,
    {
      params: {
        perPage: PER_PAGE,
        page,
        sortBy: 'desc',
      },
    },
  );
  const tableItemsData = {
    items: data.items.map(transformToTableItem),
    pagination: data.pagination,
  };
  return tableItemsData;
};

const getProductsByKeyword = async ({
  merchantId,
  keyword,
  page = 1,
}: QueryParam & { page: number }) => {
  const { data } = await axios.get<SlProductListResponse>(
    `/api/merchants/${merchantId}/products/search`,
    {
      params: {
        perPage: PER_PAGE,
        keyword,
        page,
        sortBy: 'desc',
      },
    },
  );
  const tableItemsData = {
    items: data.items.map(transformToTableItem),
    pagination: data.pagination,
  };
  return tableItemsData;
};

const passParams = (queryFn: typeof getProductsByKeyword) => {
  return ({
    queryKey: [, { merchantId, keyword }],
    pageParam,
  }: QueryFunctionContext<QueryKey>) =>
    queryFn({ merchantId, keyword, page: pageParam });
};

const composeQueryKey = ({ merchantId, keyword }: QueryParam): QueryKey => [
  'products',
  { merchantId, keyword },
];

export const useQueryProducts = ({ keyword }: { keyword?: string }) => {
  const { merchantId } = useParams() as { merchantId: string };
  const debouncedKeyword = useDebounce(keyword, keyword === '' ? 0 : 300);

  return useInfiniteQuery({
    queryKey: composeQueryKey({ merchantId, keyword: debouncedKeyword }),
    queryFn: passParams(keyword ? getProductsByKeyword : getProducts),
    getNextPageParam: (lastPage) =>
      lastPage.pagination.currentPage < lastPage.pagination.totalPages
        ? lastPage.pagination.currentPage + 1
        : undefined,
    enabled: !!merchantId,
  });
};
