import { Button } from '@shopline/dashboard-ui';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { YT_LIVE_E2E_PREFIX } from '../constants';
import { FormField } from '../formSchema';
import useCanEdit from '../hooks/useCanEdit';
import useExceedDate from '../hooks/useExceedDate';
import LiveProductsPicker from './LiveProductsPicker';
import LiveProductsTable from './LiveProductsTable';

const CenterWrapper = styled.div`
  text-align: center;
`;
export const E2E_PREFIX = `${YT_LIVE_E2E_PREFIX}-live-products`;

const LiveProducts = () => {
  const { t } = useTranslation('ytLive');
  const canEdit = useCanEdit();
  const [exceeded] = useExceedDate();

  const [isOpen, setIsOpen] = useState(false);

  const slProductIds = useWatch({ name: FormField.slProductIds });

  return (
    <div data-e2e-id={E2E_PREFIX}>
      {slProductIds?.length > 0 && <LiveProductsTable ids={slProductIds} />}
      <CenterWrapper>
        <Button.Default
          onClick={() => setIsOpen(true)}
          type="button"
          disabled={!canEdit || exceeded}
          e2eId={`${E2E_PREFIX}-add-product_button`}
        >
          {t('Add Products')}
        </Button.Default>
      </CenterWrapper>
      {isOpen && <LiveProductsPicker onClose={() => setIsOpen(false)} />}
    </div>
  );
};

export default LiveProducts;
