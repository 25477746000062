const externalYtConnect = {
  'External YouTube Connect Title': 'YouTube Shopping 商店串接',
  'External YouTube Connect Description':
    '透過 Google 登入授權，您可以串接 <ShopLink></ShopLink> 與 YouTube Shopping，方便您輕鬆地在 YouTube 頻道上宣傳商品。',
  'External YouTube Connect Google Login Title': '登入 Google 帳號',
  'External YouTube Connect Google Login Description':
    '請確保登入的 Google 帳號有欲串接的 YouTube 頻道擁有者權限	',
  'External YouTube Connect Login': '登入',
  'External YouTube Connect Logout': '登出',
  'External YouTube Connect Logout Popup Title': '確認登出？',
  'External YouTube Connect Logout Popup Content': '確認要登出嗎？',
  'External YouTube Connect Logout Success': '您已成功登出',
  'External YouTube Connect Connected Channels Title': '串接頻道與商店',
  'External YouTube Connect Connected Channels Description':
    '點按「串接」以連接頻道與商店，完成後即可在頻道後台查看此商店的商品。',
  'External YouTube Connect Connected Channels Description Faq Tooltip':
    '一個 YouTube 頻道可串接多間商店，此次串接操作，不會影響過往已串接的頻道。',
  'External YouTube Connect No Connected Channels Connect Button': '串接',
  'External YouTube Connect Disconnect Channel': '解除串接',
  'External YouTube Connect Connected Channels GMC Account Changed':
    'Google Merchant Center 帳號異動，請重新串接頻道',
  'External YouTube Connect No Eligible Channels':
    '你的 Google 帳號並不是任何符合 YouTube 購物資格頻道的擁有者',
  'External YouTube Connect With Connected Channels':
    '以下頻道已與此商店完成 YouTube Shopping 串接',
  'External YouTube Connect Disconnect Channel Popup Title':
    '確認解除此頻道與 {{shopName}} 的 YouTube Shopping 串接？',
  'External YouTube Connect Disconnect Channel Popup Content':
    '解除串接後，在 YouTube 頻道將無法顯示任何商店商品',
  'External YouTube Connect Disconnect Channel Popup Confirm': '確定解除',
  'External YouTube Connect Disconnect Channel Popup Cancel': '取消',
  'External YouTube Connect Disconnect Channel Connect Success Toast':
    '串接成功',
  'External YouTube Connect Disconnect Channel Disconnect Success Toast':
    '解除成功',
  'External YouTube Connect Disconnect Channel Error Information Missing':
    '欄位資料缺失',
  'External YouTube Connect Disconnect Channel Error No Permission':
    '管理員權限不足',
  'External YouTube Connect Disconnect Channel Error Not Eligible':
    '頻道不符合資格',
  'External YouTube Connect Disconnect Channel Error Something Wrong':
    '頻道發生錯誤',
  'External YouTube Connect Disconnect Channel Error Already Linked':
    '頻道已串接',
  'External YouTube Connect Connect Channel Error Merchant Not Integrated':
    '商店 與 Google Merchant Center 帳號之間的連結已失效',
  'External YouTube Connect Add Channel': '新增頻道',
  'External YouTube Connect Channel Name': '頻道名稱',
  'External YouTube Connect Channel ID': '頻道 ID',
  'External YouTube Connect Add Channel Success': '新增頻道成功',
  'External YouTube Connect Information Missing': '欄位資料缺失',
  'External YouTube Connect Add No Permission': '管理員權限不足',
  'External YouTube Connect Channel Not Eligible': '頻道不符合資格',
  'External YouTube Connect Add Channel Error': '頻道發生錯誤',
  'External YouTube Connect Footer':
    "© 2013 - 2024 SHOPLINE. Asia's E-Commerce Solution.",
};

export default externalYtConnect;
