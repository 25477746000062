const externalYtConnect = {
  'External YouTube Connect Title': 'YouTube Shopping 商店串接',
  'External YouTube Connect Description':
    '透过 Google 登入授权，您可以串接 <ShopLink></ShopLink> 与 YouTube Shopping，方便您轻松地在 YouTube 频道上宣传商品。',
  'External YouTube Connect Google Login Title': '登入 Google 帐号',
  'External YouTube Connect Google Login Description':
    '请确保登入的 Google 帐号有欲串接的 YouTube 频道拥有者权限',
  'External YouTube Connect Login': '登录',
  'External YouTube Connect Logout': '登出',
  'External YouTube Connect Logout Popup Title': '确认登出？',
  'External YouTube Connect Logout Popup Content': '确认要登出吗？',
  'External YouTube Connect Logout Success': '您已成功登出',
  'External YouTube Connect Connected Channels Title': '串接频道与商店',
  'External YouTube Connect Connected Channels Description':
    '点按「串接」以连接频道与商店，完成后即可在频道后台查看此商店的商品。',
  'External YouTube Connect Connected Channels Description Faq Tooltip':
    '一个 YouTube 频道可串接多间商店，此次串接操作，不会影响过往已串接的频道。',
  'External YouTube Connect No Connected Channels Connect Button': '串接',
  'External YouTube Connect Disconnect Channel': '解除串接',
  'External YouTube Connect Connected Channels GMC Account Changed':
    'Google Merchant Center 帐号异动，请重新串接频道',
  'External YouTube Connect No Eligible Channels':
    '你的 Google 账号并不是任何符合 YouTube 购物资格频道的拥有者',
  'External YouTube Connect With Connected Channels':
    '以下频道已与商店完成 YouTube Shopping 白名单串接',
  'External YouTube Connect Disconnect Channel Popup Title':
    '确认解此频道与 {{shopName}} 的 YouTube Shopping 串接？',
  'External YouTube Connect Disconnect Channel Popup Content':
    '解除串接后，在 YouTube 频道将无法显示任何商店商品',
  'External YouTube Connect Disconnect Channel Popup Confirm': '确定解除',
  'External YouTube Connect Disconnect Channel Popup Cancel': '取消',
  'External YouTube Connect Disconnect Channel Connect Success Toast':
    '串接成功',
  'External YouTube Connect Disconnect Channel Disconnect Success Toast':
    '解除成功',
  'External YouTube Connect Disconnect Channel Error Information Missing':
    '栏位资料缺失',
  'External YouTube Connect Disconnect Channel Error No Permission':
    '管理员权限不足',
  'External YouTube Connect Disconnect Channel Error Not Eligible':
    '频道不符合资格',
  'External YouTube Connect Disconnect Channel Error Something Wrong':
    '频道发生错误',
  'External YouTube Connect Disconnect Channel Error Already Linked':
    '频道已串接',
  'External YouTube Connect Connect Channel Error Merchant Not Integrated':
    '商店 与 Google Merchant Center 帐号之间的连结已失效',
  'External YouTube Connect Add Channel': '新增频道',
  'External YouTube Connect Channel Name': '频道名称',
  'External YouTube Connect Channel ID': '频道 ID',
  'External YouTube Connect Add Channel Success': '新增频道成功',
  'External YouTube Connect Information Missing': '栏位资料缺失',
  'External YouTube Connect Add No Permission': '管理员权限不足',
  'External YouTube Connect Channel Not Eligible': '频道不符合资格',
  'External YouTube Connect Add Channel Error': '频道发生错误',
  'External YouTube Connect Footer':
    "© 2013 - 2024SHOPLINE. Asia's E-Commerce Solution.",
};

export default externalYtConnect;
