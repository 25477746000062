import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import transformObjectKeys, { SnakeCase } from 'transform-object-keys';

import { InitialState } from './initialState.types';

type QueryKey = ['initial-state', string];

const getInitialState = async (merchantId: string) => {
  const { data } = await axios.get<SnakeCase<InitialState, true>>(
    `/api/initial-state?merchant_id=${merchantId}`,
  );
  return transformObjectKeys(data, { deep: true }) as unknown as InitialState;
};

const passMerchantId =
  (queryFn: typeof getInitialState) =>
  ({ queryKey: [, merchantId] }: QueryFunctionContext<QueryKey>) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId: string): QueryKey => [
  'initial-state',
  merchantId,
];

const useQueryInitialState = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery({
    queryKey: composeQueryKey(merchantId as string),
    queryFn: passMerchantId(getInitialState),
    enabled: !!merchantId,
  });
};

export default useQueryInitialState;
