import { ProductDisabledType } from '@/features/YTLive/LiveProducts/getProductDisabledType';

const ytLive = {
  'Apply Now': '立即申請',
  'Go to Set': '前往設定',
  'Manage Settings': '管理設定',
  'Go to Install': '前往安裝',
  'Learn more': '了解更多',
  'Linkage Completed': '已完成連接',

  'Setup Guide': '開播指南',
  'Setup Guide - description':
    '開始 YouTube 直播前，您需要完成以下設置。注意：您需要通過 Google Merchant Center 的審核並擁有 YPP 資格才可以開啟 YouTube 直播',
  'Setup Guide - error': '開播指南是必填項目，請確保以下步驟都完成設定',
  'Setup Guide - nav content': '按步驟完成設定與串接',
  'Setup Guide StepTrackerSetting': '前往追蹤設定編輯 GA4',
  'Setup Guide StepTrackerSetting - description':
    'GA4 能讓您追蹤網站所有顧客瀏覽、點擊足跡，及紀錄購買行為、事件和價值，並能跨裝置追蹤 YouTube Live Shopping 直播的導購收益 <LinkButton>了解更多</LinkButton>',
  'Setup Guide StepTrackerSetting - support link':
    'https://support.shoplineapp.com/hc/zh-tw/articles/8646049467801',
  'Setup Guide StepContentApi':
    '前往擴充功能安裝 Google Merchant Center Content API 及設定隱藏商品',
  'Setup Guide StepContentApi - description':
    '透過 Content API 串接自動上載你的商品資料到 Google Merchant Center，可以在 Google 廣告、YouTube Live Shopping 直播購物功能及其他 Google 服務中使用 <LinkButton>了解隱藏商品應用</LinkButton>',
  'Setup Guide StepContentApi - connected account':
    '已串接到以下 Google Merchant Center 帳號',
  'Setup Guide StepGmc': '請至 Google Merchant Center 後台設定 GMC、GA4 串接',
  'Setup Guide StepGmc - description':
    '請您至 GMC 後台連結您的 GA4 帳號，方能於 YouTube 頻道後台看到您的商品 <LinkButton>了解更多</LinkButton>',
  'Setup Guide StepGmc - caption':
    '注意：Google Merchant Center 和 YouTube 頻道需要是同一組 Email，若不是的話需要您：前往 Google Merchant Center > 工具與設定 > 帳戶存取權 > 使用者 > 新增使用者 > 加入 YouTube 頻道申請時的該組 Email',
  'Setup Guide StepInfoForm': '填寫資訊以連接 YouTube',
  'Field youtubeId': 'YouTube 頻道 ID',
  'Field youtubeUrl': 'YouTube 頻道 URL',
  'Field brandHomeUrl': '商店 URL',
  'Field date': '預計直播時間',
  'Field date - description':
    '請注意！在您按下前往開播按鈕時，您的商品才會實際展示於 Google Merchant Center / YouTube 後台，以上兩大步驟設定完成後，建議您於正式直播的三天前，在預計直播的 YouTube 頻道先擇期試播一次，以確保順利開播。\n*提醒您，因 YouTube / Google Merchant Center 平台上傳商品限制，僅能選擇當下 24 小時後的時間作為您的預計直播時間。',

  'HiddenProductModal - title': '隱藏商品露出於直播間時間',
  'HiddenProductModal - page 1 title': '第一步：前往 SHOPLINE 後台',
  'HiddenProductModal - page 1 description':
    '若您想在直播當下才將商品露出，請至「商品 > 商品設定 > 隱藏商品」將該建立好的商品按鈕開啟，商品將會先被暫時隱藏。',
  'HiddenProductModal - page 2 title': '第二步：設定好 Content API',
  'HiddenProductModal - page 2 description':
    '前往擴充功能商店「Google Content API - Google Merchant Center 產品資料來源」 設定好 Content API，我們會將您選擇到的隱藏商品即時上傳到 Google Merchant Center 後台，您可以隨時調整您的商品規格及資訊',
  'HiddenProductModal - page 3 title': '第三步：設定好預計直播時間',
  'HiddenProductModal - page 3 description':
    '預計直播時間選擇好後，隱藏商品將會隨著您選擇到的預計直播時間露出於商店前台及 YouTube 前後台',
  'HiddenProductModal - page 4 title': '第四步：再次前往 SHOPLINE 後台',
  'HiddenProductModal - page 4 description':
    '請於直播當下或直播前，前往「商品 > 商品設定 > 隱藏商品」將該建立好的隱藏商品按鈕關閉，商品將會即時露出於前台。*請注意：若您想於直播前提前曝光商品，可提早將按鈕關閉。\n另外提醒您：因 Google Merchant Center 平台限制，24 小時才更新一次商品狀態，故建議您於直播前 24 小時不再去更動所有商品規格、及預計直播的時間，避免直播途中發生商品未及時在 Google Merchant Center 及 YouTube Channel 後台露出的問題。',

  'Live Products': '直播商品',
  'Live Products - counter': '({{count}}/{{max}})',
  'Live Products - description':
    '請提供您預計直播的時間。選擇好後，隱藏商品將會隨著您選擇到的預計直播時間露出於商店前台及 YouTube 前後台。另外提醒您：因 Google Merchant Center 及 YouTube 平台限制，在您選擇到「預計直播時間」後，我們在您直播前 24 小時將不再開放編輯時間。',
  'Live Products - min error': '直播商品是必填項目，請選擇直播商品',
  'Live Products - max error':
    '一個直播間僅限添加 {{count}} 個商品，請減少直播商品數量',
  'Live Products - nav content': '選擇或建立直播的商品',
  'Add Products': '選擇直播商品',
  'Live Products columns - Product': '商品',
  'Live Products columns - Inventory': '可售數量',
  'Live Products columns - Status': '狀態',
  'Live Products status - reminder on': '缺貨提醒：開啟',
  'Live Products status - reminder off': '缺貨提醒：關閉',
  'Live Products status - hidden': '隱藏商品',
  'Live Products status - schedulePublishAt': '於 {{date}} 上架',
  'Live Products status - availableStartTime start': '開始銷售：{{date}}',
  'Live Products status - availableStartTime period': '銷售時間：{{date}}',
  'Live Products status - availableStartTime end': '銷售結束：{{date}}',
  [`Live Prdoucts disabled_${ProductDisabledType.unpublished}`]: '已下架',
  [`Live Prdoucts disabled_${ProductDisabledType.emptyPrice}`]:
    '無法添加無價格商品',
  [`Live Prdoucts disabled_${ProductDisabledType.hiddenPrice}`]:
    '無法添加隱藏價格商品',
  'LiveProductsPicker - SearchBar placeholder': '搜尋商品名稱或 SPU',
  'LiveProductsPicker - No result': '找不到商品',
  'LiveProductsPicker - No result description':
    '請嘗試更改關鍵字或篩選套用詞/編號',
  'LiveProductsPicker - View selected products':
    '檢視已選商品 ({{count}}/{{max}})',
  'LiveProductsPicker - View all products': ' 檢視全部商品',

  'Youtube Admin Preparation': 'YouTube 後台準備',
  'Youtube Admin Preparation - description':
    '由於 YouTube 平台限制，暫不支援於 SHOPLINE 後台直接開播，更多直播功能敬請期待。如需終止直播請至 YouTube 後台操作。開始直播售賣商品前，請參考以下步驟：',
  'Youtube Admin Preparation - nav content': '需要在平台設定做好準備',
  'Youtube Admin Preparation StepOne': '於 YouTube 工作室設定頻道展示商品',
  'Youtube Admin Preparation StepOne - description':
    '當您設定好 Google Merchant Center Content API 後，商品將自動上傳至您的 Google Merchant Center 及 YouTube Live Shopping 後台，您可以至 YouTube 後台管理設定中心將想要在直播當下展示的商品勾選起來。',
  'Youtube Admin Preparation StepTwo': '標記直播中要展示的商品',
  'Youtube Admin Preparation StepTwo - description':
    '並可於直播當下標記釘選商品至頻道中，讓您的買家能夠邊看邊買',
  'Youtube Admin Preparation StepThree': '在預定時間開播並置頂商品',
  'Youtube Admin Preparation StepThree - description':
    '直播期間，可搭配置頂功能讓介紹的商品更顯眼。',

  'Finish Setting': '完成',
  'Finish Setting - description': '完成以上設定步驟之後，就可以馬上開播了！',
  'Start Streaming': '前往 YouTube 開播',
  'exceeded the LIVE Broadcast time':
    '此直播間已失效（超過直播時間），本次編輯時間將不儲存；欲進行開播請新增一新的直播間',
  'Youtube Tnc':
    '於設定本功能時您已充分知悉相關規定，請參閱<YoutubePrivacyLink></YoutubePrivacyLink>及以下相關規定。',
  'Youtube Privacy': 'YouTube 隱私權',
  'Youtube Tnc Detail':
    '請注意，當您進行串接 YouTube Shopping 時，代表您即同意網路商店中來源為 YouTube 的訂單數據傅輪給 YouTube。',
  'Setup Guide YouTube Shopping Setup': 'YouTube Shopping 直播間設定',
  'Field Channel': '頻道',
  'Setup Guide Choose Channel Title': '選擇 YouTube 頻道',
  'Setup Guide Choose Channel Description':
    '注意：商店包含隱藏商品，在所有串接的 YouTube 頻道後台將顯示這些商品。',
  'Setup Guide Choose Channel Dropdown Placeholder': '請選擇',
  'Setup Now': '前往啟用',
  'Setup Guide No Available Channels': '商店沒有已串接的 YouTube 頻道',
  'Schedule Live Stream Title': '預計直播時間',
  'Schedule Live Stream Description':
    '請選擇您預計直播的時間，隱藏商品將在預計直播時間顯示於商店前台及 YouTube 前後台。\n*提醒您，因 Google Merchant Center 及 YouTube 平台上傳商品限制，僅能選擇當下 24 小時後的時間作為您的預計直播時間，且在直播前 24 小時不支援編輯時間。',
  'Live Stream Products Title': '直播商品',
  'Live Stream Products Description':
    '請選擇直播商品，在您按下儲存按鈕時，您的商品才會實際顯示於 Google Merchant Center / YouTube 後台。',
  'YouTube Preparation Title': 'YouTube 後台準備',
  'YouTube Preparation Description':
    '由於 YouTube 平台限制，暫不支援於 SHOPLINE 後台直接開播，終止直播也請至 YouTube 後台操作，更多直播功能敬請期待。\n開始直播販售商品前，請參考以下步驟：',
  'Account Linking YouTube Preparation StepOne':
    '於 YouTube 工作室設定頻道展示商品',
  'Account Linking YouTube Preparation StepOne - description':
    '當您已完成 Google Merchant Center Content API 設定，商品將自動上傳至您的 Google Merchant Center 及 YouTube 後台，您可以至 YouTube 後台選取要在頻道展示的商品。',
  'Account Linking YouTube Preparation StepTwo': '標記直播中要展示的商品',
  'Account Linking YouTube Preparation StepTwo - description':
    '您可以標記商品，使其展示在直播中，讓觀眾能夠邊看邊買。',
  'Account Linking YouTube Preparation StepThree': '在預定時間開播並置頂商品',
  'Account Linking YouTube Preparation StepThree - description':
    '直播期間，可搭配置頂功能讓介紹的商品更顯眼。',
  'Finish YouTube Setting': '完成',
  'Content Api Description':
    '於設定本功能時您已充分知悉相關規定，請參閱 YouTube 隱私權政策及以下相關規定。\n請注意，當您串接 YouTube Shopping 時，代表您同意將網路商店中來自 YouTube 的訂單數據傳輸給 YouTube。',
};

export default ytLive;
