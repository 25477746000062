import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AuthedRoute from '@/components/AuthedRoute';
import { PermissionAction, PermissionScope } from '@/hooks/initialState.types';

import ExternalYTChannelConnect from './ExternalYTChannelConnect';
import YTLive from './YTLive';
import YTShoppingAccountLinking from './YTShoppingAccountLinking';

const router = createBrowserRouter([
  {
    path: '/yt-live/:merchantId/live-room/:liveRoomId',
    element: (
      <AuthedRoute
        permissions={[[PermissionScope.liveRoomSetting, PermissionAction.show]]}
      >
        <YTLive />
      </AuthedRoute>
    ),
  },
  {
    path: '/youtube-shopping-account-linking/:merchantId',
    element: (
      <AuthedRoute
        permissions={[
          [PermissionScope.youtubeChannelConnects, PermissionAction.show],
        ]}
      >
        <YTShoppingAccountLinking />
      </AuthedRoute>
    ),
  },
  {
    path: '/merchants/:merchantId',
    element: <ExternalYTChannelConnect />,
  },
]);

const FeatureRoute = () => {
  return <RouterProvider router={router} />;
};

export default FeatureRoute;
