import { prop } from 'lodash/fp';
import styled from 'styled-components';

const LineThrough = styled.div`
  text-decoration: line-through;
  flex-basis: 100%;
`;
const RedText = styled.div`
  color: ${prop('theme.colors.RED_600')};
`;

interface Props {
  priceLabel: string;
  priceSaleLabel: string;
}

const ProductPrice = ({ priceLabel, priceSaleLabel }: Props) => {
  if (priceSaleLabel) {
    return (
      <div>
        <LineThrough>{priceLabel}</LineThrough>
        <RedText>{priceSaleLabel}</RedText>
      </div>
    );
  }

  return <>{priceLabel}</>;
};

export default ProductPrice;
