import { DateTimePicker } from '@shopline/dashboard-ui';
import dayjs from 'dayjs';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import InvisibleInput from '@/components/InvisibleInput';
import Spacer from '@/components/Spacer';
import Text from '@/components/Text';

import { YT_LIVE_E2E_PREFIX } from '../constants';
import { FormField } from '../formSchemaV2';
import useCanEdit from '../hooks/useCanEdit';
import useExceedDate from '../hooks/useExceedDate';

export const E2E_PREFIX = `${YT_LIVE_E2E_PREFIX}-setup-guide`;

const Container = styled.div`
  z-index: 10;
  width: 24.875rem;
`;

const LiveDate = () => {
  const { t } = useTranslation('ytLive');
  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();
  const canEdit = useCanEdit();
  const [exceeded] = useExceedDate();
  return (
    <>
      <Text fontType="Body" color="INK_800">
        {t('Field date')}
      </Text>
      <Spacer y="4px" />
      <Container>
        <Controller
          name={FormField.date}
          defaultValue={null}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <DateTimePicker
                value={field.value}
                onDateChange={({ date }) => {
                  setValue(FormField.date, date ?? null);
                  if (isSubmitted) {
                    // setTimeout to solve INNO-2016, form value won't update immediately
                    setTimeout(() => {
                      trigger(FormField.date);
                    }, 0);
                  }
                }}
                disabled={!canEdit || exceeded}
                disabledDate={(date) => dayjs(date).isBefore(dayjs())}
                status={fieldState.invalid ? 'invalid' : 'valid'}
                renderMessages={[
                  () =>
                    fieldState.error &&
                    t(fieldState.error.message as unknown as FieldError, {
                      ns: 'fieldValidation',
                      fieldName: t('Field date'),
                      value: dayjs().add(1, 'day').format('YYYY-MM-DD h:mma'),
                    }),
                ]}
                minuteInterval={1}
                customHourFormat="h:mma"
                block
              />
              <InvisibleInput
                {...field}
                data-e2e-id={`${E2E_PREFIX}-step-info-form-date_input`}
              />
            </>
          )}
        />
      </Container>
    </>
  );
};

export default LiveDate;
