import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePostMessageContext } from '@/contexts/PostMessage';

const LanguageDetector = () => {
  const { i18n } = useTranslation();
  const [{ languageChange }] = usePostMessageContext();

  useEffect(() => {
    if (languageChange) {
      i18n.changeLanguage(languageChange);
    }
  }, [i18n, languageChange]);

  return null;
};

export default LanguageDetector;
