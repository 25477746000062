import {
  Checkbox,
  IconButton,
  Icons,
  TextButton,
} from '@shopline/dashboard-ui';
import { CSSProperties, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Text from '@/components/Text';

import { YT_LIVE_E2E_PREFIX } from '../constants';
import { FormField } from '../formSchema';

interface FlexProps {
  flexDirection?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
}

const YOUTUBE_PRIVACY_URL =
  'https://support.google.com/merchants/answer/6149970';

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
`;

const TncTextWrapper = styled(Text)`
  margin-left: 0.5rem;
  line-height: 1.6;
`;

const YoutubeTncLinkWrapper = styled.span`
  display: inline-block;
  padding: 1px 0.25rem;
`;

const YoutubePrivacyLink = () => {
  const { t, i18n } = useTranslation('ytLive');
  const { language } = i18n;
  return (
    <YoutubeTncLinkWrapper>
      <TextButton.Primary
        // @ts-ignore
        as="a"
        href={`${YOUTUBE_PRIVACY_URL}?hl=${language}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Flex alignItems="center">
          <Text fontType="Body">{t('Youtube Privacy')}</Text>
          <Icons.Solid.Connect size="MEDIUM" color="PRIMARY_600" />
        </Flex>
      </TextButton.Primary>
    </YoutubeTncLinkWrapper>
  );
};

const YoutubeTermsAndConditions = () => {
  const [isTncOpen, setTncOpen] = useState(false);
  const { t } = useTranslation('ytLive');
  const { control } = useFormContext();

  return (
    <>
      <Flex alignItems="center">
        <Controller
          name={FormField.tnc}
          defaultValue={false}
          control={control}
          render={({ field }) => (
            <Checkbox.Default
              {...field}
              data-e2e-id={`${YT_LIVE_E2E_PREFIX}-youtube-tnc_checkbox`}
            />
          )}
        ></Controller>
        <TncTextWrapper
          color="INK_800"
          style={{ marginLeft: '.5rem' }}
          fontType="Body"
        >
          <Trans
            t={t}
            i18nKey="Youtube Tnc"
            components={{
              YoutubePrivacyLink: <YoutubePrivacyLink />,
            }}
          />
        </TncTextWrapper>
        <IconButton
          size={1.5}
          Icon={Icons.Outline.Chevron}
          iconProps={{
            size: 'MEDIUM',
            color: 'INK_600',
            position: isTncOpen ? 'TOP' : 'BOTTOM',
          }}
          handleClick={() => setTncOpen(!isTncOpen)}
          tooltipProps={{ direction: 'LEFT' }}
        ></IconButton>
      </Flex>
      {isTncOpen && (
        <Text color="INK_600" fontType="Body">
          {t('Youtube Tnc Detail')}
        </Text>
      )}
    </>
  );
};

export default YoutubeTermsAndConditions;
