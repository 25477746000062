import { cond } from 'lodash';
import { constant, negate, prop, stubTrue } from 'lodash/fp';

import { TableItem } from '../../../hooks/useProducts/transformToTableItem';

export enum ProductDisabledType {
  unpublished = 'unpublished',
  hiddenPrice = 'hiddenPrice',
  emptyPrice = 'emptyPrice',
}

export const getProductDisabledType = cond<
  TableItem,
  ProductDisabledType | null
>([
  [prop('isDraft'), constant(ProductDisabledType.unpublished)],
  [prop('hidePrice'), constant(ProductDisabledType.hiddenPrice)],
  [negate(prop('hasPrice')), constant(ProductDisabledType.emptyPrice)],
  [stubTrue, constant(null)],
]);

export default getProductDisabledType;
