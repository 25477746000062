import { Icons, LoadingSpinner } from '@shopline/dashboard-ui';
import { useQueryClient } from '@tanstack/react-query';
import eq from 'lodash/fp/eq';
import prop from 'lodash/fp/prop';
import { filter, flow, get } from 'lodash-es';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import Text from '@/components/Text';
import {
  useQuerySelectedProducts,
  useSelectedProductsQueryKey,
} from '@/hooks/useProducts/useSelectedProducts';

import { TableItem } from '../../../hooks/useProducts/transformToTableItem';
import { FormField } from '../formSchema';
import useCanEdit from '../hooks/useCanEdit';
import useExceedDate from '../hooks/useExceedDate';
import { E2E_PREFIX } from '.';
import getProductDisabledType from './getProductDisabledType';
import useColumns from './useColumns';

const LoadingWrapper = styled.div`
  text-align: center;
  padding: 2rem;
`;

const TableWrapper = styled.div`
  overflow: auto;
  max-height: 300px;
  margin-bottom: 1rem;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;
const Th = styled.th`
  height: 46px;
  text-align: left;
  background-color: ${prop('theme.colors.SNOW_DEFAULT')};
  border-bottom: 1px solid ${prop('theme.colors.INK_200')};
`;
const Tbody = styled.tbody`
  color: ${prop('theme.colors.INK_800')};
  font-size: 0.875rem;
`;
const Tr = styled.tr`
  height: 64px;
  &:not(:last-child) td {
    border-bottom: 1px solid ${prop('theme.colors.INK_200')};
  }
`;
const DeleteButton = styled.div`
  cursor: pointer;
`;

interface Props {
  ids: string[];
}
const LiveProductsTable = ({ ids }: Props) => {
  const columns = useColumns();
  const {
    setValue,
    watch,
    formState: { isSubmitted },
  } = useFormContext();
  const canEdit = useCanEdit();
  const [exceeded] = useExceedDate();

  const { data: selectedItems, isLoading } = useQuerySelectedProducts({
    ids,
  });
  const filteredItems = useMemo<TableItem[]>(
    () => filter(selectedItems, flow(getProductDisabledType, eq(null))),
    [selectedItems],
  );

  const selectedProductsQueryKey = useSelectedProductsQueryKey();
  const queryClient = useQueryClient();
  const handleDelete = (targetItem: TableItem) => () => {
    console.log(targetItem);
    const currentIds: string[] = watch(FormField.slProductIds);
    setValue(
      FormField.slProductIds,
      currentIds.filter((id) => id !== targetItem.uniqueId),
      { shouldValidate: isSubmitted },
    );
    queryClient.setQueryData<TableItem[]>(
      selectedProductsQueryKey,
      (old) => old?.filter((item) => item.id !== targetItem.id) ?? old,
    );
  };

  if (isLoading) {
    return (
      <LoadingWrapper>
        <LoadingSpinner size={20} />
      </LoadingWrapper>
    );
  }

  return (
    <TableWrapper>
      <Table data-e2e-id={`${E2E_PREFIX}-products-table`}>
        <Thead>
          <tr>
            {columns.map((col) => (
              <Th key={col.key}>
                <Text fontType="Body" weight="Medium" color="INK_600">
                  {col.title}
                </Text>
              </Th>
            ))}
            {canEdit && !exceeded && <Th></Th>}
          </tr>
        </Thead>
        <Tbody>
          {filteredItems?.map((item) => (
            <Tr key={item.uniqueId}>
              {columns.map((col) => (
                <td key={col.key}>
                  {col.renderCell
                    ? col.renderCell(item, col.key, {})
                    : get(item, col.key, '')}
                </td>
              ))}
              {canEdit && !exceeded && (
                <td>
                  <DeleteButton onClick={handleDelete(item)}>
                    <Icons.Solid.Trash size="MEDIUM" color="INK_400" />
                  </DeleteButton>
                </td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

export default LiveProductsTable;
