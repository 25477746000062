import { every, get } from 'lodash-es';

import { PermissionAction, PermissionScope } from './initialState.types';
import useQueryInitialState from './useQueryInitialState';

export type PermissionItem = [PermissionScope, PermissionAction];
export default function usePermissions(items: PermissionItem[] = []) {
  const { isSuccess, data } = useQueryInitialState();
  if (!isSuccess) {
    return false;
  }
  if (typeof data === 'undefined') {
    return false;
  }

  return every(items, (pair) => get(data.permissions, pair, false));
}
