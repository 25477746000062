const externalYtConnect = {
  'External YouTube Connect Title': 'YouTube Shopping Integration',
  'External YouTube Connect Description':
    "By logging in to your Google account, you can connect <ShopLink></ShopLink> to your YouTube Shopping channel and access the store's products.",
  'External YouTube Connect Google Login Title': 'Log in to Google account',
  'External YouTube Connect Google Login Description':
    'Please ensure you log in with the Google account that has owner access to the YouTube Channel.',
  'External YouTube Connect Login': 'Log in',
  'External YouTube Connect Logout': 'Log out',
  'External YouTube Connect Logout Popup Title': 'Confirm Logout',
  'External YouTube Connect Logout Popup Content': 'Are you sure to log out?',
  'External YouTube Connect Logout Success': 'You are logged out.',
  'External YouTube Connect Connected Channels Title':
    'Connect channel with the store',
  'External YouTube Connect Connected Channels Description':
    'Click "Connect" to connect the channel with the store. Once connected, the store\'s products will be available on the YouTube dashboard.',
  'External YouTube Connect Connected Channels Description Faq Tooltip':
    'Each YouTube channel can connect to multiple stores. Connecting to a new store will not affect any previously established connections.',
  'External YouTube Connect No Connected Channels Connect Button': 'Connect',
  'External YouTube Connect Disconnect Channel': 'Disconnect',
  'External YouTube Connect Connected Channels GMC Account Changed':
    'The Google Merchant Center account has been changed. Please reauthorize the channel.',
  'External YouTube Connect No Eligible Channels':
    'The Google account does not own any channel that is eligible for YouTube Shopping.',
  'External YouTube Connect With Connected Channels':
    'You have connected the following channels to the store',
  'External YouTube Connect Disconnect Channel Popup Title':
    'Are you sure you want to disconnect the YouTube Shopping integration between this channel and {{shopName}}?',
  'External YouTube Connect Disconnect Channel Popup Content':
    'Once disconnected, the channel will no longer have access to the store products.',
  'External YouTube Connect Disconnect Channel Popup Confirm': 'Disconnect',
  'External YouTube Connect Disconnect Channel Popup Cancel': 'Cancel',
  'External YouTube Connect Disconnect Channel Connect Success Toast':
    'Connect successfully',
  'External YouTube Connect Disconnect Channel Disconnect Success Toast':
    'Disconnect successfully',
  'External YouTube Connect Disconnect Channel Error Information Missing':
    'Some information is missing',
  'External YouTube Connect Disconnect Channel Error No Permission':
    "You don't have enough permission",
  'External YouTube Connect Disconnect Channel Error Not Eligible':
    'Channel not eligible',
  'External YouTube Connect Disconnect Channel Error Something Wrong':
    'Something is wrong with the channel',
  'External YouTube Connect Disconnect Channel Error Already Linked':
    'Channel is linked',
  'External YouTube Connect Connect Channel Error Merchant Not Integrated':
    'The connection between shop and Google Merchant Center account has been expired.',
  'External YouTube Connect Add Channel': 'Add channel',
  'External YouTube Connect Channel Name': 'Channel name',
  'External YouTube Connect Channel ID': 'Channel ID',
  'External YouTube Connect Add Channel Success': 'Added successfully',
  'External YouTube Connect Information Missing': 'Some information is missing',
  'External YouTube Connect Add No Permission':
    "You don't have enough permission",
  'External YouTube Connect Channel Not Eligible': 'Channel not eligible',
  'External YouTube Connect Add Channel Error':
    'Something is wrong with the channel',
  'External YouTube Connect Footer':
    "© 2013 - 2024 SHOPLINE. Asia's E-Commerce Solution.",
};

export default externalYtConnect;
