import { Text as SLText } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

type SLTextProps = React.ComponentProps<typeof SLText>;
type Props = Omit<SLTextProps, 'lang'> & { as?: string };

const Text = (props: Props) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  return <SLText lang={language as SLTextProps['lang']} {...props} />;
};

export default Text;
