import { Button, TextButton } from '@shopline/dashboard-ui';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import InvisibleInput from '@/components/InvisibleInput';
import Spacer from '@/components/Spacer';
import Text from '@/components/Text';
import useAdminHost from '@/hooks/useAdminHost';
import { useQueryMerchant } from '@/hooks/useMerchant';

import { FormField } from '../formSchema';
import { E2E_PREFIX } from '.';
import HiddenProductModal from './HiddenProductModal';
import LinkageCompleted from './LinkageCompleted';
import Step from './Step';

const ValueBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.SNOW_500};
  border-radius: 6px;
  padding: 16px;
`;

const ActionButton = ({
  isInstalled,
  e2eId,
}: {
  isInstalled: boolean;
  e2eId: string;
}) => {
  const { t } = useTranslation('ytLive');
  const adminHost = useAdminHost();

  return isInstalled ? (
    <Button.Default
      // @ts-ignore
      as="a"
      href={`${adminHost}/external/google-merchant-center`}
      target="_blank"
      rel="noreferrer noopener"
      e2eId={e2eId}
    >
      {t('Manage Settings')}
    </Button.Default>
  ) : (
    <Button.Default
      // @ts-ignore
      as="a"
      href={`${adminHost}/apps/apps_store/Google-Merchant-Center-ContentAPI`}
      target="_blank"
      rel="noreferrer noopener"
      e2eId={e2eId}
    >
      {t('Go to Install')}
    </Button.Default>
  );
};

const StepContentApi = ({ stepNum }: { stepNum: number }) => {
  const { t } = useTranslation('ytLive');
  const { getFieldState, watch, control } = useFormContext();
  const { data: merchant } = useQueryMerchant({ refetchOnWindowFocus: true });
  const isInstalled = !!merchant?.enabled;
  const isLinkageCompleted = watch(FormField.isGmcReady);
  const hasError = getFieldState(FormField.isGmcReady).invalid;

  const [isHiddenProductModalOpen, setIsHiddenProductModalOpen] =
    useState(false);

  return (
    <Step
      stepNum={stepNum}
      title={t('Setup Guide StepContentApi')}
      actionButton={
        <ActionButton
          isInstalled={isInstalled}
          e2eId={`${E2E_PREFIX}-step-content-api_action-button`}
        />
      }
      hasError={hasError}
    >
      <Text fontType="Body" color="INK_600">
        <Trans
          t={t}
          i18nKey="Setup Guide StepContentApi - description"
          components={{
            LinkButton: (
              <TextButton.Primary
                onClick={() => setIsHiddenProductModalOpen(true)}
                type="button"
              />
            ),
          }}
        />
        {isHiddenProductModalOpen && (
          <HiddenProductModal
            onClose={() => setIsHiddenProductModalOpen(false)}
          />
        )}
      </Text>
      {isLinkageCompleted && (
        <>
          <LinkageCompleted />
          <Spacer y="1rem" />
          <Text fontType="Body" color="INK_800">
            {t('Setup Guide StepContentApi - connected account')}
          </Text>
          <Spacer y="0.25rem" />
          <ValueBlock>
            <Text fontType="Body" color="INK_500">
              {merchant?.googleMerchantId}
            </Text>
          </ValueBlock>
        </>
      )}
      <Controller
        name={FormField.isGmcReady}
        defaultValue={null}
        control={control}
        render={({ field }) => (
          <InvisibleInput
            {...field}
            type="checkbox"
            data-e2e-id={`${E2E_PREFIX}-step-three-isGmcReady_checkbox`}
          ></InvisibleInput>
        )}
      ></Controller>
    </Step>
  );
};

export default StepContentApi;
