import { DateTimePicker as SLDateTimePicker } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

type SLDateTimePickerProps = React.ComponentProps<typeof SLDateTimePicker>;

const DateTimePicker = (props: Omit<SLDateTimePickerProps, 'lang'>) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  return (
    <SLDateTimePicker
      lang={language as SLDateTimePickerProps['lang']}
      {...props}
    />
  );
};

export default DateTimePicker;
