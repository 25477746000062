import { LoadingSpinner } from '@shopline/dashboard-ui';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingPage = () => (
  <LoadingWrapper>
    <LoadingSpinner size={36} />
  </LoadingWrapper>
);

export default LoadingPage;
