import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useQueryLiveRoomSetting } from '@/hooks/useLiveRoomSetting/useLiveRoomSetting';

type TouchFn = () => void;

const Context = createContext<[boolean, TouchFn]>([false, () => {}]);

export const ExceedDateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [exceeded, setExceeded] = useState(false);
  const { data } = useQueryLiveRoomSetting();

  const touch = useCallback(() => {
    if (data?.date) {
      setExceeded(new Date() >= new Date(data.date));
    }
  }, [data?.date]);

  useEffect(() => {
    touch();
  });

  return (
    <Context.Provider value={[exceeded, touch]}>{children}</Context.Provider>
  );
};

const useExceedDate = () => useContext(Context);

export default useExceedDate;
