import { useMemo } from 'react';

import useQueryInitialState from '@/hooks/useQueryInitialState';

const useHasFeature = (featureKey: string) => {
  const { data } = useQueryInitialState();

  const rolloutKeysSet = useMemo(
    () => new Set([...(data?.merchant.rolloutKeys ?? [])]),
    [data?.merchant.rolloutKeys],
  );
  return rolloutKeysSet.has(featureKey);
};

export default useHasFeature;
