import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const supportedLanguages = ['zh-hant', 'zh-cn', 'en'];

const useLanguageFromQuery = () => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    const langParam = searchParams.get('lang') || 'en';
    const lang = supportedLanguages.includes(langParam) ? langParam : 'en';
    i18n.changeLanguage(lang);
  }, [searchParams, i18n]);
};

export default useLanguageFromQuery;
