import dayjs from 'dayjs';
import Joi from 'joi';

import { FieldError } from '@/constants/fieldError';

import { PRODUCTS_MAX_COUNT, PRODUCTS_MIN_COUNT } from './constants';

export enum FormField {
  isGa4Ready = 'isGa4Ready',
  isGmcReady = 'isGmcReady',
  youtubeId = 'youtubeId',
  brandHomeUrl = 'brandHomeUrl',
  youtubeUrl = 'youtubeUrl',
  date = 'date',
  slProductIds = 'slProductIds',
  tnc = 'tnc',
}

const formSchema = Joi.object({
  [FormField.isGa4Ready]: Joi.boolean().invalid(false),
  [FormField.isGmcReady]: Joi.boolean().invalid(false),
  [FormField.youtubeId]: Joi.string().required().messages({
    'string.empty': FieldError.required,
    'any.required': FieldError.required,
  }),
  [FormField.youtubeUrl]: Joi.string()
    .required()
    .pattern(/^(https?:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/)
    .messages({
      'string.empty': FieldError.required,
      'any.required': FieldError.required,
      'string.pattern.base': FieldError.pattern,
    }),
  [FormField.brandHomeUrl]: Joi.string().required().messages({
    'string.empty': FieldError.required,
    'any.required': FieldError.required,
  }),
  [FormField.date]: Joi.date()
    .required()
    .custom((value, helper) => {
      if (dayjs(value).isBefore(dayjs().add(1, 'day'))) {
        return helper.error('date.min');
      }

      return value;
    })
    .messages({
      'date.base': FieldError.required,
      'any.required': FieldError.required,
      'date.min': FieldError.minDate,
    }),
  [FormField.slProductIds]: Joi.array()
    .required()
    .min(PRODUCTS_MIN_COUNT)
    .max(PRODUCTS_MAX_COUNT)
    .messages({
      'array.min': 'Live Products - min error',
      'array.max': 'Live Products - max error',
    }),
  [FormField.tnc]: Joi.boolean().invalid(false),
});

export interface FormSchema {
  [FormField.youtubeId]: string;
  [FormField.youtubeUrl]: string;
  [FormField.brandHomeUrl]: string;
  [FormField.date]: Date | null;
  [FormField.slProductIds]: string[];
  [FormField.tnc]: boolean;
}

export default formSchema;
