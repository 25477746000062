const translations = {
  'YouTube Shopping Account Linking Title': 'YouTube Shopping 商店串接',
  'Store Setup Title': '商店前置設定',
  'Store Setup Description':
    '請確保您已可以在 Google Merchant Center 帳號管理此商店的商品廣告資訊，再到步驟 2 複製串接連結給您的合作 YouTube 頻道管理員。\n',
  'Store Setup GMC Title': '串接 Google Merchant Center',
  'Store Setup GMC Description':
    '完成後，此商店的商品資料將自動同步至串接的 Google Merchant Center 帳戶。請注意新增 YouTube Shopping 頻道後，請勿異動商店串接的 GMC 帳號，若異動，需要重新授權頻道，該頻道才能取得新的商品資訊。 <KnowMoreLink></KnowMoreLink>',
  'Learn More': '了解更多',
  'Setup Now': '前往啟用',
  'Store Setup GMC Account Connected': '已串接以下 Google Merchant Center 帳號',
  'Store Setup GMC Finished': '已完成串接',
  'Store Setup GMC ID Changed':
    'Google Merchant Center 帳號異動，部分頻道需要重新授權',
  'Store Setup Content API Title': '啟用 Content API',
  'Store Setup Content API Description':
    '商店的商品異動將透過 Content API 即時更新至 Google Merchant Center，隱藏商品資訊也會自動同步。 <KnowMoreLink></KnowMoreLink>',
  'Store Setup Content API Status': 'Google Content API 串接狀態',
  'Store Setup Content API Finished': '已完成串接',
  'Connected Channels Title': 'YouTube Shopping 串接頻道',
  'Connected Channels Description':
    '請複製串接連結給合作的 YouTube 頻道管理員，頻道管理員完成授權後，即可於相關 YouTube 頻道後台選取商品應用於直播或影片中。',
  'Connected Channels Copy Link': '複製串接連結',
  'Connected Channels Copy Success': '複製完成',
  'Connected Channels No Channels Connected':
    '無任何頻道與此商店完成 YouTube Shopping 串接',
  'Connected Channels Channels Connected':
    '以下頻道已與商店完成 YouTube Shopping 串接',
  'Connected Channels Not Authorized': '非系統授權',
  'Connected Channels Disconnect Tooltip':
    '若需要解除此頻道串接，請聯繫您的客戶成功顧問',
  'Connected Channels Disconnect Channel': '解除串接',
  'Connected Channels Disconnect Popup Title':
    '確認解除此頻道與 {{shopName}} 的 YouTube Shopping 串接？',
  'Connected Channels Disconnect Popup Description':
    '解除串接後，在 YouTube 頻道將無法顯示任何商店商品',
  'Connected Channels Confirm Disconnect': '確定解除',
  'Connected Channels Cancel Disconnect': '取消',
  'Connected Channels Disconnect Channel Success': '成功解除',
  'Connected Channels Reconnect as GMC ID Changed':
    'Google Merchant Center 帳號異動，請重新串接頻道',
};

export default translations;
