import {
  QueryFunctionContext,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { InitialStateQueryKey } from '@/types/external-yt-channel-connect/external-yt-channel-connect.types';

import { queryKeys } from './queryKey';

interface InitialState {
  merchant: {
    name: string;
    brandHomeUrl: string;
    logoUrl: string;
  };
  user: {
    name: string;
    email: string;
  } | null;
}

type QueryKey = [
  'external-yt-channnel-connect-page-initial-state',
  string | undefined,
];

export const getInitialState = async (
  merchantId?: string,
): Promise<InitialState> => {
  const { data } = await axios.get<InitialState>(
    `/external-api/merchants/${merchantId}/initial-state`,
  );
  return data;
};

const passMerchantId =
  (queryFn: typeof getInitialState) =>
  ({ queryKey: [_, merchantId] }: QueryFunctionContext<InitialStateQueryKey>) =>
    queryFn(merchantId);

export const useQueryInitialState = (
  options?: Omit<
    UseQueryOptions<
      InitialState,
      AxiosError,
      InitialState,
      InitialStateQueryKey
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery<InitialState, AxiosError, InitialState, QueryKey>({
    queryKey: queryKeys.initialState(merchantId),
    queryFn: passMerchantId(getInitialState),
    enabled: !!merchantId,
    ...options,
  });
};
