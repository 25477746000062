import { useWindowScroll } from '@uidotdev/usehooks';
import { isNil } from 'lodash';

import useBoundingClientRect from './useBoundinClientRect';

const useSticky = (
  ref: React.MutableRefObject<HTMLElement | undefined | null>,
  top = 0,
): boolean => {
  const { y: elementY } = useBoundingClientRect(ref);
  const [{ y: scrollY }] = useWindowScroll();

  if (isNil(elementY) || isNil(scrollY)) {
    return false;
  }

  return elementY - top <= scrollY;
};

export default useSticky;
