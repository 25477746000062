import { Grid, Icons, TextButton } from '@shopline/dashboard-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spacer from '@/components/Spacer';
import Text from '@/components/Text';
import useHasFeature from '@/hooks/useHasFeature';

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const StepWrapper = styled.div`
  display: flex;
  gap: 4px;
`;
const FullWidthImg = styled.img`
  width: 100%;
`;

const YoutubeAdminPreparation = () => {
  const { t } = useTranslation('ytLive');
  const youtubeAccountingLinkingEnabled = useHasFeature(
    'youtube_accounting_linking',
  );
  const steps = useMemo(
    () => [
      {
        icon: <Icons.Solid.StepOne size="MEDIUM" />,
        title: youtubeAccountingLinkingEnabled
          ? t('Account Linking YouTube Preparation StepOne')
          : t('Youtube Admin Preparation StepOne'),
        description: youtubeAccountingLinkingEnabled
          ? t('Account Linking YouTube Preparation StepOne - description')
          : t('Youtube Admin Preparation StepOne - description'),
        supportLink: 'https://support.google.com/youtube/answer/12258288',
      },
      {
        icon: <Icons.Solid.StepTwo size="MEDIUM" />,
        title: youtubeAccountingLinkingEnabled
          ? t('Account Linking YouTube Preparation StepTwo')
          : t('Youtube Admin Preparation StepTwo'),
        description: youtubeAccountingLinkingEnabled
          ? t('Account Linking YouTube Preparation StepTwo - description')
          : t('Youtube Admin Preparation StepTwo - description'),
        supportLink: 'https://support.google.com/youtube/answer/12299016',
      },
      {
        icon: <Icons.Solid.StepThree size="MEDIUM" />,
        title: youtubeAccountingLinkingEnabled
          ? t('Account Linking YouTube Preparation StepThree')
          : t('Youtube Admin Preparation StepThree'),
        description: youtubeAccountingLinkingEnabled
          ? t('Account Linking YouTube Preparation StepThree - description')
          : t('Youtube Admin Preparation StepThree - description'),
        supportLink: 'https://support.google.com/youtube/answer/12299016',
      },
    ],
    [t, youtubeAccountingLinkingEnabled],
  );

  return (
    // @ts-ignore react 18 removed `children` as a default prop
    <Grid.Row>
      <Grid.Column lg={6} md={6} sd={12}>
        <FullWidthImg
          src={require('./youtube-admin.jpg')}
          alt="youtube admin"
        />
      </Grid.Column>
      <Grid.Column lg={6} md={6} sd={12}>
        <StepsContainer>
          {steps.map((step) => (
            <StepWrapper key={step.title}>
              <div>{step.icon}</div>
              <div>
                <Text fontType="Body" color="INK_800">
                  {step.title}
                </Text>
                <Spacer y="4px" />
                <Text fontType="Body" color="INK_600">
                  {step.description}
                </Text>
                <Spacer y="4px" />
                <TextButton.Primary
                  // @ts-ignore
                  as="a"
                  href={step.supportLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t('Learn more')}
                </TextButton.Primary>
              </div>
            </StepWrapper>
          ))}
        </StepsContainer>
      </Grid.Column>
    </Grid.Row>
  );
};

export default YoutubeAdminPreparation;
