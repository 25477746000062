import { TextButton } from '@shopline/dashboard-ui';
import { Trans, useTranslation } from 'react-i18next';

import Spacer from '@/components/Spacer';
import Text from '@/components/Text';

import Step from './Step';

const StepGmc = ({ stepNum }: { stepNum: number }) => {
  const { t } = useTranslation('ytLive');

  return (
    <Step stepNum={stepNum} title={t('Setup Guide StepGmc')}>
      <Text fontType="Body" color="INK_600">
        <Trans
          t={t}
          i18nKey="Setup Guide StepGmc - description"
          components={{
            LinkButton: (
              <TextButton.Primary
                // @ts-ignore
                as="a"
                href="https://support.google.com/merchants/answer/12046446"
                target="_blank"
                rel="noreferrer noopener"
              />
            ),
          }}
        />
      </Text>
      <Spacer y="1rem" />
      <Text fontType="Caption" color="INK_500">
        {t('Setup Guide StepGmc - caption')}
      </Text>
    </Step>
  );
};

export default StepGmc;
