enum ConnectToChannelErrorCode {
  CHANNEL_NOT_FOUND = 'channel-not-found',
  CHANNEL_NOT_ELIGIBLE = 'channel-not-eligible',
  NO_PERMISSION_ON_CHANNEL = 'no-permission-on-channel',
  ALREADY_LINKED = 'already-linked',
  MERCHANT_NOT_INTEGRATED = 'merchant-not-integrated',
}

export type ErrorResponse = {
  code: ConnectToChannelErrorCode;
  message: string;
};

export const errorCodeToTranslationKeyMap = {
  [ConnectToChannelErrorCode.CHANNEL_NOT_FOUND]:
    'External YouTube Connect Disconnect Channel Error Something Wrong',
  [ConnectToChannelErrorCode.CHANNEL_NOT_ELIGIBLE]:
    'External YouTube Connect Disconnect Channel Error Not Eligible',
  [ConnectToChannelErrorCode.NO_PERMISSION_ON_CHANNEL]:
    'External YouTube Connect Disconnect Channel Error No Permission',
  [ConnectToChannelErrorCode.ALREADY_LINKED]:
    'External YouTube Connect Disconnect Channel Error Already Linked',
  [ConnectToChannelErrorCode.MERCHANT_NOT_INTEGRATED]:
    'External YouTube Connect Connect Channel Error Merchant Not Integrated',
} as const;
