import { Button, Popup } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { YT_ACCOUNT_LINKING_E2E_PREFIX } from '@/constants/e2eId';
import useQueryInitialState from '@/hooks/useQueryInitialState';
import { useDisconnectChannelFromMerchant } from '@/hooks/youtubeAccountLinking/useDisconnectChannel';

interface DisconnectChannelPopupProps {
  youtubeConnectId?: string;
  onClose: () => void;
}

interface DisconnectChannelPopupFooterProps {
  youtubeConnectId?: string;
  onClose: () => void;
}

export const disconnectChannelPopupE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-disconnect-channel-popup_modal`;
export const disconnectChannelPopupConfirmButtonE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-disconnect-channel-popup-confirm_button`;
export const disconnectChannelPopupCancelButtonE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-disconnect-channel-popup-cancel_button`;
export const disconnectChannelPopupBodyE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-disconnect-channel-popup-body_content`;

const PopupFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PopupFooter = ({
  youtubeConnectId,
  onClose,
}: DisconnectChannelPopupFooterProps) => {
  const { t } = useTranslation('ytAccountLinking');
  const { mutateAsync: disconnectChannelFromMerchantMutation, isLoading } =
    useDisconnectChannelFromMerchant(youtubeConnectId);

  const handleClickConfirm = async () => {
    await disconnectChannelFromMerchantMutation();
    onClose();
  };

  return (
    <PopupFooterContainer>
      <Button.Default
        onClick={onClose}
        e2eId={disconnectChannelPopupCancelButtonE2eId}
        height="LARGE"
      >
        {t('Connected Channels Cancel Disconnect')}
      </Button.Default>
      <MarginContainer ml="1rem">
        <Button.Destructive
          onClick={handleClickConfirm}
          e2eId={disconnectChannelPopupConfirmButtonE2eId}
          width="WIDE"
          height="LARGE"
          isLoading={isLoading}
        >
          {t('Connected Channels Confirm Disconnect')}
        </Button.Destructive>
      </MarginContainer>
    </PopupFooterContainer>
  );
};

const DisconnectChannelPopup = ({
  youtubeConnectId,
  onClose,
}: DisconnectChannelPopupProps) => {
  const { t } = useTranslation('ytAccountLinking');
  const { data } = useQueryInitialState();
  const title = t('Connected Channels Disconnect Popup Title', {
    shopName: data?.merchant.name,
  });
  return (
    // @ts-ignore react 18 removed `children` as a default prop
    <Popup
      e2eId={disconnectChannelPopupE2eId}
      onClose={onClose}
      title={title}
      renderFooter={() => (
        <PopupFooter youtubeConnectId={youtubeConnectId} onClose={onClose} />
      )}
    >
      <Text
        e2eId={disconnectChannelPopupBodyE2eId}
        color="INK_800"
        fontType="Subheading"
      >
        {t('Connected Channels Disconnect Popup Description')}
      </Text>
    </Popup>
  );
};

export default DisconnectChannelPopup;
