import styled from 'styled-components';

import Divider from '@/components/Divider';

import { YT_LIVE_E2E_PREFIX } from '../constants';
import StepContentApi from './StepContentApi';
import StepGmc from './StepGmc';
import StepInfoForm from './StepInfoForm';
import StepTrackerSetting from './StepTrackerSetting';

export const E2E_PREFIX = `${YT_LIVE_E2E_PREFIX}-setup-guide`;

const Container = styled.div`
  margin-top: 1rem;
`;

const SetupGuide = () => {
  return (
    <Container data-e2e-id={E2E_PREFIX}>
      <StepTrackerSetting stepNum={1} />
      <Divider />
      <StepContentApi stepNum={2} />
      <Divider />
      <StepGmc stepNum={3} />
      <Divider />
      <StepInfoForm stepNum={4} />
    </Container>
  );
};

export default SetupGuide;
