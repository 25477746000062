import { ProductDisabledType } from '@/features/YTLive/LiveProducts/getProductDisabledType';

const ytLive = {
  'Apply Now': '立即申请',
  'Go to Set': '前往设定',
  'Manage Settings': '管理设定',
  'Go to Install': '前往安装',
  'Learn more': '了解更多',
  'Linkage Completed': '已完成连接',

  'Setup Guide': '开播指南',
  'Setup Guide - description':
    '开始 YouTube 直播前，您需要完成以下设置。注意：您需要通过 Google Merchant Center 的审核并拥有 YPP 资格才可以开启 YouTube 直播',
  'Setup Guide - error': '开播指南是必填项目，请确保以下步骤都完成设定',
  'Setup Guide - nav content': '按步骤完成设定与串接',
  'Setup Guide StepTrackerSetting': '前往追踪设定编辑 GA4',
  'Setup Guide StepTrackerSetting - description':
    'GA4 能让您追踪网站所有顾客浏览、点击足迹，及纪录购买行为、事件和价值，并能跨装置追踪 YouTube Live Shopping 直播的导购收益 <LinkButton>了解更多</LinkButton>',
  'Setup Guide StepTrackerSetting - support link':
    'https://support.shoplineapp.com/hc/zh-cn/articles/8646049467801',
  'Setup Guide StepContentApi':
    '前往扩充功能安装 Google Merchant Center Content API 及设定隐藏商品',
  'Setup Guide StepContentApi - description':
    '透过 Content API 串接自动上载你的商品资料到 Google Merchant Center，可以在 Google 广告、YouTube Live Shopping 直播购物功能及其他 Google 服务中使用 <LinkButton>了解隐藏商品应用</LinkButton>',
  'Setup Guide StepContentApi - connected account':
    '已串接到以下 Google Merchant Center 帐号',
  'Setup Guide StepGmc': '请至 Google Merchant Center 后台设定 GMC、GA4 串接',
  'Setup Guide StepGmc - description':
    '请您至 GMC 后台连结您的 GA4 帐号，方能于 YouTube 频道后台看到您的商品 <LinkButton>了解更多</LinkButton>',
  'Setup Guide StepGmc - caption':
    '注意：Google Merchant Center 和 YouTube 频道需要是同一组 Email，若不是的话需要您：前往 Google Merchant Center > 工具与设定 > 帐户存取权 > 使用者> 新增使用者 > 加入 YouTube 频道申请时的该组 Email',
  'Setup Guide StepInfoForm': '填写资讯以连接 YouTube',
  'Field youtubeId': 'YouTube 频道 ID',
  'Field youtubeUrl': 'YouTube 频道 URL',
  'Field brandHomeUrl': '商店 URL',
  'Field date': '预计直播时间',
  'Field date - description':
    '请注意！在您按下前往开播按钮时，您的商品才会实际展示于Google Merchant Center / YouTube 后台，以上两大步骤设定完成后，建议您于正式直播的三天前，在预计直播的YouTube 频道先择期试播一次，以确保顺利开播。\n*提醒您，因 YouTube / Google Merchant Center 平台上传商品限制，仅能选择当下 24 小时后的时间作为您的预计直播时间。',

  'HiddenProductModal - title': '隐藏商品露出于直播间时间',
  'HiddenProductModal - page 1 title': '第一步：前往 SHOPLINE 后台',
  'HiddenProductModal - page 1 description':
    '若您想在直播当下才将商品露出，请至「商品 > 商品设定 > 隐藏商品」将该建立好的商品按钮开启，商品将会先被暂时隐藏。 ',
  'HiddenProductModal - page 2 title': '第二步：设定好 Content API',
  'HiddenProductModal - page 2 description':
    '前往扩充功能商店「Google Content API - Google Merchant Center 产品资料来源」 设定好 Content API，我们会将您选择到的隐藏商品即时上传到 Google Merchant Center 后台，您可以随时调整您的商品规格及资讯',
  'HiddenProductModal - page 3 title': '第三步：设定好预计直播时间',
  'HiddenProductModal - page 3 description':
    '预计直播时间选择好后，隐藏商品将会随着您选择到的预计直播时间露出于商店前台及 YouTube 前后台',
  'HiddenProductModal - page 4 title': '第四步：再次前往 SHOPLINE 后台',
  'HiddenProductModal - page 4 description':
    '请于直播当下或直播前，前往「商品 > 商品设定 > 隐藏商品」将该建立好的隐藏商品按钮关闭，商品将会即时露出于前台。 *请注意：若您想于直播前提前曝光商品，可提早将按钮关闭。 \n另外提醒您：因 Google Merchant Center 平台限制，24 小时才更新一次商品状态，故建议您于直播前 24 小时不再去更动所有商品规格、及预计直播的时间，避免直播途中发生商品未及时在 Google Merchant Center 及 YouTube Channel 后台露出的问题。 ',

  'Live Products': '直播商品',
  'Live Products - counter': '({{count}}/{{max}})',
  'Live Products - description':
    '请提供您预计直播的时间。选择好后，隐藏商品将会随着您选择到的预计直播时间露出于商店前台及 YouTube 前后台。另外提醒您：因 Google Merchant Center 及 YouTube 平台限制，在您选择到「预计直播时间」后，我们在您直播前 24 小时将不再开放编辑时间。',
  'Live Products - min error': '直播商品是必填项目，请选择直播商品',
  'Live Products - max error':
    '一个直播间仅限添加 {{count}} 个商品，请减少直播商品数量',
  'Live Products - nav content': '选择或建立直播的商品',
  'Add Products': '选择直播商品',
  'Live Products columns - Product': '商品',
  'Live Products columns - Price': '价格',
  'Live Products columns - Inventory': '可售数量',
  'Live Products columns - Status': '状态',
  'Live Products status - reminder on': '缺货提醒：开启',
  'Live Products status - reminder off': '缺货提醒：关闭',
  'Live Products status - hidden': '隐藏商品',
  'Live Products status - schedulePublishAt': '于 {{date}} 上架',
  'Live Products status - availableStartTime start': '开始销售：{{date}}',
  'Live Products status - availableStartTime period': '销售时间：{{date}}',
  'Live Products status - availableStartTime end': '销售结束：{{date}}',
  [`Live Prdoucts disabled_${ProductDisabledType.unpublished}`]: '已下架',
  [`Live Prdoucts disabled_${ProductDisabledType.emptyPrice}`]:
    '无法添加无价格商品',
  [`Live Prdoucts disabled_${ProductDisabledType.hiddenPrice}`]:
    '无法添加隐藏价格商品',
  'LiveProductsPicker - No result': '找不到商品',
  'LiveProductsPicker - SearchBar placeholder': '搜寻商品名称或 SPU',
  'LiveProductsPicker - No result description':
    '请尝试更改关键字或筛选套用词/编号',
  'LiveProductsPicker - View selected products':
    '检视已选商品 ({{count}}/{{max}})',
  'LiveProductsPicker - View all products': ' 检视全部商品',

  'Youtube Admin Preparation': 'YouTube 后台准备',
  'Youtube Admin Preparation - description':
    '由于 YouTube 平台限制，暂不支援于 SHOPLINE 后台直接开播，更多直播功能敬请期待。如需终止直播请至 YouTube 后台操作。开始直播售卖商品前，请参考以下步骤：',
  'Youtube Admin Preparation - nav content': '需要在平台设定做好准备',
  'Youtube Admin Preparation StepOne': '于 YouTube 工作室设定频道展示商品',
  'Youtube Admin Preparation StepOne - description':
    '当您设定好 Google Merchant Center Content API 后，商品将自动上传至您的 Google Merchant Center 及 YouTube Live Shopping 后台，您可以至 YouTube 后台管理设定中心将想要在直播当下展示的商品勾选起来。',
  'Youtube Admin Preparation StepTwo': '标记直播中要展示的商品',
  'Youtube Admin Preparation StepTwo - description':
    '并可于直播当下标记钉选商品至频道中，让您的买家能够边看边买',
  'Youtube Admin Preparation StepThree': '在预定时间开播并置顶商品',
  'Youtube Admin Preparation StepThree - description':
    '直播期间，可搭配置顶功能让介绍的商品更显眼。',

  'Finish Setting': '完成',
  'Finish Setting - description': '完成以上设定步骤之后，就可以马上开播了！',
  'Start Streaming': '前往 YouTube 开播',
  'exceeded the LIVE Broadcast time':
    '此直播间已失效（超过直播时间），本次编辑时间将不储存；欲进行开播请新增一新的直播间',
  'Youtube Tnc':
    '在设置此功能时，您已充分了解相关规定，请参阅<YoutubePrivacyLink></YoutubePrivacyLink>及以下相关规定。',
  'Youtube Privacy': 'YouTube 隐私政策',
  'Youtube Tnc Detail':
    '请注意，当您进行 YouTube Shopping 的串接时，表示您同意网络商店中来源于 YouTube 的订单数据会传输给 YouTube。',
  'Setup Guide YouTube Shopping Setup': 'YouTube Shopping 直播间设定',
  'Field Channel': '频道',
  'Setup Guide Choose Channel Title': '选择 YouTube 频道',
  'Setup Guide Choose Channel Description':
    '注意：商店包含隐藏商品，在所有串接的 YouTube 频道后台将显示这些商品。',
  'Setup Guide Choose Channel Dropdown Placeholder': '请选择',
  'Setup Now': '前往启用',
  'Setup Guide No Available Channels': '商店没有已串接的 YouTube 频道',
  'Schedule Live Stream Title': '预计直播时间',
  'Schedule Live Stream Description':
    '请选择您预计直播的时间，隐藏商品将在预计直播时间显示于商店前台及 YouTube 前后台。 *提醒您，因 Google Merchant Center 及 YouTube 平台上传商品限制，仅能选择当下 24 小时后的时间作为您的预计直播时间，且在直播前 24 小时不支援编辑时间。',
  'Live Stream Products Title': '直播商品',
  'Live Stream Products Description':
    '请选择直播商品，在您按下储存按钮时，您的商品才会实际显示于 Google Merchant Center / YouTube 后台。',
  'YouTube Preparation Title': 'YouTube 后台准备',
  'YouTube Preparation Description':
    '由于 YouTube 平台限制，暂不支援于 SHOPLINE 后台直接开播，终止直播也请至 YouTube 后台操作，更多直播功能敬请期待。 开始直播贩售商品前，请参考以下步骤：',
  'Account Linking YouTube Preparation StepOne':
    '于 YouTube 工作室设定频道展示商品',
  'Account Linking YouTube Preparation StepOne - description':
    '当您已完成 Google Merchant Center Content API 设定，商品将自动上传至您的 Google Merchant Center 及 YouTube 后台，您可以至 YouTube 后台选取要在频道展示的商品。',
  'Account Linking YouTube Preparation StepTwo': '标记直播中要展示的商品',
  'Account Linking YouTube Preparation StepTwo - description':
    '您可以标记商品，使其展示在直播中，让观众能够边看边买。',
  'Account Linking YouTube Preparation StepThree': '在预定时间开播并置顶商品',
  'Account Linking YouTube Preparation StepThree - description':
    '直播期间，可搭配置顶功能让介绍的商品更显眼。',
  'Finish YouTube Setting': '完成',
  'Content Api Description':
    '于设定本功能时您已充分知悉相关规定，请参阅 YouTube 隐私权政策及以下相关规定。 请注意，当您串接 YouTube Shopping 时，代表您同意将网路商店中来自 YouTube 的订单数据传输给 YouTube。',
};

export default ytLive;
