import { Alert, media } from '@shopline/dashboard-ui';
import prop from 'lodash/fp/prop';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import Spacer from '../Spacer';
import Text from '../Text';

const Container = styled.section`
  padding: 1.5rem;
  background-color: ${prop('theme.colors.SNOW_DEFAULT')};
  box-shadow:
    0px 0px 1px ${prop('theme.colors.SHADOW_DEFAULT')},
    0px 1px 4px ${prop('theme.colors.SHADOW_LIGHT')};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${media.mobile`
    margin-top: 0.5rem;
  `}
  &:first-child {
    margin-top: 0;
  }
`;

const Header = styled.div`
  margin-bottom: 1rem;
`;

const PreLineText = styled(Text)`
  white-space: pre-line;
`;

const CaptionText = ({ children }: { children: React.ReactNode }) => (
  <PreLineText fontType="Caption" color="INK_500">
    {children}
  </PreLineText>
);

export const Caption = styled(CaptionText)`
  flex: 0 0 100%;
  margin-top: 0.25rem;
`;

interface Props {
  title: string;
  caption?: React.ReactNode;
  errorMessage?: string;
  children: React.ReactNode;
}

const Section = forwardRef<HTMLDivElement, Props>(
  ({ title, caption, children, errorMessage, ...props }, ref) => (
    <Container ref={ref} {...props}>
      {errorMessage && (
        <>
          <Alert.Destructive>{errorMessage}</Alert.Destructive>
          <Spacer y="16px" />
        </>
      )}
      <Header>
        <Text as="h3" fontType="DisplaySmall" color="INK_800">
          {title}
        </Text>
        {caption && (
          <div>
            <Caption>{caption}</Caption>
          </div>
        )}
      </Header>
      {children}
    </Container>
  ),
);

export default Section;
