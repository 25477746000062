import { media, Toast as UIToast } from '@shopline/dashboard-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useToastActionContext, useToastStateContext } from '@/contexts/Toast';
import { type Toast } from '@/contexts/Toast/types';

const ToastContainer = styled.div`
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 50;
  ${media.mobile`
    left: 0;
    right: 0;
    top: 1.5rem;
  `}
`;

const toastMap = {
  alert: UIToast.Alert,
  information: UIToast.Information,
  success: UIToast.Success,
  warning: UIToast.Warning,
};

const useDuration = ({
  duration,
  dismiss,
}: {
  duration?: number;
  dismiss: any;
}) => {
  useEffect(() => {
    if (duration) {
      setTimeout(dismiss, duration);
    }
  }, [duration, dismiss]);
};

const ToastItem = ({
  as: Component,
  id,
  isShow,
  duration,
  title,
  titleWithParams,
  description,
  descriptionWithParams,
  descriptionNodeWithProps,
}: Toast & {
  as: (typeof toastMap)[keyof typeof toastMap];
}) => {
  const { t } = useTranslation();
  const { dismissUIToast, deleteUIToast } = useToastActionContext();

  const memoizedTitle = useMemo(() => {
    if (titleWithParams) {
      const { key, params } = titleWithParams;
      return t(key as any, params);
    }
    if (title) {
      return t(title);
    }
    return '';
  }, [t, titleWithParams, title]);

  const memoizedDescription = useMemo(() => {
    if (descriptionNodeWithProps) {
      const { node: Description, props = {} } = descriptionNodeWithProps;
      return <Description {...props} />;
    }
    if (descriptionWithParams) {
      const { key, params } = descriptionWithParams;
      return t(key as any, params);
    }
    if (description) return t(description);
    return undefined;
  }, [t, descriptionNodeWithProps, descriptionWithParams, description]);

  const handleAnimationEnd = useCallback(() => {
    if (!isShow) {
      deleteUIToast?.(id);
    }
  }, [isShow, id, deleteUIToast]);
  const handleClick = useCallback(
    () => dismissUIToast?.(id),
    [dismissUIToast, id],
  );

  useDuration({ duration, dismiss: handleClick });

  return (
    <Component
      e2eId={`toast-${id}`}
      title={memoizedTitle}
      description={memoizedDescription}
      isShow={isShow}
      onClick={handleClick}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

const ToastList = () => {
  const toasts = useToastStateContext();

  const hasToast = useMemo(() => Object.keys(toasts).length > 0, [toasts]);

  if (!hasToast) return null;

  return (
    <ToastContainer>
      {Object.values(toasts).map((toast) => {
        const Toast = toastMap[toast.type];
        if (!Toast) return null;
        return <ToastItem as={Toast} key={toast.id} {...toast} />;
      })}
    </ToastContainer>
  );
};

export default ToastList;
