import update from 'immutability-helper';
import getOr from 'lodash/fp/getOr';
import mapValues from 'lodash/fp/mapValues';
import { curryRight, identity } from 'lodash-es';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  PostMessageConfig,
  PostMessageConfigSet,
  PostMessageEvent,
} from './types';

const curriedUpdate = curryRight(update, 2);

const dispatchMessage = (payload: PostMessageEvent) => {
  if (window.parent === window) {
    // eslint-disable-next-line no-console
    console.log('cannot request to parent window', payload);
    return;
  }
  window.parent.postMessage(payload, '*');
};

const PostMessageContext = createContext<
  [Record<string, any>, Record<string, any>]
>([{}, {}]);

const createInitialState = mapValues<PostMessageConfig<unknown>, unknown>(
  getOr(null, ['initialValue']),
);

const createHandlers = mapValues<PostMessageConfig<unknown>, any>(
  ({ dispatch }) =>
    (...args: Parameters<typeof dispatch>) =>
      dispatchMessage(dispatch(...args)),
);

const createUpdater = curryRight(
  ({ data }: { data: any }, config: PostMessageConfigSet) =>
    mapValues(
      ({ parse }) =>
        typeof parse === 'function' ? parse(data) : { $apply: identity },
      config,
    ),
);

export const PostMessageProvider = <TConfig extends PostMessageConfigSet>({
  config,
  children,
}: {
  config: TConfig;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(createInitialState(config));
  const handlers = useMemo(() => createHandlers(config), [config]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      setState(curriedUpdate(createUpdater(event, config)));
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  const contextValue = useMemo<[Record<string, any>, Record<string, any>]>(
    () => [state, handlers],
    [state, handlers],
  );

  return (
    <PostMessageContext.Provider value={contextValue}>
      {children}
    </PostMessageContext.Provider>
  );
};

export const usePostMessageContext = () => useContext(PostMessageContext);

export default PostMessageContext;
