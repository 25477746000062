import { Button, Dropdown, Icons } from '@shopline/dashboard-ui';
import { OptionProps } from '@shopline/dashboard-ui/types/components/dropdown/Dropdown';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { FieldError } from '@/constants/fieldError';
import { useConfig } from '@/contexts/Config';
import useQueryInitialState from '@/hooks/useQueryInitialState';
import { useQueryMerchantConnectedChannels } from '@/hooks/youtubeAccountLinking/useQueryMerchantConnectedChannels';

import { YT_LIVE_E2E_PREFIX } from '../constants';
import { FormField } from '../formSchemaV2';

interface LiveChannelProps {
  isPrevSelectedChannelUnlinked?: boolean;
}

const NoAvailableChannelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const getSetupLink = (
  adminOrigin: string,
  youTubeShoppingAccountLinkingAppId: string,
  handle?: string,
) =>
  `${adminOrigin}/admin/${handle}/apps/apps_store/${youTubeShoppingAccountLinkingAppId}`;

export const goSetupButtonE2eId = `${YT_LIVE_E2E_PREFIX}-go-setup_button`;
export const channelDropdownE2eId = `${YT_LIVE_E2E_PREFIX}-channel_dropdown`;

const LiveChannel = ({
  isPrevSelectedChannelUnlinked = false,
}: LiveChannelProps) => {
  const { origins, appIds } = useConfig();
  const { t } = useTranslation('ytLive');
  const {
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { data: initialState } = useQueryInitialState();
  const { data: connectedChannels } = useQueryMerchantConnectedChannels();
  const handle = initialState?.merchant.handle;
  const noAvailableChannel = isEmpty(connectedChannels?.connects);

  // immediately show error message if the channel is unlinked
  useEffect(() => {
    if (isPrevSelectedChannelUnlinked) {
      setError(FormField.channel, {
        message: FieldError.channelUnlinked,
      });
      trigger(FormField.channel);
    }
  }, [isPrevSelectedChannelUnlinked, setError, trigger]);

  const dropdownOptions: OptionProps[] =
    connectedChannels?.connects?.map((channel) => ({
      label: channel.youtubeChannelName,
      value: channel.id,
      key: channel.id,
    })) || [];

  const handleDropdownSelect = (value: string[]) => {
    setValue(FormField.channel, value[0]);
    clearErrors(FormField.channel);
  };

  return (
    <>
      {noAvailableChannel ? (
        <NoAvailableChannelContainer>
          <Text color="INK_800" fontType="Body">
            {t('Setup Guide No Available Channels')}
          </Text>
          <MarginContainer ml="1rem">
            <Button.Default
              // @ts-ignore
              as="a"
              href={getSetupLink(
                origins.admin,
                appIds.youTubeShoppingAccountLinking,
                handle,
              )}
              target="_blank"
              rel="noreferrer noopener"
              Icon={Icons.Outline.Link}
              width="SQUARE"
              e2eId={goSetupButtonE2eId}
            >
              {t('Setup Now')}
            </Button.Default>
          </MarginContainer>
        </NoAvailableChannelContainer>
      ) : (
        <Controller
          name={FormField.channel}
          defaultValue={null}
          control={control}
          render={({ field }) => (
            <Dropdown
              options={dropdownOptions}
              isOpen={dropdownOpen}
              onClose={() => setDropdownOpen(false)}
              onClick={() => setDropdownOpen(true)}
              onChange={handleDropdownSelect}
              value={field.value ? [field.value] : []}
              menuAlign="left"
              width="24.875rem"
              placeHolder={t('Setup Guide Choose Channel Dropdown Placeholder')}
              border={errors?.[FormField.channel] ? '1px solid #E68173' : ''}
              e2eId={channelDropdownE2eId}
            />
          )}
        ></Controller>
      )}
      {errors[FormField.channel] && (
        <MarginContainer>
          <Text color="RED_700" fontType="Micro">
            {t(
              isPrevSelectedChannelUnlinked
                ? FieldError.channelUnlinked
                : FieldError.required,
              {
                ns: 'fieldValidation',
                fieldName: t('Field Channel'),
              },
            )}
          </Text>
        </MarginContainer>
      )}
    </>
  );
};

export default LiveChannel;
