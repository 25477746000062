const fieldValidation = {
  required: '{{fieldName}} 是必須的',
  pattern: '{{fieldName}} 無效',
  min: '{{fieldName}} 不得小於 {{value}}',
  max: '{{fieldName}} 不得大於 {{value}}',
  minDate: '{{fieldName}} 不得早於 {{value}}',
  maxDate: '{{fieldName}} 不得晚於 {{value}}',
  channelUnlinked: '選取的頻道已被解綁，請重新選擇或串接頻道',
};

export default fieldValidation;
