import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Grid, Icons, TextButton } from '@shopline/dashboard-ui';
import dayjs from 'dayjs';
import { matchesProperty, overSome } from 'lodash-es';
import { FunctionComponent } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AnchorLink from '@/components/AnchorLink';
import { BranchLoading } from '@/components/BranchLoading';
import Page from '@/components/Page';
import Section from '@/components/Section';
import StickyWrapper from '@/components/StickyWrapper';
import { useToastActionContext } from '@/contexts/Toast';
import useHasFeature from '@/hooks/useHasFeature';
import {
  useQueryLiveRoomSetting,
  useUpdateLiveRoomSetting,
} from '@/hooks/useLiveRoomSetting/useLiveRoomSetting';
import { useQueryMerchant } from '@/hooks/useMerchant';
import { useQueryTracker } from '@/hooks/useTracker';
import { Tracker, TrackerType } from '@/types/tracker/tracker.types';

import { PRODUCTS_MAX_COUNT, YT_LIVE_E2E_PREFIX } from './constants';
import formSchema, { FormField, FormSchema } from './formSchema';
import useCanEdit from './hooks/useCanEdit';
import useExceedDate, { ExceedDateProvider } from './hooks/useExceedDate';
import useNav from './hooks/useNav';
import LiveProducts from './LiveProducts';
import SetupGuide from './SetupGuide';
import YoutubeAdminPreparation from './YoutubeAdminPreparation';
import YoutubeTermsAndConditions from './YoutubeTermsAndConditions';
import YTLiveV2 from './YTLiveV2';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const CenterWrapper = styled.div`
  text-align: center;
`;
const AnchorLinkWrapper = styled(StickyWrapper)`
  margin-left: 1rem;
`;

const E2E_PREFIX = `${YT_LIVE_E2E_PREFIX}-index`;

const YTLive: FunctionComponent = () => {
  const { t } = useTranslation('ytLive');
  const { createUIToast } = useToastActionContext();
  const canEdit = useCanEdit();
  const [exceedDate, touch] = useExceedDate();

  const { data: liveRoomSetting } = useQueryLiveRoomSetting();
  const youtubeAccountingLinkingEnabled = useHasFeature(
    'youtube_accounting_linking',
  );

  const {
    mutate: updateLiveRoomSetting,
    isLoading: isUpdateLiveRoomSettingLoading,
  } = useUpdateLiveRoomSetting();
  const { data: ga4Tracker } = useQueryTracker({
    trackerType: TrackerType.google_analytics_4,
    refetchOnWindowFocus: true,
  }) as { data: Tracker };
  const { data: merchant } = useQueryMerchant({ refetchOnWindowFocus: true });
  const formMethods = useForm({
    resolver: joiResolver(formSchema),
    values: {
      [FormField.isGa4Ready]: Boolean(ga4Tracker),
      [FormField.isGmcReady]: Boolean(merchant?.verified),
      [FormField.youtubeId]: liveRoomSetting?.youtubeId ?? '',
      [FormField.brandHomeUrl]: merchant?.brandHomeUrl ?? '',
      [FormField.youtubeUrl]: liveRoomSetting?.youtubeUrl ?? '',
      [FormField.date]: liveRoomSetting?.date ?? '',
      [FormField.slProductIds]: liveRoomSetting?.slProductIds ?? [],
      [FormField.tnc]: false,
    },
  });

  const hasSetupGuiedError = Object.keys(formMethods.formState.errors).some(
    (fieldKey) =>
      (
        [
          FormField.isGa4Ready,
          FormField.isGmcReady,
          FormField.youtubeUrl,
          FormField.youtubeId,
          FormField.date,
        ] as string[]
      ).includes(fieldKey),
  );

  const liveProductsErrorMessage = t(
    (formMethods.formState.errors[FormField.slProductIds]?.message ??
      '') as any,
    {
      count: PRODUCTS_MAX_COUNT,
    },
  );

  const onSubmit: SubmitHandler<FormSchema> = (data) => {
    if (liveRoomSetting?.date && new Date(liveRoomSetting.date) <= new Date()) {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'exceeded the LIVE Broadcast time',
          params: { ns: 'ytLive' },
        },
      });
      touch();
      return;
    }
    const { youtubeId, youtubeUrl, date, slProductIds } = data;
    updateLiveRoomSetting({
      youtubeId,
      youtubeUrl,
      date: dayjs(date).toISOString(),
      slProductIds,
    });
  };

  const {
    refs: {
      setupGuideRef,
      liveProductsRef,
      youtubeAdminPreparationRef,
      finishSettingRef,
    },
    items: navItems,
  } = useNav({ e2ePrefix: E2E_PREFIX });

  const isYoutubeTncChecked = formMethods.watch(FormField.tnc);

  const shouldDisableSaveButton =
    !canEdit || exceedDate || !isYoutubeTncChecked;

  if (youtubeAccountingLinkingEnabled) {
    return <YTLiveV2 />;
  }

  return (
    <Page titleLeftSection={t('Youtube Live Shopping', { ns: 'pageTitle' })}>
      {/* @ts-ignore react 18 removed `children` as a default prop  */}
      <Grid.Row>
        <Grid.Column disableGutter lg={8} md={9} sd={12}>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit as any)}>
              <SectionContainer>
                <Section
                  title={t('Setup Guide')}
                  caption={t('Setup Guide - description')}
                  ref={setupGuideRef}
                  errorMessage={
                    hasSetupGuiedError ? t('Setup Guide - error') : ''
                  }
                >
                  <SetupGuide />
                </Section>
                <Section
                  title={`${t('Live Products')} ${t('Live Products - counter', {
                    count: formMethods.watch(FormField.slProductIds).length,
                    max: PRODUCTS_MAX_COUNT,
                  })}`}
                  caption={t('Live Products - description')}
                  ref={liveProductsRef}
                  errorMessage={liveProductsErrorMessage}
                >
                  <LiveProducts />
                </Section>
                <Section
                  title={t('Youtube Admin Preparation')}
                  caption={t('Youtube Admin Preparation - description')}
                  ref={youtubeAdminPreparationRef}
                >
                  <YoutubeAdminPreparation />
                </Section>
                <Section
                  title={t('Finish Setting')}
                  caption={<YoutubeTermsAndConditions />}
                  ref={finishSettingRef}
                >
                  <CenterWrapper>
                    <Button.Primary
                      type="submit"
                      width="WIDE"
                      isLoading={isUpdateLiveRoomSettingLoading}
                      disabled={shouldDisableSaveButton}
                      e2eId={`${YT_LIVE_E2E_PREFIX}-save_button`}
                    >
                      {t('Save', { ns: 'common' })}
                    </Button.Primary>
                  </CenterWrapper>
                </Section>
                {liveRoomSetting?.youtubeUrl && (
                  <CenterWrapper>
                    <TextButton.Primary
                      // @ts-ignore
                      as="a"
                      href={liveRoomSetting?.youtubeUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icons.Outline.Link size="MEDIUM" />
                      {t('Start Streaming')}
                    </TextButton.Primary>
                  </CenterWrapper>
                )}
              </SectionContainer>
            </form>
          </FormProvider>
        </Grid.Column>
        <Grid.Column disableGutter lg={4} md={3} sd={12}>
          <AnchorLinkWrapper top={20}>
            <AnchorLink
              scrollbarWrapper={document.documentElement}
              items={navItems}
              defaultMessage=""
            />
          </AnchorLinkWrapper>
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
};

const isLoadingLiveRoomSetting = overSome([
  matchesProperty('isLoading', true),
  matchesProperty('isSuccess', false),
]);

const EnhancedYTLive = () => {
  const query = useQueryLiveRoomSetting();
  const isLoading = isLoadingLiveRoomSetting(query);

  return (
    <BranchLoading isLoading={isLoading}>
      <ExceedDateProvider>
        <YTLive />
      </ExceedDateProvider>
    </BranchLoading>
  );
};

export default EnhancedYTLive;
