import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';

interface ContentBlockProps {
  title: string;
  description: JSX.Element;
  children: React.ReactNode;
}

const SetupBlock = ({ title, description, children }: ContentBlockProps) => {
  return (
    <>
      <MarginContainer mt="0.25rem">
        <Text color="INK_800" fontType="Subheading" weight="Semibold">
          {title}
        </Text>
      </MarginContainer>
      <MarginContainer mt="0.25rem">
        <Text color="INK_500" fontType="Body" weight="Regular">
          {description}
        </Text>
      </MarginContainer>
      {children}
    </>
  );
};

export default SetupBlock;
