import { Icons } from '@shopline/dashboard-ui';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';

interface BlockContainerProps {
  stepNum: number;
  title: string;
  description: string;
  children?: React.ReactNode;
  actionButton?: React.ReactNode;
}

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.SNOW_200};
  border-radius: 6px;
`;

const HeaderRow = styled.div`
  display: flex;
`;

const Spacer = styled.div`
  flex: 0 0 36px;
`;

const BlockTitleAndDescription = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const BlockAction = styled.div`
  flex-shrink: 0;
`;

const ContentRow = styled.div`
  display: flex;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const stepIcons: Record<number, JSX.Element> = {
  1: <Icons.Solid.StepOne size="MEDIUM" />,
  2: <Icons.Solid.StepTwo size="MEDIUM" />,
};

const BlockContainer = ({
  stepNum,
  children,
  title,
  description,
  actionButton,
}: BlockContainerProps) => {
  return (
    <BlockWrapper>
      <HeaderRow>
        <Spacer>{stepIcons[stepNum]}</Spacer>
        <BlockTitleAndDescription>
          <Text color="INK_800" fontType="Heading" weight="Semibold">
            {title}
          </Text>
          <MarginContainer mt="0.25rem">
            <Text color="INK_600" fontType="Body" weight="Regular">
              {description}
            </Text>
          </MarginContainer>
        </BlockTitleAndDescription>
        <BlockAction>{actionButton}</BlockAction>
      </HeaderRow>
      <ContentRow>
        <Spacer />
        <ContentContainer>{children}</ContentContainer>
      </ContentRow>
    </BlockWrapper>
  );
};

export default BlockContainer;
