import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { useToastActionContext } from '@/contexts/Toast';
import queryClient from '@/features/queryClient';

import { queryKeys } from './queryKey';

export const logout = async () => {
  await axios.delete('/external-api/auth/session');
};

export const useGoogleOAuthLogout = (merchantId?: string) => {
  const { createUIToast } = useToastActionContext();

  return useMutation({
    mutationFn: logout,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.initialState(merchantId));
      createUIToast?.({
        type: 'success',
        titleWithParams: {
          key: 'External YouTube Connect Logout Success',
          params: { ns: 'externalYtConnect' },
        },
        duration: 3000,
      });
    },
  });
};
