import { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import transformObjectKeys, { CamelCase } from 'transform-object-keys';

import { Merchant } from '@/types/merchant/merchant.type';

import { PermissionAction, PermissionScope } from './initialState.types';
import usePermissions from './usePermissions';

type QueryKey = ['merchant', string];

const getMerchant = async (merchantId: string) => {
  const { data } = await axios.get<Merchant>(`/api/merchants/${merchantId}`);
  return transformObjectKeys(data, {
    deep: true,
  }) as unknown as CamelCase<Merchant>;
};

const passMerchantId =
  (queryFn: typeof getMerchant) =>
  ({ queryKey: [, merchantId] }: QueryFunctionContext<QueryKey>) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId: string): QueryKey => [
  'merchant',
  merchantId,
];

export const useQueryMerchant = ({
  refetchOnWindowFocus = false,
}: {
  refetchOnWindowFocus: boolean;
}) => {
  const { merchantId } = useParams() as { merchantId: string };
  const canShow = usePermissions([
    [PermissionScope.merchant, PermissionAction.show],
  ]);

  return useQuery({
    queryKey: composeQueryKey(merchantId),
    queryFn: passMerchantId(getMerchant),
    enabled: canShow && !!merchantId,
    staleTime: refetchOnWindowFocus ? 0 : Number.POSITIVE_INFINITY,
    refetchOnWindowFocus,
  });
};
