import type { ColorName } from '@shopline/dashboard-ui/types/themes/colors';
import styled from 'styled-components';

const StyledHr = styled.hr<{ color: ColorName; spacing: string }>`
  border-width: 0;
  border-style: solid;
  border-color: ${({ theme, color }) => theme.colors[color]};
  border-bottom-width: 1px;
  margin-top: ${({ spacing }) => spacing};
  margin-bottom: ${({ spacing }) => spacing};
`;

interface Props {
  color?: ColorName;
  spacing?: string;
}

const Divider = ({ color = 'INK_300', spacing = '1rem' }: Props) => {
  return <StyledHr color={color} spacing={spacing} />;
};

export default Divider;
