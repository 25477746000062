export enum TrackerStatus {
  active = 'active',
  inactive = 'inactive',
}
export type TrackerStatusString = keyof typeof TrackerStatus;

export enum TrackerType {
  google_analytics_4 = 'google_analytics_4',
}
export type TrackerTypeString = keyof typeof TrackerType;

export interface Tracker {
  id: string;
  updatedAt: string;
  status: TrackerStatusString;
  trackerType: TrackerTypeString;
}
