const common = {
  Save: 'Save',
  'Previous Step': 'Previous Step',
  'Next Step': 'Next Step',
  Finish: 'Finish',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  'Unauthorized - title': 'Sorry, You are not authorized to access this page!',
  'Unauthorized - description':
    'If you wish to access this page, please contact shop admin for permission.',
  'Saved successfully': 'Saved successfully',
  'Failed to save': 'Failed to save',
  en: 'English',
  'zh-hant': '繁體中文',
  'zh-cn': '简体中文',
  vi: 'Vietnamese',
  th: 'Thai',
  'General Error': 'Something went wrong, please try again.',
};

export default common;
