import '@/locales';

import { Theme } from '@shopline/dashboard-ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '@/components/GlobalStyle';
import LanguageDetector from '@/components/LanguageDetector';
import { EXTERNAL_PAGE_DOMAINS } from '@/constants/environments';
import { ConfigProvider } from '@/contexts/Config';
import { PostMessageProvider } from '@/contexts/PostMessage';
import { ToastProvider } from '@/contexts/Toast';

import FeatureRoute from './FeatureRoute';
import postMessageConfig from './postMessageConfig';
import queryClient from './queryClient';

const Features = () => {
  const url = window.location.href;
  const isExternalPage = EXTERNAL_PAGE_DOMAINS.some((domain) =>
    url.includes(domain),
  );

  const feature = (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <ToastProvider>
          <ConfigProvider>
            <FeatureRoute />
          </ConfigProvider>
        </ToastProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );

  if (isExternalPage) {
    return feature;
  }

  return (
    <PostMessageProvider config={postMessageConfig}>
      <LanguageDetector />
      {feature}
    </PostMessageProvider>
  );
};

export default Features;
