import { Breadcrumb, media } from '@shopline/dashboard-ui';
import type { BreadcrumbItem } from '@shopline/dashboard-ui/types/components/breadcrumb';
import React from 'react';
import styled from 'styled-components';

import Text from '../Text';

export const HeaderWrapper = styled.div`
  ${media.mobile`
    padding: 0 1rem;
  `};
`;

const TitleSection = styled.div<{ rightSectionOnly: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ rightSectionOnly }) =>
    rightSectionOnly ? 'flex-end' : 'space-between'};
  flex-wrap: wrap;
  margin: 1rem 0 1.5rem;
`;

export interface Props {
  titleLeftSection: React.ReactNode;
  titleRightSection?: React.ReactNode;
  breadcrumbs?: BreadcrumbItem[];
}

const PageHeader = ({
  titleLeftSection,
  titleRightSection = null,
  breadcrumbs,
}: Props) => (
  <HeaderWrapper>
    {breadcrumbs && <Breadcrumb items={breadcrumbs} />}
    {(titleLeftSection || titleRightSection) && (
      <TitleSection rightSectionOnly={!titleLeftSection && !!titleRightSection}>
        {typeof titleLeftSection === 'string' ? (
          <Text as="h2" fontType="DisplayLarge" color="INK_DEFAULT">
            {titleLeftSection}
          </Text>
        ) : (
          titleLeftSection
        )}
        {titleRightSection}
      </TitleSection>
    )}
  </HeaderWrapper>
);

export default PageHeader;
