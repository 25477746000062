import { IconButton, Icons, Tooltip } from '@shopline/dashboard-ui';
import { LanguageName } from '@shopline/dashboard-ui/types/themes/fonts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { YT_ACCOUNT_LINKING_E2E_PREFIX } from '@/constants/e2eId';
import { MerchantConnectedChannel } from '@/types/youtube-account-linking/youtube-account-linking.type';

import DisconnectChannelPopup from './DisconnectChannelPopup';

export const gmcIdChangedAlertE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-gmc-id-changed-alert_icon`;
export const pastActivatedChannelAlertIconE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-already-activated-channel-alert_icon`;
export const channelDisconnectIconE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-channel-disconnect_icon`;

const ConnectedChannelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.SNOW_500};
  border-radius: 6px;
`;

const BreakLineText = styled(Text)`
  word-break: break-word;
`;

const ConnectStatus = styled.div`
  display: flex;
  align-items: center;
`;

const AlertIconContainer = styled.div`
  vertical-align: text-top;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
`;

const NonAuthorizedChannelStatus = styled.div`
  display: flex;
  align-items: center;
`;

interface ChannelConnectStatusProps {
  channel: MerchantConnectedChannel;
  hasRemovePermission?: boolean;
}

const ChannelConnectStatus = ({
  channel,
  hasRemovePermission = false,
}: ChannelConnectStatusProps) => {
  const [disconnectPopupOpen, setDisconnectPopupOpen] = useState(false);
  const { t, i18n } = useTranslation('ytAccountLinking');
  // Channels without youtuberId are previously connected channels which needs shopline to manually disconnect it.
  const isPastActivatedChannel = !channel?.youtuberId;

  const handleClickDisconnect = () => {
    setDisconnectPopupOpen(true);
  };

  return (
    <>
      {disconnectPopupOpen && (
        <DisconnectChannelPopup
          youtubeConnectId={channel.id}
          onClose={() => setDisconnectPopupOpen(false)}
        />
      )}
      <ConnectedChannelsContainer>
        <BreakLineText color="INK_800" fontType="Body">
          {channel.youtubeChannelName}
        </BreakLineText>
        {isPastActivatedChannel ? (
          // @ts-ignore react 18 removed `children` as a default prop
          <Tooltip.Primary
            text={t('Connected Channels Disconnect Tooltip')}
            direction="TOP_CENTER"
          >
            <NonAuthorizedChannelStatus>
              <Icons.Solid.Alert
                e2eId={pastActivatedChannelAlertIconE2eId}
                size="SMALL"
                color="INK_600"
              />
              <MarginContainer ml="0.25rem">
                <Text color="INK_800" fontType="Body">
                  {t('Connected Channels Not Authorized')}
                </Text>
              </MarginContainer>
            </NonAuthorizedChannelStatus>
          </Tooltip.Primary>
        ) : (
          <ConnectStatus>
            {channel?.isGMCIdChanged && (
              <>
                <AlertIconContainer>
                  <Icons.Solid.Alert
                    e2eId={gmcIdChangedAlertE2eId}
                    color="RED_700"
                    size="SMALL"
                  />
                </AlertIconContainer>
                <Text color="RED_700" fontType="Body">
                  {t('Connected Channels Reconnect as GMC ID Changed')}
                </Text>
              </>
            )}
            {hasRemovePermission && (
              <MarginContainer ml="1rem">
                <IconButton
                  Icon={Icons.Outline.Unlink}
                  colorType={'basic'}
                  size={1}
                  iconProps={{
                    size: 'SMALL',
                    position: 'LEFT',
                    e2eId: channelDisconnectIconE2eId,
                  }}
                  tooltipProps={{
                    direction: 'TOP_CENTER',
                    lang: i18n.language as LanguageName,
                    text: t('Connected Channels Disconnect Channel'),
                  }}
                  handleClick={handleClickDisconnect}
                />
              </MarginContainer>
            )}
          </ConnectStatus>
        )}
      </ConnectedChannelsContainer>
    </>
  );
};

export default ChannelConnectStatus;
