import React, { createContext, useContext, useMemo } from 'react';

import { APP_IDS, ORIGIN_CONFIGS } from '@/constants/environments';
import {
  Environment,
  OriginConfig,
  ShoplineApps,
} from '@/types/environment/environment.type';
import { getEnvironment } from '@/utils/getEnvironment';

interface ConfigContextType {
  environment: Environment;
  origins: OriginConfig;
  appIds: ShoplineApps;
}

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useMemo(() => {
    const environment = getEnvironment();
    const origins = ORIGIN_CONFIGS[environment];
    const appIds = APP_IDS[environment];

    return {
      environment,
      origins,
      appIds,
    };
  }, []);

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = (): ConfigContextType => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useDomain must be used within a DomainProvider');
  }
  return context;
};
