import {
  Environment,
  OriginConfig,
  ShoplineApps,
} from '@/types/environment/environment.type';

export const EXTERNAL_PAGE_DOMAINS = [
  'localhost:3000/merchants',
  'localhost:3003/merchants',
  'yt-channel-connect.shoplinestg.com/merchants',
  'yt-channel-connect-preview.shoplineapp.com/merchants',
  'yt-channel-connect.shoplineapp.com/merchants',
];

export const ORIGIN_CONFIGS: Record<Environment, OriginConfig> = {
  staging: {
    ytChannelConnect: 'https://yt-channel-connect.shoplinestg.com',
    admin: 'https://admin.sws.shoplinestg.com',
  },
  preview: {
    ytChannelConnect: 'https://yt-channel-connect-preview.shoplineapp.com',
    admin: 'https://w0.shoplineapp.com',
  },
  production: {
    ytChannelConnect: 'https://yt-channel-connect.shoplineapp.com',
    admin: 'https://admin.shoplineapp.com',
  },
  default: {
    ytChannelConnect: 'https://yt-channel-connect.shoplinestg.com',
    admin: 'https://admin.sws.shoplinestg.com',
  },
};

export const APP_IDS: Record<Environment, ShoplineApps> = {
  staging: {
    youTubeShoppingAccountLinking: '6708a5cb411f1fccdbabe004',
  },
  preview: {
    youTubeShoppingAccountLinking: '66ab3b0315aad857cff24c71',
  },
  production: {
    youTubeShoppingAccountLinking: '66ab3b0315aad857cff24c71',
  },
  default: {
    youTubeShoppingAccountLinking: '6708a5cb411f1fccdbabe004',
  },
};
