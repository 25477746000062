const common = {
  Save: '储存',
  'Previous Step': '上一步',
  'Next Step': '下一步',
  Finish: '完成',
  Cancel: '取消',
  Confirm: '确认',
  'Unauthorized - title': '抱歉，此页面目前暂时无法开放给您！',
  'Unauthorized - description': '若您仍需进入此页面，请联系您的店长索取权限。',
  'Saved successfully': '储存成功',
  'Failed to save': '储存失敗',
  en: 'English',
  'zh-hant': '繁體中文',
  'zh-cn': '简体中文',
  vi: '越南语',
  th: '泰语',
  'General Error': '发生问题，请再试一次',
};

export default common;
