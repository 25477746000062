import { ProductDisabledType } from '@/features/YTLive/LiveProducts/getProductDisabledType';

const ytLive = {
  'Apply Now': 'Apply Now',
  'Go to Set': 'Go to Set',
  'Manage Settings': 'Manage Settings',
  'Go to Install': 'Go to Install',
  'Learn more': 'Learn more',
  'Linkage Completed': 'Connected',

  'Setup Guide': 'Setup',
  'Setup Guide - description':
    "Before starting a YouTube LIVE Shopping broadcast, you'll need to complete the following settings. Note: You need to be approved by Google Merchant Center and have YPP qualifications before you start YouTube live streaming with SHOPPING feature",
  'Setup Guide - error':
    'Set-up Guide is a required field, please make sure the following steps are completed.',
  'Setup Guide - nav content':
    'Follow the steps to complete the settings and connection.',
  'Setup Guide StepTrackerSetting': 'Set up GA4 in "Tracker Setting"',
  'Setup Guide StepTrackerSetting - description':
    'GA4 allows you to track all customer browsing behaviors and clickstream data on your website, as well as record purchasing behaviors, events, and values. You can also track revenue generated from YouTube Live Shopping broadcasts across devices. <LinkButton>Learn more</LinkButton>',
  'Setup Guide StepTrackerSetting - support link':
    'https://support.shoplineapp.com/hc/en-us/articles/8646049467801',
  'Setup Guide StepContentApi':
    'Install Google Merchant Center Content API in Apps Store and set up hidden products',
  'Setup Guide StepContentApi - description':
    'Through a Content API connection, your products will be automatically uploaded to Google Merchant Center, allowing them to be accessible in Google Ads, YouTube Live Shopping, and other Google features. <LinkButton>Learn more about hidden product</LinkButton>',
  'Setup Guide StepContentApi - connected account':
    'The shop has been connected to below Google Merchant Center account',
  'Setup Guide StepGmc':
    'Go to Google Merchant Center to set up connection between GA4 and Google Merchant Center',
  'Setup Guide StepGmc - description':
    'Go to the Google Merchant Center and connect your GA4 account to view your products in the YouTube Channel admin. <LinkButton>Learn more</LinkButton>',
  'Setup Guide StepGmc - caption':
    'Note: You must use the same email address for both your Google Merchant Center account and your YouTube channel account. Otherwise, please go to Google Merchant Center > Tools and Settings > Account access > Users > Add User, and enter the YouTube channel account email address.',
  'Setup Guide StepInfoForm': 'Connect to YouTube',
  'Field youtubeId': 'YouTube channel ID',
  'Field youtubeUrl': 'YouTube Channel URL',
  'Field brandHomeUrl': 'Store URL',
  'Field date': 'The Expected LIVE Broadcast Time',
  'Field date - description':
    'Please note that your products will only be published in the Google Merchant Center or by the admin of your YouTube channel after you click the "Go Live" button. Once you have completed all the settings above, it is recommended that you conduct a trial broadcast on the YouTube channel three days before the actual broadcast to ensure a successful outcome.\n*Reminder: Due to restrictions on product uploading on YouTube and Google Merchant Center, you can only select a time 24 hours after the current time as your expected live broadcast time.',

  'HiddenProductModal - title':
    'The time of Hidden products exposed in YouTube LIVE Shopping',
  'HiddenProductModal - page 1 title': 'Step 1: Go to SHOPLINE Admin',
  'HiddenProductModal - page 1 description':
    'If you want to reveal your products during the live broadcast, please go to "Products & Categories > Products > Product Settings > Hidden Product" to turn on the Toggle, and the product will be temporarily hidden.',
  'HiddenProductModal - page 2 title': 'Step 2: Set up the Content API',
  'HiddenProductModal - page 2 description':
    'Go to "App > Apps Store > Google Content API - Product data source for Google Merchant Center" and set up the Content API. We will instantly upload the hidden products you choose to the Google Merchant Center admin. You can adjust your product specifications and information at any time.',
  'HiddenProductModal - page 3 title':
    'Step 3: Set the Expected LIVE Broadcast Time',
  'HiddenProductModal - page 3 description':
    'After selecting the Expected LIVE Broadcast Time, the hidden products will be exposed at your SHOP Storefront & YouTube Channel Admin/Front according to the estimated live broadcast time you selected.',
  'HiddenProductModal - page 4 title': 'Step 4: Go to the SHOPLINE Admin again',
  'HiddenProductModal - page 4 description':
    'Please go to "Products & Categories > Products > Product Settings > Hidden Product" during or before the live broadcast to turn off the toggle, and the product will be immediately exposed to the SHOP Storefront.\n*Please note: If you want to expose the product in advance before the live broadcast, you can turn off the toggle in advance.\n\nAlso remind you: Due to the limitations of the Google Merchant Center platform, product status is only updated once every 24 hours. Therefore, it\'s recommended that do not change all product specifications and the expected live broadcast time 24 hours before the live broadcast, to avoid the problems like: products not being available / show in time during the live broadcast in the admin of Google Merchant Center and YouTube Channel.',

  'Live Products': 'Live broadcast products ',
  'Live Products - counter': '({{count}}/{{max}})',
  'Live Products - description':
    'Please provide your expected live broadcast time. After selection, the hidden products will be exposed at your SHOP Storefront & YouTube Channel Admin/Front according to your selected live broadcast time. Reminder: Due to Google Merchant Center and YouTube platform restrictions, after you selected "The Expected Live Broadcast Time", we\'ll no longer open the editing time for 24 hours before your live broadcast.',
  'Live Products - min error':
    'Products are required for live broadcasts. Please select at least one.',
  'Live Products - max error':
    'Only {{count}} products can be added to a LIVE Broadcast Room. Please reduce the number of LIVE Broadcast Products.',
  'Live Products - nav content':
    'Select or create products for your live broadcast',
  'Add Products': 'Add product(s)',
  'Live Products columns - Product': 'Product',
  'Live Products columns - Price': 'Price',
  'Live Products columns - Inventory': 'Available Quantity',
  'Live Products columns - Status': 'Status',
  'Live Products status - reminder on': 'Out-Of-Stock Reminder: ON',
  'Live Products status - reminder off': 'Out-Of-Stock Reminder: OFF',
  'Live Products status - hidden': 'Hidden Product',
  'Live Products status - schedulePublishAt':
    'Scheduled Publish Time: {{date}}',
  'Live Products status - availableStartTime start':
    'Available After: {{date}}',
  'Live Products status - availableStartTime period':
    'Available Time: {{date}}',
  'Live Products status - availableStartTime end':
    'Unavailable Since: {{date}}',
  [`Live Prdoucts disabled_${ProductDisabledType.unpublished}`]: 'Unpublished',
  [`Live Prdoucts disabled_${ProductDisabledType.emptyPrice}`]:
    'Cannot add product without price',
  [`Live Prdoucts disabled_${ProductDisabledType.hiddenPrice}`]:
    'Cannot add hidden price product',
  'LiveProductsPicker - SearchBar placeholder':
    'Search for Product Name or SPU',
  'LiveProductsPicker - No result': 'No Item Found',
  'LiveProductsPicker - No result description':
    'Try to change the keyword or filter',
  'LiveProductsPicker - View selected products':
    'View the Selected Products ({{count}}/{{max}})',
  'LiveProductsPicker - View all products': 'View All Products',

  'Youtube Admin Preparation': 'YouTube channel admin setup ',
  'Youtube Admin Preparation - description':
    'Due to the limitations of YouTube, starting a YouTube live shopping broadcast from the SHOPLINE Admin is currently not supported. Please stay tuned for more livestreaming feature updates. If you wish to terminate live broadcast, please go to your YouTube admin to proceed. The following are what you need to know before starting a live broadcast:',
  'Youtube Admin Preparation - nav content':
    'Complete related settings in the YouTube channel',
  'Youtube Admin Preparation StepOne':
    'Set up a channel in YouTube Studio for product display',
  'Youtube Admin Preparation StepOne - description':
    'Once you set up the Google Merchant Center Content API, the products will be automatically uploaded to your Google Merchant Center and the admin for YouTube live shopping. You can go to the YouTube admin to check the products to be displayed in the live broadcast.',
  'Youtube Admin Preparation StepTwo':
    'Mark the products to be displayed in your live broadcast',
  'Youtube Admin Preparation StepTwo - description':
    'You can mark and pin products to the channel during the live broadcast for viewers to watch and shop at the same time.',
  'Youtube Admin Preparation StepThree':
    'Schedule a live broadcast and pin products',
  'Youtube Admin Preparation StepThree - description':
    'During your live broadcast, you can highlight featured products with the pin product feature.',

  'Finish Setting': 'Completion',
  'Finish Setting - description':
    'Once you complete the steps above, you can start your YouTube live shopping broadcast immediately!',
  'Start Streaming': 'Go to YouTube Channel to Start your LIVE Streaming',
  'exceeded the LIVE Broadcast time':
    'This LIVE Broadcast room has expired (exceeded the LIVE Broadcast time), this editing time will not be stored. If you want to start broadcasting, please add a new LIVE Broadcast room.',
  'Youtube Tnc':
    'When setting up this feature, you acknowledge that you are fully aware of the relevant regulations. Please refer to the <YoutubePrivacyLink></YoutubePrivacyLink> and the following related regulations.',
  'Youtube Privacy': 'YouTube Privacy Policy',
  'Youtube Tnc Detail':
    'Please note that by integrating YouTube Shopping, you agree that order data from your online store originating from YouTube will be transmitted to YouTube.',
  'Setup Guide YouTube Shopping Setup': 'YouTube Shopping Livestream Setup',
  'Field Channel': 'Channel',
  'Setup Guide Choose Channel Title': 'Select a YouTube Channel',
  'Setup Guide Choose Channel Description':
    'Note: Your store has hidden products, which will be synced to the dashboard of all connected YouTube channels.',
  'Setup Guide Choose Channel Dropdown Placeholder': 'Select',
  'Setup Guide No Available Channels':
    'The store is not connected to any YouTube Channel.',
  'Setup Now': 'Set up',
  'Schedule Live Stream Title': 'Schedule a livestream',
  'Schedule Live Stream Description':
    'Please select a desired time for the livestream. Once selected, the hidden products will be displayed on your online storefront, front page and dashboard of your YouTube channel based on the chosen time.\n*Please note that, due to Google Merchant Center and YouTube restrictions, the livestream time must be set at least 24 hours in advance and cannot be edited within 24 hours of the scheduled time.',
  'Live Stream Products Title': 'Livestream product(s)',
  'Live Stream Products Description':
    'Please select the livestream product. After clicking "Save", the product will be displayed on Google Merchant Center and YouTube dashboard.',
  'YouTube Preparation Title': 'YouTube channel dashboard setup',
  'YouTube Preparation Description':
    'Due to the limitations of YouTube, starting a YouTube livestream from the SHOPLINE Admin is currently not supported. Please stay tuned for more livestreaming feature updates. If you wish to terminate the livestream, please go to your YouTube dashboard to proceed. The following are what you need to know before starting a livestream:',
  'Account Linking YouTube Preparation StepOne':
    'Set up a channel in YouTube Studio for product display',
  'Account Linking YouTube Preparation StepOne - description':
    'Once you set up the Google Merchant Center Content API, the products will be automatically uploaded to your Google Merchant Center and YouTube dashboard. You can go to the YouTube dashboard to select the products to be displayed in the channel.',
  'Account Linking YouTube Preparation StepTwo':
    'Mark the products to be displayed in your livestream',
  'Account Linking YouTube Preparation StepTwo - description':
    'You can mark and pin products to the channel during the livestream for viewers to watch and shop at the same time.',
  'Account Linking YouTube Preparation StepThree':
    'Schedule a livestream and pin products',
  'Account Linking YouTube Preparation StepThree - description':
    'During your livestream, you can highlight featured products with the pin product feature.',
  'Finish YouTube Setting': 'Almost done!',
  'Content Api Description':
    'When setting up this feature, you acknowledge that you are fully aware of the relevant regulations. Please review the YouTube Privacy Policy and the related regulations below.\nBy integrating YouTube Shopping, you agree that order data from your online store, originating from YouTube, will be shared with YouTube.',
};

export default ytLive;
