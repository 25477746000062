import { ImagePreview } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Spacer from '@/components/Spacer';
import Text from '@/components/Text';
import getTranslation from '@/utils/getTranslation';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
// NOTE: for overridding ImagePreview > ImageWrapper style, may be out of action after upgrading dashboard-ui
const ImageWrapper = styled.div`
  flex: 0 0 36px;
  & > div > div {
    max-width: 36px;
    max-height: 36px;
  }
`;
const EllipsisedText = styled(Text)`
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
interface Props {
  image: string;
  titleTranslations: Record<string, string>;
}

const ProductTitle = ({ image, titleTranslations }: Props) => {
  const {
    i18n: { language },
  } = useTranslation('ytLive');

  return (
    <FlexWrapper>
      <ImageWrapper>
        <ImagePreview.QuickView disableHover image={image} />
      </ImageWrapper>
      <Spacer x="8px" />
      <EllipsisedText fontType="Body">
        {getTranslation(titleTranslations, language)}
      </EllipsisedText>
    </FlexWrapper>
  );
};

export default ProductTitle;
