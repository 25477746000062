import { Button, Popup } from '@shopline/dashboard-ui';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX } from '@/constants/e2eId';
import { useDisconnectChannelFromMerchant } from '@/hooks/externalYTChannelConnect/useConnectYouTubeChannel';

interface DisconnectChannelPopupProps {
  youtubeConnectId?: string;
  onClose: () => void;
  shopName?: string;
}

interface DisconnectChannelPopupFooterProps {
  youtubeConnectId?: string;
  onClose: () => void;
}

export const disconnectChannelPopupE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-disconnect-channel-popup_modal`;
export const disconnectChannelPopupConfirmButtonE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-disconnect-channel-popup-confirm_button`;
export const disconnectChannelPopupCancelButtonE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-disconnect-channel-popup-cancel_button`;
export const disconnectChannelPopupBodyE2eId = `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-disconnect-channel-popup-body_content`;

const PopupFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const PopupFooter = ({
  youtubeConnectId,
  onClose,
}: DisconnectChannelPopupFooterProps) => {
  const { t } = useTranslation('externalYtConnect');
  const { mutateAsync: disconnectChannel, isLoading } =
    useDisconnectChannelFromMerchant(youtubeConnectId);

  const handleClickConfirm = async () => {
    await disconnectChannel();
    onClose();
  };

  return (
    <PopupFooterContainer>
      <Button.Default
        onClick={onClose}
        e2eId={disconnectChannelPopupCancelButtonE2eId}
        height="LARGE"
      >
        {t('External YouTube Connect Disconnect Channel Popup Cancel')}
      </Button.Default>
      <MarginContainer ml="1rem">
        <Button.Destructive
          onClick={handleClickConfirm}
          isLoading={isLoading}
          e2eId={disconnectChannelPopupConfirmButtonE2eId}
          width="WIDE"
          height="LARGE"
        >
          {t('External YouTube Connect Disconnect Channel Popup Confirm')}
        </Button.Destructive>
      </MarginContainer>
    </PopupFooterContainer>
  );
};

const DisconnectChannelPopup = ({
  youtubeConnectId,
  onClose,
  shopName,
}: DisconnectChannelPopupProps) => {
  const { t } = useTranslation('externalYtConnect');
  const isMobileScreen = useMediaQuery('(max-width: 768px)');
  const title = t('External YouTube Connect Disconnect Channel Popup Title', {
    shopName,
  });
  const hasBodyPadding = isMobileScreen;
  return (
    // @ts-ignore react 18 removed `children` as a default prop
    <Popup
      e2eId={disconnectChannelPopupE2eId}
      onClose={onClose}
      title={isMobileScreen ? '' : title}
      renderFooter={() => (
        <PopupFooter youtubeConnectId={youtubeConnectId} onClose={onClose} />
      )}
      hasBodyPadding={hasBodyPadding}
    >
      {isMobileScreen && (
        <Text
          e2eId={disconnectChannelPopupBodyE2eId}
          color="INK_800"
          fontType="Heading"
        >
          {title}
        </Text>
      )}
    </Popup>
  );
};

export default DisconnectChannelPopup;
