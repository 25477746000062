import { has, omit } from 'lodash-es';

import * as actions from './actions';
import { ActionTypes, type Toast } from './types';

export type ToastState = Record<string, Toast>;

export const initialState: ToastState = {};

type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;

const reducer = (state: ToastState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.CREATE_UI_TOAST:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case ActionTypes.DISMISS_UI_TOAST:
      return has(state, action.payload.id)
        ? {
            ...state,
            [action.payload.id]: {
              ...state[action.payload.id],
              isShow: false,
            },
          }
        : state;
    case ActionTypes.DELETE_UI_TOAST:
      return omit(state, [action.payload.id]);

    case ActionTypes.CLEAR_UI_TOAST:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
