import { Footer, media } from '@shopline/dashboard-ui';
import React from 'react';
import styled from 'styled-components';

import PageHeader, { Props as PageHeaderProps } from '../PageHeader';

const PageWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  ${media.mobile`
    padding: 1.5rem 0;
  `}
`;

interface Props extends PageHeaderProps {
  className?: string;
  children: React.ReactNode;
  hideFooter?: boolean;
}

const Page = ({
  titleLeftSection,
  titleRightSection = null,
  breadcrumbs,
  className,
  children,
  hideFooter = false,
}: Props) => (
  <PageWrapper className={className}>
    <PageHeader
      titleLeftSection={titleLeftSection}
      titleRightSection={titleRightSection}
      breadcrumbs={breadcrumbs}
    />
    {children}
    {!hideFooter && <Footer />}
  </PageWrapper>
);

export default Page;
