import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { useToastActionContext } from '@/contexts/Toast';
import {
  GetMerchantConnectedChannelsResponse,
  MerchantConnectedChannelsQueryKey,
} from '@/types/youtube-account-linking/youtube-account-linking.type';

import { queryKey } from './queryKey';

const getMerchantConnectedChannels = async (merchantId?: string) => {
  const { data } = await axios.get<GetMerchantConnectedChannelsResponse>(
    `/api/merchants/${merchantId}/connects`,
  );
  return data;
};

const passMerchantId =
  (queryFn: typeof getMerchantConnectedChannels) =>
  ({
    queryKey: [_, merchantId],
  }: QueryFunctionContext<MerchantConnectedChannelsQueryKey>) =>
    queryFn(merchantId);

export const useQueryMerchantConnectedChannels = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { createUIToast } = useToastActionContext();
  return useQuery<
    GetMerchantConnectedChannelsResponse,
    AxiosError,
    GetMerchantConnectedChannelsResponse,
    MerchantConnectedChannelsQueryKey
  >({
    queryKey: queryKey.merchantConnectedChannels(merchantId),
    queryFn: passMerchantId(getMerchantConnectedChannels),
    enabled: !!merchantId,
    onError: () => {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'General Error',
          params: { ns: 'common' },
        },
        duration: 3000,
      });
    },
  });
};
