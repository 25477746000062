const common = {
  Save: 'Lưu',
  en: 'English',
  'zh-hant': 'Traditional Chinese',
  'zh-cn': 'Simplified Chinese',
  vi: 'Vietnamese',
  th: 'Thai',
};

export default common;
